import { useState } from 'react';
import { EntityMain } from '../Domain/EntityMain';
import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addLoading, changeSaludo, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { useNavigate } from 'react-router-dom';
import { UseCaseList } from '../Application/UseCaseList';
import { DtoRequestList } from '../Domain/DtoRequestList';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { DtoResponseList } from '../Domain/DtoResponseList';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { IConfigModal, initIConfigModal } from '../Domain/Utils';

export const Controller = () => {
    const { websocket, dbLocal, countProcess } = useSelector((state: RootState) => state.generico);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const languageTranslate = LanguageTranslate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [data, setData] = useState<Array<EntityMain>>([]);
    const [configModal, setConfigModal] = useState<IConfigModal>(initIConfigModal)

    const formFilter = useFormik({
        initialValues: { fechaInicio: '', fechaFin: '', },
        validationSchema: Yup.object({
            fechaInicio: Yup.string().required(languageTranslate.moduloRDI.list.validate.formFilter.fechaInicio),
            fechaFin: Yup.string().required(languageTranslate.moduloRDI.list.validate.formFilter.fechaFin),
        }),
        onSubmit: (values, formikHelpers) => { },
    });


    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            const response = await repository.customGetListRDI(user.IdUsuario === 0 ? 'guest' : 'user');
            setData(() => (formatterData(response)))
        } catch (error) {
            window.location.reload();
        }
    };

    const loadData = async () => {
        const params: DtoRequestList = {
            $match: {
                "ObservacionSolucion.IdEstado": 10,
                "Procesos.Registrar.DatosUsuario.Identificacion": user.Identificacion
            }
        }
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            const response = await (new UseCaseList(repository).exec(params));
            if (response === null) return;
            const customRegisterPermanent = await repository.customClearRDI();
            await dbLocal.insertDataStore([{ nameStore: 'RDI', data: [...response, ...customRegisterPermanent] }]);
            setData(() => (formatterData(response)));
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const formatterData = (list: DtoResponseList[]): EntityMain[] => {
        return list.map((rdi) => ({
            _id: rdi._id,
            codigo: rdi.IdIncidente,
            descripcion: rdi.BreveDescripcionIncidente,
            estado: rdi.Estado.Estado,
            idEstado: rdi.Estado.IdEstado,
            tipoIncidente: rdi.NombreIncidente,
            idTipoIncidente: rdi.IdNombreIncidente,
            fecha: new Date(rdi.FechaRegistro.Fecha),
            idObservacionSolucion: rdi.ObservacionSolucion?.IdEstado || 0
        })).sort((a, b) => Number(a.fecha) - Number(b.fecha)).reverse();
    }

    const onChangeFechaInicio = (newName: string) => {
        formFilter.setFieldValue("fechaInicio", newName);
    }

    const onChangeFechaFin = (newName: string) => {
        formFilter.setFieldValue("fechaFin", newName);
    }

    const onRedirectForm = (_id?: string) => {
        const params = _id ? `/${_id}` : '';
        navigate(`${AdapterConfigure.ROUTE_PREVIEW}${params}`, { replace: true })
    };

    const downloadFile = async (payload: EntityMain) => {
        try {
            // let nameFile = btoa(unescape(encodeURIComponent(payload.codigo)));
            // let path = `${process.env.REACT_APP_URL_MASTER}${AdapterConfigure.DOWNLOAD_PDF}/${nameFile}`;
            // let newPath = `${process.env.REACT_APP_URL_MASTER_NEW_BACKEND}${AdapterConfigure.DOWNLOAD_PDF_NEWBACKEND}/${payload._id}`
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const result = await repository.downloadPDF(payload._id);
            if (!result) return;

            const href = URL.createObjectURL(result);
            let alink = document.createElement('a');
            alink.href = href;
            alink.download = `${payload.codigo}.pdf`;
            alink.click();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        };
    }

    // Funciones del Modal de búsqueda

    const onChangeModal = (params: Partial<IConfigModal>) => {
        setConfigModal((prev) => ({
            ...prev,
            ...params
        }))
    };

    const onSubmitModal = async () => {
        const params: DtoRequestList = {
            $match: {
                "ObservacionSolucion.IdEstado": { $in: configModal.filter.estado.map(row => row.value) },
                "Procesos.Registrar.DatosUsuario.Identificacion": user.Identificacion,
                "Procesos.Registrar.IdFecha": {
                    $gte: parseInt(configModal.filter.fechaInicio.replaceAll('-', '')),
                    $lte: parseInt(configModal.filter.fechaFin.replaceAll('-', ''))
                }
            }
        }

        try {
            if (configModal.filter.estado.length === 0) throw Error(languageTranslate.moduloRDI.list.validate.formFilter.estado)
            if (!configModal.filter.fechaInicio) throw Error(languageTranslate.moduloRDI.list.validate.formFilter.fechaInicio)
            if (!configModal.filter.fechaFin) throw Error(languageTranslate.moduloRDI.list.validate.formFilter.fechaFin)

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            let response = await (new UseCaseList(repository).exec(params));
            response = (response ?? []).splice(0, 200)
            if (!response) return;
            const customRegisterPermanent = await repository.customClearRDI();
            await dbLocal.insertDataStore([{ nameStore: 'RDI', data: [...response, ...customRegisterPermanent] }]);
            setData(() => (formatterData(response ?? [])));
            onChangeModal(initIConfigModal)
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return {
        data,
        user,

        init,
        formFilter,

        onChangeFechaInicio,
        onChangeFechaFin,
        onRedirectForm,
        loadData,
        downloadFile,
        countProcess,

        onChangeModal,
        configModal,
        onSubmitModal,
    };
}
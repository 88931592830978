import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb";
import Stepper from "../../../shared/Components/ElementStepper";
import { DtoResponseForm } from "../Domain/DtoResponseForm";
import {
  InputAutoCompleteLocation,
  InputCheck,
  InputCheckItems,
  InputSelect,
  InputSelectPersonnel,
  InputText,
  InputTextArea,
} from "./../../../shared/Components/ElementInputsCostume";
import "./Style.scss";
import { ViewTipoInspeccion, ViewDetalleItems } from "./ViewStep4";
import { EntityLoadData } from "../Domain/EntityLoadData";
import { EntitySelectPersonal } from "../Domain/EntitySelectPersonal";
import { EntityDetalleInspeccion } from "../Domain/EntityDetalleInspeccion";
import { EntityAplica } from "../Domain/EntityAplica";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import ElementStepperContent from "../../../shared/Components/ElementStepperContent";
import { ListFilterOptionSelect } from "../../../shared/Infraestructure/ListFilterOptionSelect";
import { AdapterConfigure } from "../Infraestructure/AdapterConfigure";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";
// import { EntitySelectNivelGravedad } from "../Domain/EntitySelectNivelGravedad";
interface PropsViewMain {
  onChangeContentForTab: (newKey: number) => void;
  onChange: (name: string, value: any) => void;
  onChangeItem: (name: string, value: any) => void;
  onChangeModalFormItem: (show?: boolean) => void;
  actionsFormItem: (typeAction: number, payload: any) => void;
  save: () => Promise<any>;
  showModalFormItem: boolean;
  aplica: EntityAplica[];
  keyTab: number;
  mode: {
    text: string;
    key: string;
  };
  isBlockInputs: boolean;
  isModePreview?: boolean;
  isModeSurvey?: boolean;
  formRegistro: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  formRegistroItem: {
    values: any;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  data: DtoResponseForm;
  DetalleInspeccion: EntityDetalleInspeccion[];
  selectInformation: EntityLoadData;
  loading: boolean;
  isOPS: Boolean;
}

const View = (props: PropsViewMain) => {
  const {
    onChange,
    onChangeContentForTab,
    formRegistro,
    keyTab,
    selectInformation,
    loading,
    isBlockInputs,
    isModePreview,
  } = props;
  const valuePais = formRegistro.values["Pais"];
  const valueDelegacion = formRegistro.values["Delegacion"];
  const valueGrupo = formRegistro.values["Grupo"];
  const valueTipoOperacion = formRegistro.values["TipoOperacion"];
  const languageTranslate = LanguageTranslate();

  return (
    <div className="ViewInspeccionForm">
      <div className="container-fluid">
        <div className="row mb-2 mt-2">
          <ElementBreadCrumb
            list={[
              ...(props.isOPS
                ? languageTranslate.moduloInspeccion.form.navbarOPS
                : languageTranslate.moduloInspeccion.form.navbar),
              { text: props.mode.text, navigate: false, path: "" },
            ]}
          />
        </div>

        <Stepper
          data={[
            languageTranslate.moduloInspeccion.form.stepper.stepper1.text,
            languageTranslate.moduloInspeccion.form.stepper.stepper2.text,
            languageTranslate.moduloInspeccion.form.stepper.stepper3.text,
            languageTranslate.moduloInspeccion.form.stepper.stepper4.text,
          ]}
          onChange={props.onChangeContentForTab}
          stepPosition={props.keyTab}
        />

        {/** STEP 1 */}
        <ElementStepperContent
          step={1}
          currentStep={keyTab}
          title={
            languageTranslate.moduloInspeccion.form.stepper.stepper1.content
              .title
          }
          onSubmit={onChangeContentForTab}
        >
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .ot
            }
            name="OT"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={selectInformation.OT}
            loading={loading}
            disabled={isBlockInputs}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .area
            }
            name="Area"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={
              !valuePais?.value && !valueDelegacion?.value
                ? []
                : selectInformation.Area.filter(
                    (area) =>
                      area.dataComplete.Pais.IdPais === valuePais.value &&
                      area.dataComplete.Delegacion.Codigo ===
                        valueDelegacion.value
                  )
            }
            loading={loading}
            disabled={isBlockInputs}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .empresa
            }
            name="Empresa"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={
              !valuePais?.value || !valueGrupo?.value
                ? []
                : selectInformation.Empresa.filter(
                    (emp) =>
                      emp.dataComplete.Pais.IdPais === valuePais.value &&
                      emp.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_EMPRESA && (!!row.EstadoPNET || valueGrupo.value !== AdapterKeyGeneral.ID_GRUPO_COBRA)) &&
                      emp.dataComplete.Grupo.IdGrupo === valueGrupo.value
                    )
            }
            loading={loading}
            disabled={isBlockInputs}
            disabledVirtualized
            arrFilterOption={ListFilterOptionSelect.empresa}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .actividad
            }
            name="Actividad"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={(props.formRegistro.values.OT?.dataComplete?.Grupo  && props.selectInformation.Actividad[0]?.dataComplete?.Grupo?.Codigo) ? 
              props.selectInformation.Actividad.filter(row => row.dataComplete.Grupo?.Codigo === props.formRegistro.values.OT.dataComplete.Grupo?.Codigo)
              :
              props.selectInformation.Actividad
            }
            loading={loading}
            disabled={isBlockInputs}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .cliente
            }
            name="Cliente"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={
              !valuePais?.value || !valueGrupo.value
                ? []
                : selectInformation.Cliente.filter(
                    (cln) =>
                      cln.dataComplete.Pais.IdPais === valuePais.value &&
                      cln.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_CLIENTE && (!!row.EstadoPNET || valueGrupo.value !== AdapterKeyGeneral.ID_GRUPO_COBRA)) &&
                      cln.dataComplete.Grupo.IdGrupo === valueGrupo.value
                    )
            }
            loading={loading}
            disabled={isBlockInputs}
            arrFilterOption={ListFilterOptionSelect.cliente}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .jefeObra
            }
            name="JefeObra"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={
              !valuePais?.value
                ? []
                : selectInformation.JefeObra.filter(
                    (jfo) => jfo.dataComplete.IdPais === valuePais.value
                  )
            }
            loading={loading}
            arrFilterOption={ListFilterOptionSelect.personal}
            disabled={isBlockInputs}
            isAsync
            delaySearch={1000}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .coordinador
            }
            name="Coordinador"
            onChange={onChange}
            values={formRegistro.values}
            isRequired
            options={
              !valuePais?.value
                ? []
                : selectInformation.Coordinador.filter(
                    (cod) => cod.dataComplete.IdPais === valuePais.value
                  )
            }
            loading={loading}
            arrFilterOption={ListFilterOptionSelect.personal}
            disabled={isBlockInputs}
          />
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                .encargado
            }
            name="Encargado"
            onChange={onChange}
            values={formRegistro.values}
            isClearable
            options={
              !valuePais?.value
                ? []
                : selectInformation.Encargado.filter(
                    (ecr) => ecr.dataComplete.IdPais === valuePais.value
                  )
            }
            loading={loading}
            arrFilterOption={ListFilterOptionSelect.personal}
            disabled={isBlockInputs}
          />
        </ElementStepperContent>

        {/** STEP 2 */}
        <ElementStepperContent
          step={2}
          currentStep={props.keyTab}
          title={languageTranslate.moduloInspeccion.form.stepper.stepper2.text}
          onSubmit={props.onChangeContentForTab}
        >
          <InputCheck
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .inspeccionConjunta
            }
            name="ConjuntaTipo"
            onChange={props.onChange}
            values={props.formRegistro.values}
            disabled={isBlockInputs}
          />
          {props.formRegistro.values.ConjuntaTipo && (
            <>
              <InputSelect
                label={
                  languageTranslate.moduloInspeccion.form.stepper.stepper2
                    .content.personalConjunta
                }
                name="ConjuntaPersonal"
                onChange={props.onChange}
                values={props.formRegistro.values}
                isRequired
                options={
                  !valuePais?.value
                    ? []
                    : selectInformation.InspeccionConjunta.filter(
                        (isc) => isc.dataComplete.IdPais === valuePais.value || [1, 2].includes(isc.dataComplete?.IdRol || 0) || AdapterConfigure.TIPO_EXTRA_CONJUNTA.includes(isc.dataComplete.CodePerfil)
                      )
                }
                arrFilterOption={ListFilterOptionSelect.personal}
                disabled={isBlockInputs}
              />
            </>
          )}
          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .subContrata
            }
            name="SubContrata"
            isClearable
            onChange={props.onChange}
            values={props.formRegistro.values}
            options={
              !valuePais?.value
                ? []
                : selectInformation.SubContrata.filter(
                    (emp) => emp.dataComplete.Pais.IdPais === valuePais.value
                  )
            }
            disabled={isBlockInputs}
            arrFilterOption={ListFilterOptionSelect.subcontrata}
          />
          
          {
            (props.formRegistro.values.SubContrata && !props.formRegistro.values.ConjuntaTipo) ?
              <InputCheck
                label={
                  languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                    .inspeccionSubcontrataConjunta
                }
                name="InspeccionConSubContrata"
                onChange={props.onChange}
                values={props.formRegistro.values}
                disabled={isBlockInputs}
              />
            : null
          }

          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .tipoOperacion
            }
            name="TipoOperacion"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            options={selectInformation.TipoOperacion}
            disabled={isBlockInputs}
          />

          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .formato
            }
            name="Formato"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            options={
              !valueTipoOperacion?.value
                ? []
                : selectInformation.Formato.filter(
                    (formato) =>
                      formato.dataComplete.TipoOperacion.IdTipoOperacion === valueTipoOperacion.value &&
                      formato.dataComplete.Grupo[0]?.Pais.find(row => row.IdPais === props.formRegistro.values.Pais?.dataComplete?.IdPais)
                  )
            }
            disabled={isBlockInputs}
          />

          <InputSelect
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .momento
            }
            name="TipoMomentoTrabajo"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            options={selectInformation.TipoMomento}
            disabled={isBlockInputs}
          />
          <InputCheck
            label={languageTranslate.moduloInspeccion.form.stepper.stepper2.content.fueraHorario}
            name="FueraHorario"
            onChange={props.onChange}
            values={props.formRegistro.values}
            disabled={isBlockInputs}
          />

          <InputAutoCompleteLocation
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .lugar
            }
            name="Lugar"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            disabled={isBlockInputs}
          />
          <InputText
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .obraTD
            }
            name="Obra"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            disabled={isBlockInputs}
          />
          <InputText
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .trabajoRealizar
            }
            name="TrabajoRealizar"
            onChange={props.onChange}
            values={props.formRegistro.values}
            isRequired
            disabled={isBlockInputs}
          />
          <InputText
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                .placa
            }
            name="Placa"
            onChange={props.onChange}
            values={props.formRegistro.values}
            disabled={isBlockInputs}
            uppercase
          />
          {props.isOPS && (
            <>
              <InputCheck
                label={
                  languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                    .reconocimientoSST
                }
                name="ReconocimientoOPS"
                onChange={props.onChange}
                values={props.formRegistro.values}
                isRequired
                disabled={isBlockInputs}
              />
              {props.formRegistro.values.ReconocimientoOPS && (
                <InputText
                  label={
                    languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                      .observacionOPS
                  }
                  name="ObservacionOPS"
                  onChange={props.onChange}
                  values={props.formRegistro.values}
                  isRequired
                  disabled={isBlockInputs}
                />
              )}
            </>
          )}
        </ElementStepperContent>

        {/** STEP 3 */}
        <ElementStepperContent
          step={3}
          currentStep={props.keyTab}
          title={languageTranslate.moduloInspeccion.form.stepper.stepper3.text}
          onSubmit={props.onChangeContentForTab}
        >
          <InputSelectPersonnel
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper3.content
                .personal
            }
            name="PersonalUnidad"
            onChange={props.onChange}
            values={{ PersonalUnidad: props.formRegistro.values["Personal"] }}
            options={
              !valuePais?.value && !valueGrupo?.value
                ? []
                : selectInformation.Personal.filter(
                    (rsl) =>
                      (
                        rsl.dataComplete.IdPais === valuePais.value &&
                        rsl.dataComplete.Grupo === valueGrupo.value
                      )
                  )
            }
            arrFilterOption={ListFilterOptionSelect.personal}
            isMulti
            closeMenuOnSelect
            isClearable={false}
            disabled={isBlockInputs}
            isAsync={true}
            delaySearch={1000}
          />
          {props.formRegistro.values["Personal"] &&
            props.formRegistro.values["Personal"].length > 0 && (
              <table className="table table-striped mb-5 mt-5">
                <thead>
                  <tr>
                    <th scope="col">
                      {
                        languageTranslate.moduloInspeccion.form.stepper.stepper3
                          .content.table.id
                      }
                    </th>
                    <th scope="col">
                      {
                        languageTranslate.moduloInspeccion.form.stepper.stepper3
                          .content.table.name
                      }
                    </th>
                    <th scope="col">
                      {
                        languageTranslate.moduloInspeccion.form.stepper.stepper3
                          .content.table.lastName
                      }
                    </th>
                    <th scope="col">
                      {
                        languageTranslate.moduloInspeccion.form.stepper.stepper3
                          .content.table.responsable
                      }
                    </th>
                    {!isBlockInputs && <th scope="col"></th>}
                  </tr>
                </thead>
                <tbody>
                  {props.formRegistro.values["Personal"]?.map(
                    (psl: EntitySelectPersonal) => (
                      <tr>
                        <th scope="row">{psl.dataComplete.IdPersonal}</th>
                        <td>{psl.dataComplete.Nombres}</td>
                        <td>{psl.dataComplete.Apellidos}</td>
                        <td className="text-center">
                          <input
                            type="radio"
                            disabled={isBlockInputs}
                            checked={
                              psl.value ===
                              props.formRegistro.values[
                                "PersonalResponsableTrabajo"
                              ]?.value
                            }
                            onClick={() =>
                              props.onChange("PersonalResponsableTrabajo", psl)
                            }
                          />
                        </td>
                        {!isBlockInputs && (
                          <td
                            onClick={() =>
                              props.onChange("DeletePersonal", psl)
                            }
                          >
                            <i
                              className="fa-solid fa-trash"
                              style={{ fontSize: 15 }}
                            />
                          </td>
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
        </ElementStepperContent>

        {/** STEP 4 */}
        <ViewTipoInspeccion
          keyTab={props.keyTab}
          onChangeContentForTab={props.onChangeContentForTab}
          onChange={props.onChange}
          disabled={props.isBlockInputs}
          // ListTipoInspeccion={selectInformation.TipoInspeccion}
          ListTipoInspeccion={
            props.isBlockInputs ?
            (props.formRegistro.values.TipoInspeccion || [])
            :
            !valueTipoOperacion?.value
              ? []
              : selectInformation.TipoInspeccion.filter(
                  (tipoinspeccion) =>
                    tipoinspeccion.dataComplete.TipoOperacion
                      .IdTipoOperacion === valueTipoOperacion.value
                )
          }
          formRegistro={props.formRegistro}
        />

        <ViewDetalleItems
          keyTab={props.keyTab}
          onChangeModalFormItem={props.onChangeModalFormItem}
          showModalFormItem={props.showModalFormItem}
          actionsFormItem={props.actionsFormItem}
          onChangeContentForTab={props.onChangeContentForTab}
          formRegistro={props.formRegistro}
          formRegistroItem={props.formRegistroItem}
          onChange={props.onChange}
          onChangeItem={props.onChangeItem}
          DetalleInspeccion={props.DetalleInspeccion}
          mode={props.mode}
          isModePreview={isModePreview}
          isModeSurvey={props.isModeSurvey}
          isBlockInputs={props.isBlockInputs}
          selectInformation={{
            AnomaliaInspeccion: selectInformation.AnomaliaInspeccion,
            NivelGravedad: selectInformation.NivelGravedad,
            Resultado: selectInformation.Resultado,
            Aplica: props.aplica,
            TipoSituacion: selectInformation.TipoSituacion,
          }}
        />

        {[4].includes(props.keyTab) &&
          props.DetalleInspeccion.some(
            (row) => row.Anomalia.ResultadoAnomalia === 1
          ) && (
            <InputSelect
              label={
                languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                  .responsableLevantamiento
              }
              name="ResponsableLevantamiento"
              onChange={props.onChange}
              values={props.formRegistro.values}
              isRequired
              options={
                !valuePais?.value
                  ? []
                  : selectInformation.ResponsableLevantamiento.filter(
                      (rsl) => rsl.dataComplete.IdPais === valuePais.value
                    )
              }
              disabled={isBlockInputs}
              arrFilterOption={ListFilterOptionSelect.personal}
            />
          )}

        {[4].includes(props.keyTab) &&
          props.DetalleInspeccion.some(
            (row) => row.Anomalia.ResultadoAnomalia === 1
          ) && (
            <InputCheckItems
              label={
                languageTranslate.moduloInspeccion.form.stepper.stepper2.content
                  .paralizacionTrabajo
              }
              name="ParalizacionTrabajo"
              onChange={props.onChange}
              values={props.formRegistro.values/*{
                ParalizacionTrabajo: props.DetalleInspeccion.some(
                  (row) => row.ParalizacionTrabajoItem === "SI"
                )
                  ? true
                  : props.formRegistro.values["ParalizacionTrabajo"],
              }*/}
              disabled={isBlockInputs}
            />
          )}

        {[4].includes(props.keyTab) && (
          <InputTextArea
            label={
              languageTranslate.moduloInspeccion.form.stepper.stepper4.content
                .textObservacionGeneral
            }
            name="ObservacionGeneral"
            onChange={props.onChange}
            values={props.formRegistro.values}
            disabled={isBlockInputs}
          />
        )}

        {!isModePreview && [4].includes(props.keyTab) && (
          <>
            <button onClick={props.save} className="btn btn-primary w-100">
              {languageTranslate.moduloInspeccion.form.btnSave}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default View;

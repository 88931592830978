import { EntityTipoPregunta } from "../../../shared/Domain/Catalogos/EntityTipoPregunta";
import { EntityAutochequeoItems, EntityAutochequeoItemsTipologia } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { LanguageApp, LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "../Infaestructure/AdapterConfigure";

export class UseCaseLoadQuestionsAUT {
    private repository: RepositoryMain;
    private IdMomentoListaAutochequeo: number;
    private IdMomentoCierreControlPreventivo: number;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoListaAutochequeo = 1;
        this.IdMomentoCierreControlPreventivo = 5;
        this.languageDefault = 'es';
    }

    public async exec(OT:EntityOTs): Promise<EntityLoadQuestion> {
        const [AutoChequeoTipologia, AutochequeoTitulos, AutochequeoItems, PreguntasLA, TipoPreguntas] = await Promise.all([
            this.getAutochequeoTipologia(OT),
            this.getAutochequeoTitulos(OT),
            this.getAutochequeoItems(OT),
            this.getPreguntaLA(),
            this.getTipoPregunta(),
            // this.hasQuestionInCCP(OT.Delegacion.IdDelegacion, OT.Pais.IdPais, OT.Actividad.IdActividad)
        ]);

        // Obtener desde otra colección Medios Humanos
        const codeLanguage = LanguageApp();
        const _PreguntasLA = PreguntasLA.map((row, index, arr) => {
            const titleQuestion = TipoPreguntas.find(_row => _row.CodigoTitulo === row.tipoPregunta.codigo) || {
                _id: "",
                CodigoTitulo: "",
                IdTitulo: 0,
                estado: []
            };

            return ({
                _id: row._id,
                IdRegistro: row.IdPreguntala,
                Item: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.nombre || row.iteminIdioma[0]?.nombre,
                Help: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.help || row.iteminIdioma[0]?.help || '',
                Momento: {
                    IdMomento: titleQuestion?.IdTitulo || 0,
                    Momento: "NEW"
                },
                Disponibilidad: JSON.stringify(row.disponibilidad.map(_row => ({
                    Respuesta: _row.respuesta,
                    Estados: _row.mostrarEstados ? 1 : 0,
                    Observacion: _row.requiereObservacion ? 1 : 0,
                    EnviarCorreo: 0,
                    DevolverOrden: 0,
                    CantidadFotos: _row.requiereFoto ? 1 : 0,
                    IdHijo: arr.find(__row => __row.codigo === _row.codigoHijo)?.IdPreguntala || 0,
                    NombreFoto: "",
                    TypeInput: _row.opcionPregunta.codigo
                }))),
                Estados: "[]",
                VisibilidadPregunta: 1,
                TipologiaArray: `[{ "IdTipologia": ${titleQuestion?.IdTitulo}, "CodigoTipologia": "${titleQuestion?.CodigoTitulo}", "Orden": 1, "Titulo": { "IdTitulo": ${titleQuestion?.IdTitulo}, "Titulo": "${titleQuestion?.CodigoTitulo}", "CodigoTitulo": "${titleQuestion?.CodigoTitulo}" } }]`,
                Procesos: {},
                IdPais: 0,
                IdDelegacion: 0,
                IdActividad: 0,
                Orden: row.orden || index + 1,
                TypeInput: row.opcionPregunta.codigo
            }) as any
        }).sort((a, b) => a.Orden - b.Orden)
        
        // Juntar los items
        const newAutochequeoTitulos = ([] as EntityAutochequeoTitulos[]).concat(TipoPreguntas, AutochequeoTitulos);
        const newAutochequeoItems = ([] as EntityAutochequeoItems[]).concat(_PreguntasLA, AutochequeoItems)
        const availableTitles = this.filterAvailableTitles(newAutochequeoTitulos, newAutochequeoItems);

        // Agregar al último título la pregunta Firma Jefe Trabajo
        const LastAutochequeoTitulo = availableTitles[availableTitles.length - 1];
        if (LastAutochequeoTitulo) {
            newAutochequeoItems.push({
                _id: '00000000000000001',
                IdRegistro: 9999999,
                Item: LanguageTranslate().moduloAutoChequeo.form.inputs.textFirmaJefeTrabajo,
                Help: '',
                Momento: {
                    IdMomento: LastAutochequeoTitulo?.IdTitulo || 0,
                    Momento: ""
                },
                Disponibilidad: "[]",
                Estados: "[]",
                VisibilidadPregunta: 1,
                TipologiaArray: `[{ "IdTipologia": ${LastAutochequeoTitulo?.IdTitulo}, "CodigoTipologia": "${LastAutochequeoTitulo?.CodigoTitulo}", "Orden": 1, "Titulo": { "IdTitulo": ${LastAutochequeoTitulo?.IdTitulo}, "Titulo": "${LastAutochequeoTitulo?.CodigoTitulo}", "CodigoTitulo": "${LastAutochequeoTitulo?.CodigoTitulo}" } }]`,
                Procesos: {},
                IdPais: 0,
                IdDelegacion: 0,
                IdActividad: 0,
                Orden: 9999999,
                TypeInput: "FIR-OBLI-JEFE"
            } as any)
        };

        return ({
            AutoChequeoTipologia,
            AutochequeoTitulos: availableTitles,
            AutochequeoItems: newAutochequeoItems.sort((itemA, itemB) => itemA.IdRegistro - itemB.IdRegistro),
            cierreControlPreventivoHasQuestion: false // hasQuestionInCCP -- no se deberá manejar CCP en AUT
        });
    }

    private async getAutochequeoTipologia(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoTipologia();
        return arr.filter(item => item.IdActividad === OT.Actividad.IdActividad && item.IdDelegacion === OT.Delegacion.IdDelegacion && item.IdPais === OT.Pais.IdPais && [this.IdMomentoListaAutochequeo].includes(item.Momento.IdMomento));
    }

    private async getAutochequeoTitulos(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoTitulos();
        const result = arr.filter(item => 
            item.Actividad.IdActividad === OT.Actividad.IdActividad &&
            item.Delegacion.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.Pais.IdPais === OT.Pais.IdPais &&
            [this.IdMomentoListaAutochequeo].includes(item.Momento.IdMomento))

        const ListForMoment = {
            [this.IdMomentoListaAutochequeo]: [] as EntityAutochequeoTitulos[],
        }

        result.reduce((total, currentValue) => {
            if (currentValue.Momento.IdMomento === this.IdMomentoListaAutochequeo) {
                total[this.IdMomentoListaAutochequeo].push({ ...currentValue, adittionalIdTitle: [currentValue.IdTitulo] });
            }
            return total;
        }, ListForMoment);

        return ListForMoment[this.IdMomentoListaAutochequeo];
    }

    private async getAutochequeoItems(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoItems();
        return arr.filter(item =>
            item.IdActividad === OT.Actividad.IdActividad &&
            item.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.IdPais === OT.Pais.IdPais &&
            [this.IdMomentoListaAutochequeo].includes(item.Momento.IdMomento)
        )
    }

    private async getPreguntaLA() {
        let response = (await this.repository.getPreguntasLA()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.tipoPregunta.codigo));
        if (response.length === 0) 
            response = (await this.repository.reloadPreguntaLA()).filter(
                row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.tipoPregunta.codigo)
            );

        return response;
    }

    private async getTipoPregunta() {
        const codeLanguage = LanguageApp();
        let response = (await this.repository.getTipoPreguntas()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.codigo));
        if (response.length === 0) 
            response = (await this.repository.reloadEntityPregunta()).filter(
                row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.codigo)
            );

        return response.map((row: EntityTipoPregunta, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: "",
                Codigo: "",
                IdActividad: 0
            },
            CodigoTitulo: row.codigo,
            Delegacion: {
                Codigo: "",
                Delegacion: "",
                IdDelegacion: 0,
            },
            Estado: {
                Estado: "Activo",
                IdEstado: 1,
            },
            IdTitulo: row.IdTipoPreguntas,
            adittionalIdTitle: [row.IdTipoPreguntas],
            Momento: {
                IdMomento: 0,
                Momento: ""
            },
            Orden: index + 1,
            Pais: {
                IdPais: 0,
                Pais: ""
            },
            Requerimientos: {
                Fotografia: 0,
                Observacion: 0
            },
            Template: "",
            Tipologia: {
                CodigoTipologia: "",
                IdTipologia: 0,
                TipologíaTrabajo: ""
            },
            Titulo: (row.iteminIdioma.find(row => row.codigo === codeLanguage)?.nombre || '').toUpperCase() || (row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '').toUpperCase(),
        }));
    }

    private filterAvailableTitles(AutochequeoTitulos: EntityAutochequeoTitulos[], AutochequeoItems: EntityAutochequeoItems[] ) {
        return AutochequeoTitulos.filter(row => {
            let listQuestionShow = AutochequeoItems.filter(item =>
                JSON.parse(item.TipologiaArray).some((tpl: EntityAutochequeoItemsTipologia) => tpl.Titulo.IdTitulo === row?.IdTitulo && tpl.Titulo.CodigoTitulo === row.CodigoTitulo)
            );

            return listQuestionShow.length > 0;
        })
    }

    private async hasQuestionInCCP(IdDelegacion: number, IdPais: number, IdActividad: number): Promise<boolean> {
        const arr = await this.repository.getAutochequeoItems();
        return (arr.some(item =>
            item.IdActividad === IdActividad &&
            item.IdDelegacion === IdDelegacion &&
            item.IdPais === IdPais &&
            [this.IdMomentoCierreControlPreventivo].includes(item.Momento.IdMomento)
        ))
    }
}
import { EntityTipoPregunta } from "../../../shared/Domain/Catalogos/EntityTipoPregunta";
import { EntityAutochequeoItems, EntityAutochequeoItemsTipologia } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityAutochequeoTipologia } from "../../../shared/Domain/EntityAutochequeoTipologia";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { LanguageApp } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "../Infaestructure/AdapterConfigure";

export class UseCaseLoadQuestionsCP {
    private repository: RepositoryMain;
    private IdMomentoControlAutochequeo: number;
    private IdMomentoCierreControlPreventivo: number;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoControlAutochequeo = 2;
        this.IdMomentoCierreControlPreventivo = 5;
    }

    public async exec(OT:EntityOTs, Tipologias: EntityAutochequeoTipologia[], isModePreview?: boolean): Promise<EntityLoadQuestion> {
        let [AutoChequeoTipologia, AutochequeoTitulos, { AutochequeoItems }, PreguntasLA, TipoPreguntas] = await Promise.all([
            this.getAutochequeoTipologia(OT, Tipologias),
            this.getAutochequeoTitulos(OT, Tipologias, isModePreview),
            this.getAutochequeoItems(OT, Tipologias, isModePreview),
            this.getPreguntaLA(),
            this.getTipoPregunta(),
        ]);

        const codeLanguage = LanguageApp();
        const _PreguntasLA = PreguntasLA.map((row, index, arr) => {
            const titleQuestion = TipoPreguntas.find(_row => _row.CodigoTitulo === row.tipoPregunta.codigo) || {
                _id: "",
                CodigoTitulo: "",
                IdTitulo: 0,
                estado: []
            };

            return ({
                _id: row._id,
                IdRegistro: row.IdPreguntala,
                Item: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.nombre || row.iteminIdioma[0]?.nombre,
                Help: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.help || row.iteminIdioma[0]?.help || '',
                Momento: {
                    IdMomento: titleQuestion?.IdTitulo || 0,
                    Momento: ""
                },
                Disponibilidad: JSON.stringify(row.disponibilidad.map(_row => ({
                    Respuesta: _row.respuesta,
                    Estados: _row.mostrarEstados ? 1 : 0,
                    Observacion: _row.requiereObservacion ? 1 : 0,
                    EnviarCorreo: 0,
                    DevolverOrden: 0,
                    CantidadFotos: _row.requiereFoto ? 1 : 0,
                    IdHijo: arr.find(__row => __row.codigo === _row.codigoHijo)?.IdPreguntala || 0,
                    NombreFoto: "",
                    TypeInput: _row.opcionPregunta.codigo
                }))),
                Estados: "[]",
                VisibilidadPregunta: 1,
                TipologiaArray: `[{ "IdTipologia": ${titleQuestion?.IdTitulo}, "CodigoTipologia": "${titleQuestion?.CodigoTitulo}", "Orden": 1, "Titulo": { "IdTitulo": ${titleQuestion?.IdTitulo}, "Titulo": "${titleQuestion?.CodigoTitulo}", "CodigoTitulo": "${titleQuestion?.CodigoTitulo}" } }]`,
                Procesos: {},
                IdPais: 0,
                IdDelegacion: 0,
                IdActividad: 0,
                Orden: row.orden || index + 1,
                TypeInput: row.opcionPregunta.codigo
            }) as any
        }).sort((a, b) => a.Orden - b.Orden)


        const ListIdTitulos = AutochequeoTitulos.map(row => row.IdTitulo);
        AutochequeoItems = AutochequeoItems.map((row) => {
            const tipologias: EntityAutochequeoItemsTipologia[] = JSON.parse(row.TipologiaArray);
            const arrMultipleTitulo = tipologias.filter(_row => ListIdTitulos.includes(_row.Titulo.IdTitulo));
            // se guardará solo al primer título, para evitar la duplicidad de la misma pregunta en diferentes tipologías
            if (arrMultipleTitulo.length > 1) row.TipologiaArray = JSON.stringify([arrMultipleTitulo[0]]);

            return row;
        });

        const newAutochequeoTitulos = ([] as EntityAutochequeoTitulos[]).concat(isModePreview ? TipoPreguntas : [], AutochequeoTitulos);
        const newAutochequeoItems = ([] as EntityAutochequeoItems[]).concat(isModePreview ? _PreguntasLA : [], AutochequeoItems)
        const availableTitles = this.filterAvailableTitles(newAutochequeoTitulos, newAutochequeoItems);

        return ({
            AutoChequeoTipologia,
            AutochequeoTitulos: availableTitles,
            AutochequeoItems: newAutochequeoItems,
            cierreControlPreventivoHasQuestion: false // Eliminar el CCP a los CP - hasQuestionInCCP
        })
    }

    private async getAutochequeoTipologia(OT: EntityOTs, Tipologias: EntityAutochequeoTipologia[]) {
        const arr = await this.repository.getAutochequeoTipologia();
        return arr.filter(item => item.IdActividad === OT.Actividad.IdActividad &&
            item.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.IdPais === OT.Pais.IdPais &&
            (Tipologias.some(tpl => tpl.IdTipologia === item.IdTipologia)));
    }

    private async getAutochequeoTitulos(OT: EntityOTs, Tipologias: EntityAutochequeoTipologia[], isModePreview?: boolean) {
        const arr = await this.repository.getAutochequeoTitulos();
        const result = arr.filter(item => 
            item.Actividad.IdActividad === OT.Actividad.IdActividad &&
            item.Delegacion.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.Pais.IdPais === OT.Pais.IdPais &&
            (Tipologias.some(tpl => (tpl.IdTipologia === item.Tipologia.IdTipologia && !isModePreview) || (tpl.IdTipologia === item.IdTitulo && !!isModePreview))))

        const ListForMoment = {
            [this.IdMomentoControlAutochequeo]: [] as EntityAutochequeoTitulos[],
        }

        result.reduce((total, currentValue: EntityAutochequeoTitulos) => {
            const indexFinded = ListForMoment[this.IdMomentoControlAutochequeo].findIndex(row => row.Titulo === currentValue.Titulo);
            if (currentValue.Momento.IdMomento === this.IdMomentoControlAutochequeo) {
                if (indexFinded === -1)
                    total[this.IdMomentoControlAutochequeo].push({ ...currentValue, adittionalIdTitle: [currentValue.IdTitulo] });
                else
                    total[this.IdMomentoControlAutochequeo][indexFinded].adittionalIdTitle = [...(total[this.IdMomentoControlAutochequeo][indexFinded].adittionalIdTitle || []), currentValue.IdTitulo]
            }

            return total;
        }, ListForMoment);

        return ListForMoment[this.IdMomentoControlAutochequeo];
    }

    private async getAutochequeoItems(OT: EntityOTs, Tipologias: EntityAutochequeoTipologia[], isModePreview?: boolean) {
        const arr = await this.repository.getAutochequeoItems();
        return ({ 
            AutochequeoItems: arr.filter(item =>
                item.IdActividad === OT.Actividad.IdActividad &&
                item.IdDelegacion === OT.Delegacion.IdDelegacion &&
                item.IdPais === OT.Pais.IdPais &&
                ( Tipologias.some((tpl) =>
                    (JSON.parse(item.TipologiaArray) as EntityAutochequeoItemsTipologia[]).some(
                    tplArr => tplArr.IdTipologia === tpl.IdTipologia || (tpl.IdTipologia === tplArr.Titulo.IdTitulo && !!isModePreview)
                    ) 
                ))
            ),
            hasQuestionInCCP: arr.some(item =>
                item.IdActividad === OT.Actividad.IdActividad &&
                item.IdDelegacion === OT.Delegacion.IdDelegacion &&
                item.IdPais === OT.Pais.IdPais &&
                [this.IdMomentoCierreControlPreventivo].includes(item.Momento.IdMomento)
            )
        })
    }

    private async getPreguntaLA() {
        const response = (await this.repository.getPreguntasLA()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.tipoPregunta.codigo));
        return response;
    }

    private async getTipoPregunta() {
        const codeLanguage = LanguageApp();
        const response = (await this.repository.getTipoPreguntas()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.codigo));
        return response.map((row: EntityTipoPregunta, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: "",
                Codigo: "",
                IdActividad: 0
            },
            CodigoTitulo: row.codigo,
            Delegacion: {
                Codigo: "",
                Delegacion: "",
                IdDelegacion: 0,
            },
            Estado: {
                Estado: "Activo",
                IdEstado: 1,
            },
            IdTitulo: row.IdTipoPreguntas,
            Momento: {
                IdMomento: 0,
                Momento: ""
            },
            Orden: index + 1,
            Pais: {
                IdPais: 0,
                Pais: ""
            },
            Requerimientos: {
                Fotografia: 0,
                Observacion: 0
            },
            Template: "",
            Tipologia: {
                CodigoTipologia: "",
                IdTipologia: 0,
                TipologíaTrabajo: ""
            },
            Titulo: (row.iteminIdioma.find(row => row.codigo === codeLanguage)?.nombre || '').toUpperCase(),
        }));
    }

    private filterAvailableTitles(AutochequeoTitulos: EntityAutochequeoTitulos[], AutochequeoItems: EntityAutochequeoItems[] ) {
        return AutochequeoTitulos.filter(row => {
            let listQuestionShow = AutochequeoItems.filter(item =>
                JSON.parse(item.TipologiaArray).some((tpl: EntityAutochequeoItemsTipologia) => tpl.Titulo.IdTitulo === row?.IdTitulo && tpl.Titulo.CodigoTitulo === row.CodigoTitulo)
            );

            return listQuestionShow.length > 0;
        })
    }
}
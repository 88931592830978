import { legacy_createStore as createStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import genericoSlice from './SliceGenerico';
import authenticationSlice from './SliceAuthentication';

const rootReducer = combineReducers({
    generico: genericoSlice,
    auth: authenticationSlice,
});

export const AdapterStore = createStore(rootReducer);
export const useAppDispatch = () => useDispatch();
export const globalDispatch = AdapterStore.dispatch;
export type RootState = ReturnType<typeof rootReducer>;


// Library
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

// Infraestructure
import {
    addLoading,
    removeLoading,
    changeOnline,
    changeCountProcess,
    removeLoadingMaestros,
    changeAvailableUpdate,
    addLoadingMaestros,
} from "../../context/shared/Infraestructure/SliceGenerico";
import { AdapterLoadMasterGuestMode } from "../../context/shared/Infraestructure/AdapterLoadMasterGuestMode";
import { changePermisoVariable, signIn } from "../../context/shared/Infraestructure/SliceAuthentication";
import { AdapterLoadMaster } from "../../context/shared/Infraestructure/AdapterLoadMaster";
import { LanguageTranslate } from "../../context/shared/Infraestructure/LanguageTranslate";
import { AdapterGenerico } from "../../context/shared/Infraestructure/AdapterGenerico";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { RootState } from "../../context/shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Application
import { UseCaseExecuteProcess } from "../Application/UseCaseExecuteProcess";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

// Domain
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";
import { EntityFileLocal } from "../../context/shared/Domain/EntityFileLocal";
import { AdapterCheckConnection } from "../../context/shared/Infraestructure/AdapterCheckConnection";
import { EntityAutochequeo } from "../../context/shared/Domain/EntityAutochequeo";
import { EntityLoadMasterFromApp } from "../../context/shared/Domain/EntityDataAccess";

export const Controller = () => {
    const { generico: { websocket, dbLocal, loadingMaestros, availableUpdate, showContainerMessage }, auth: { user, permisoVariables, preferencia } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const languageTranslate = LanguageTranslate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMasterGuestMode = new AdapterLoadMasterGuestMode(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    let [sw, setSW] = useState<ServiceWorkerRegistration | undefined>();
    const [updating, setUpdating] = useState<boolean>(false);
    const [forceUpdateApp, setForceUpdateApp] = useState<boolean>(false);

    let intervalVerifyRDI: NodeJS.Timer;

    const init = async () => {
        try {
            await requirePermiss();
            if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
                let service: ServiceWorkerRegistration | undefined = await serviceWorkerRegistration.register({ onUpdate(registration) { dispatch(changeAvailableUpdate(true)); }, });
                setSW(service);
                dispatch(changeAvailableUpdate(!!service?.waiting));
            }

            if (preferencia.forceOffline) dispatch(changeOnline(false)); // solo si marcó, no tomar el valor si está desmarcado
            window.addEventListener('online', onOnline);
            window.addEventListener('offline', onOffline);

            let permisos = await updateUserInformation();
            await callToAllInitialServices(permisos);
            repository.getNotificationsPending({ identificacion: user.Identificacion });
            if (new AdapterCheckConnection().isStable()) await verifyProcess(true);
            dispatch(removeLoadingMaestros());

            intervalVerifyRDI = setInterval(verifyProcess, 60000);
        } catch (error) {
            window.location.reload();
        }
    };

    const requirePermiss = async () => {
        try {
            // let permissMicrophone = await AdapterPermiss.permissMicrophone();
            // if (!permissMicrophone) { throw Error('Debe permitir el uso del microfono'); }

            // let permissGeolocation = await AdapterPermiss.permissGeolocation();
            // if (!permissGeolocation) { throw Error('Debe permitir el uso del GPS'); }

            // let permissNotification = await AdapterPermiss.permissNotification();
            // if (!permissNotification) { throw Error('Debe permitir el uso de las notificaciones'); }
        } catch (error) {
            await AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
            await requirePermiss();
        }
    };

    const callToAllInitialServices = async (permisos: EntityLoadMasterFromApp) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            if (user.IdUsuario === 0) {
                let { servicesCalleds, countryGuest }: { servicesCalleds: EntityInformationDataInitial | null, countryGuest: number[] | null } = AdapterStorage.get(["servicesCalleds", "countryGuest"]);
                let idPais = countryGuest ? countryGuest[0] : 0;
                if (!servicesCalleds) {

                    // Llamada al servicio de País para consultar los datos a Lite a cargar
                    const paisesOptions = await repositoryLoadMasterGuestMode.selectInitPais();
                    let options = paisesOptions.reduce((obj, row) => { Object.assign(obj, { [row._id]: `${row.Pais}` }); return obj; }, {});

                    // Carga el modal a mostrar
                    const result = await Swal.fire({
                        title: LanguageTranslate().moduloLogin.textoTitleSelectPais,
                        input: 'select',
                        inputOptions: options,
                        inputPlaceholder: LanguageTranslate().moduloLogin.textoPlaceholderSelectPais,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        backdrop: true,
                        reverseButtons: false,
                        focusConfirm: true,
                        target: '#root',
                        inputValidator: (value: any) => {
                            return new Promise((resolve) => {
                                if (value.trim() === '') {
                                    resolve(LanguageTranslate().moduloLogin.textoErrorSelectPais);
                                }
                                else {
                                    resolve(null);
                                }
                            })
                        }
                    });

                    // 
                    idPais = paisesOptions.find(row => row._id === result.value)?.IdPais || 0;
                    AdapterStorage.set('countryGuest', [idPais]);
                }

                let initServicesCalleds = repositoryLoadMaster.initServicesCalled();
                await (repositoryLoadMasterGuestMode.loadMasterFromInstallApp({ pais: [idPais], grupo: [], delegacion: [], ot: [] }, initServicesCalleds));
                AdapterStorage.set('servicesCalleds', initServicesCalleds);
            } else {
                let { servicesCalledsWeek }: { servicesCalledsWeek: string | null } = AdapterStorage.get(["servicesCalledsWeek"]);
                let servicesCalleds = repositoryLoadMaster.initServicesCalled();

                const timeServiceCalledWeek = servicesCalledsWeek ? new Date(servicesCalledsWeek) : new Date();
                const calculatedDays = AdapterGenerico.calculoTiempoDias(timeServiceCalledWeek);
                const ListIdDate = {
                    domingo: 0,
                    lunes: 1,
                    martes: 2,
                    miercoles: 3,
                    jueves: 4,
                    viernes: 5,
                    sabado: 6
                }
                const diasNoPermitidos = [ListIdDate.jueves, ListIdDate.viernes, ListIdDate.sabado, ListIdDate.domingo];
                const param = AdapterGenerico.getQueryUrl('isForceApp');

                const { [`preference${user.IdUsuario}`]: preference } = AdapterStorage.get(`preference${user.IdUsuario}`);
                const isActiveModeForceOffline = preference?.forceOffline;
                if (isActiveModeForceOffline) return;

                if(param && param === 'true') {
                    dispatch(addLoadingMaestros({ textLoadingMaestro: languageTranslate.textoConfigurandoAreaTrabajo }));
                    await repositoryLoadMaster.loadMasterOneTimeForWeek(permisos, servicesCalleds, languageTranslate.textoConfigurandoAreaTrabajo);
                    window.history.pushState({}, '', `${window.location.pathname}`);
                    AdapterStorage.set('servicesCalledsWeek', new Date().toISOString());
                } else if (
                    (!servicesCalledsWeek && !diasNoPermitidos.includes(timeServiceCalledWeek.getUTCDay())) ||
                    (calculatedDays >= 7  && !diasNoPermitidos.includes(timeServiceCalledWeek.getUTCDay()))
                ) {
                    dispatch(addLoadingMaestros({ textLoadingMaestro: languageTranslate.textoSincronizacionSemanalCatalogo }));
                    await repositoryLoadMaster.loadMasterOneTimeForWeek(permisos, servicesCalleds);
                    AdapterStorage.set('servicesCalledsWeek', new Date().toISOString());
                } else {
                    await repositoryLoadMaster.loadMasterFromApp(permisos, servicesCalleds);
                }

                AdapterStorage.set('servicesCalleds', servicesCalleds);
            }

        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    };

    const onOffline = () => {
        AdapterGenerico.createToast('Sin conexión', 'warning');
        dispatch(changeOnline(false));
    };

    const onOnline = async () => {
        if (preferencia.forceOffline) {
            dispatch(changeOnline(false));
            return;
        }

        websocket.init();
        dispatch(changeOnline(true));

        await verifyProcess(true);

        AdapterGenerico.createToast('Conexión establecida', 'success');
    };

    const verifyProcess = async (force: boolean = false) => {
        let count: number = 0;
        let countRDI: number = await verifyProcessRDI(force);
        let countInspeccion: number = await verifyProcessInspecciones(force);
        let countAutochequeo: number = await verifyProcessAutochequeo(force);
        let countTermsAccepted: number = await verifyProcessTerms(force);

        count += countRDI + countInspeccion + countAutochequeo + countTermsAccepted;
        dispatch(changeCountProcess(count));
    }

    const verifyProcessRDI = async (force: boolean = false): Promise<number> => {
        if (!force && !new AdapterCheckConnection().isStable()) { return 0; }

        let dataRDI: Array<any> = await dbLocal.selectAllStore('RDI');
        dataRDI = dataRDI.filter(row => row.Estado.IdEstado === -1);

        let countProcess: number = dataRDI.length;

        for (let row of dataRDI) {
            try {
                const formData = new FormData()
                formData.append('RDI', JSON.stringify(row.dataSend.RDI));
                row.dataSend.File.forEach((file: File, index: number) =>
                    formData.append('File', file, `${AdapterGenerico.formatoParaGuardarImagenes()}_1${index}_${
                        row.dataSend.RDI.Procesos.Registrar.IdUsuario
                    }.${file.type.split('/').pop() || ''}`)
                )

                if (row.dataSend?.FileSubsanacion) row.dataSend.FileSubsanacion.forEach((file: File, index: number) => 
                    formData.append('FileSolucion', file, `${AdapterGenerico.formatoParaGuardarImagenes()}_2${index}_${
                        row.dataSend.RDI.Procesos.Registrar.IdUsuario
                    }.${file.type.split('/').pop() || ''}`)
                )
                    


                formData.append('listFotosCargadas', JSON.stringify(row.dataSend.listFotosCargadas));

                if (row?.modeForm === 'CreateGuest' || row.guest) formData.append("Guest", "true");

                let obj: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: formData, method: 'POST', typeAuth: 'basic', typeRequest: 'form', typeResponse: 'json', url: '/ProcesoRDI' });
                await dbLocal.deleteByIndexStore({ nameStore: 'RDI', value: row._id });
                if (Array.isArray(obj) && obj.length > 0) obj = ({ ...obj[0], guest: !!row.guest })
                await dbLocal.insertDataStore({ nameStore: 'RDI', data: obj });

                countProcess -= 1;
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessInspecciones = async (force: boolean = false): Promise<number> => {
        if (!force && !new AdapterCheckConnection().isStable()) { return 0; }

        let data: Array<any> = await dbLocal.selectAllStore('Inspeccion');
        data = data.filter(row => row.Estado?.IdEstado === -1);

        let countProcess: number = data.length;

        for (let row of data) {
            try {
                let url = row.modeSave ? '/send/ProcesoInspeccion' : '/send/ProcesoInspeccionLevantamiento'

                const formData = new FormData();
                formData.append("listFotos", JSON.stringify(row.dataSend.listFotos));
                row.dataSend.listFile.forEach((file: EntityFileLocal) => formData.append('File', file.file, file.name));
                if (row.modeSave === 1) {
                    const { _id, ...rest } = row.dataSend.INSP;
                    formData.append("INSP", JSON.stringify(rest))
                }
                else {
                    formData.append("INSP", JSON.stringify({
                        IdInspeccion: row.dataSend.INSP.IdInspeccion,
                        DetalleInspeccion: JSON.stringify(row.dataSend.INSP.DatosInspeccion.DetalleInspeccion),
                        Estado: row.dataSend.INSP.Estado,
                        Procesos: row.dataSend.INSP.Procesos,
                        DatosInspeccion: row.dataSend.INSP.DatosInspeccion
                    }))
                }

                let obj: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: formData, method: 'POST', typeAuth: 'basic', typeRequest: 'form', typeResponse: 'json', url });
                await dbLocal.deleteByIndexStore({ nameStore: 'Inspeccion', value: row._id });
                await dbLocal.insertDataStore({ nameStore: 'Inspeccion', data: row.modeSave === 1 ? obj.Data : row.dataSend.INSP });

                countProcess -= 1;
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessAutochequeo = async (force: boolean = false): Promise<number> => {
        if (!force && !new AdapterCheckConnection().isStable()) { return 0; }

        let data: Array<any> = await dbLocal.selectAllStore('Autochequeo');
        data = data.filter(row => row.Estado?.IdEstado === -1);
        const itemsPrincipal = data.filter(row => [1, 3].includes(row.Momento.IdMomento)); // Registros padres que fueron guardados de forma offline

        // Item hijos
        const itemsDepends = data.filter(row => row.Momento.IdMomento === 2 && row.dataSend); // Registros que solo son hijos (Control Preventivo)
        const itemDependsWithFatherSaved = itemsDepends.filter(row => !itemsPrincipal.some(x => x._id === row.IdControlPreventivo) && typeof row.IdControlPreventivo === 'number'); // Anidado con el registro padre, que ya está guardado por el servidor
        const itemDependsWithoutFatherSaved = itemsDepends.filter(row => itemsPrincipal.some(x => x._id === row.IdControlPreventivo) && typeof row.IdControlPreventivo === 'string'); // Anidado con el registro padre también guardado de forma offline

        let countProcess: number = data.length; 

        // Función de tipo plantilla para guardar
        let url = '/send/AutochequeoPWA';
        const save = async (row: any): Promise<{ Data: EntityAutochequeo } | null> => {
            if (!row.dataSend) return null;

            const formData = new FormData();
            row.dataSend?.Files.forEach((file: { name: string; value: any }) => formData.append("File", file.value, file.name));
            if (!row.Procesos.Registrar.IdFecha || row.Id === 0) { // IdFecha se genera en el back
                const { _id, ...rest } = row.dataSend.autochequeo;
                formData.append('AUTOCHEQUEO', JSON.stringify(rest));
            } else {
                formData.append('AUTOCHEQUEO', JSON.stringify(row.dataSend.autochequeo));
            }
            formData.append('CORREO', JSON.stringify(row.dataSend.correo));
            formData.append('DOCUMENTACION', JSON.stringify(row.dataSend.documentacion));

            if (row.modeForm === 'CPTGuest' || row.Guest) formData.append('Guest', "true");

            let obj: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: formData, method: 'POST', typeAuth: 'basic', typeRequest: 'form', typeResponse: 'json', url });
            await dbLocal.deleteByIndexStore({ nameStore: 'Autochequeo', value: row._id });
            await dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: { ...obj.Data, guest: !!row.guest } });

            return Array.isArray(obj) ? obj[0] : obj;
        }

        // Guardar registro con padres e hijos con padres guardados
        for (let item of [...itemsPrincipal, ...itemDependsWithFatherSaved]) {
            try {
                // Guarda los registros normales
                const result = await save(item);
                countProcess -= 1;

                // Valida si tiene Controles Preventivos asociados pendientes de envio
                if (result?.Data) {
                    const itemDependsxPrincipal = itemDependsWithoutFatherSaved.filter(row => row.IdControlPreventivo === item._id);    
                    for (let subItem of itemDependsxPrincipal) {
                        subItem.dataSend.autochequeo.IdControlPreventivo = result.Data.Id;
                        await save(subItem);
                        countProcess -= 1;
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const verifyProcessTerms = async (force: boolean = false): Promise<number> => {
        if (!force && !new AdapterCheckConnection().isStable()) { return 0; }

        let dataTermsAccepted: Array<any> = await dbLocal.selectAllStore('TermsAccept');
        dataTermsAccepted = dataTermsAccepted.filter(row => row.Estado.IdEstado === -1);

        let countProcess: number = dataTermsAccepted.length;

        for (let row of dataTermsAccepted) {
            try {
                let obj: any = await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: row.dataSend, method: 'POST', typeAuth: 'basic', typeRequest: 'form', typeResponse: 'json', url: '/send/ProcesoNotificacionesHistorico' });
                const notificacionAccepted = JSON.parse(obj)[0];
                const infoEmail = {
                    NotificacionHistorico: {
                        IdNotificacionHistorico: notificacionAccepted.IdNotificacionHistorico,
                        TituloDocumento: notificacionAccepted.TituloDocumento,
                        ContenidoAceptado: notificacionAccepted.ContenidoAceptado,
                        Plataforma: notificacionAccepted.DatosPlataforma.Plataforma
                    },
                    Usuario: row.Usuario,
                }
                await (new UseCaseExecuteProcess<any>(repository)).exec({ type: 'api', body: infoEmail, method: 'POST', typeAuth: 'basic', typeRequest: 'form', typeResponse: 'json', url: '/send/EnviarEmailAceptaTerminos' });
                await dbLocal.deleteByIndexStore({ nameStore: 'TermsAccept', value: row._id });
                await dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: { ...notificacionAccepted, _id: Date.now().toString() } });

                countProcess -= 1;
            } catch (error) {
                console.error(error);
            }
        }

        return countProcess;
    };

    const end = async () => {
        clearInterval(intervalVerifyRDI);
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
    };

    const updateApp = async () => {
        if (!sw || !sw.waiting || updating) {
            return;
        }

        try {
            setUpdating(true);
            sw.waiting.postMessage({ type: "SKIP_WAITING" });

            // Muestra el toast de inicio de descarga
            await toast.promise(
                new Promise((resolve) => {
                    sw?.update().then(() => resolve(true));
                }),
                {
                    pending: 'Descargando...',
                    success: 'Descarga exitosa',
                    error: 'Ocurrió un error'
                },
                {
                    style: {
                        fontSize: '0.8rem',
                        height: 30
                    },
                    position: "top-center"
                }
            );

            // Recarga la página después de la actualización
            window.location.reload();
        } catch (error) {
            setUpdating(false);
        }
    };

    const updateUserInformation = async (): Promise<EntityLoadMasterFromApp> => {
        const defaultValue: EntityLoadMasterFromApp = { pais: permisoVariables.arrIdPaises, grupo: permisoVariables.arrIdGrupos, delegacion: permisoVariables.arrIdDelegaciones, ot: permisoVariables.arrIdOT, diferencialNewOT: [], diferencialDeleteOT: [] };
        if (!new AdapterCheckConnection().isStable()) return defaultValue;
        if (!user.Identificacion) return defaultValue;
        try {
            let response = await repository.UpdateUserInformation({ identificacion: user.Identificacion });
            if (response === null) return defaultValue;

            const isUpdateApp = new AdapterGenerico().compararVersiones(`${process.env.REACT_APP_VERSION_SYSTEM}`, response.user.VersionMinima);
            setForceUpdateApp(() => isUpdateApp);
            let arrIdPaises: number[] = [];
            let arrIdGrupos: number[] = [];
            let arrIdDelegaciones: number[] = [];
            let arrIdOT: number[] = [];
            let newIdOTS: number[] = []

            switch (user.DatosRol.Codigo) {
                case '02AP':
                    arrIdPaises = user.Pais.map(row => row.IdPais);
                    arrIdGrupos = user.Pais.flatMap(row => row.Grupos.map(subrow => subrow.IdGrupo));
                    break;
                case '03AD':
                case '05DC':
                case '04U':
                case "Supervisor":
                    arrIdPaises = user.Pais.map(row => row.IdPais);
                    arrIdGrupos = user.Pais.flatMap(row => row.Grupos.map(subrow => subrow.IdGrupo));
                    // const selectAllDelegacion = user.Delegacion.some(row => row.todaDelegacion);
                    arrIdDelegaciones = user.Delegacion.map((item) => item.IdDelegacion); // selectAllDelegacion ? [] : user.Delegacion.map((item) => item.IdDelegacion);
                    arrIdOT = user.Delegacion.flatMap(row => row.OT.map(subrow => subrow.IdOT));

                    // diferencial de OT
                    newIdOTS = Array.from(new Set([...arrIdOT, ...permisoVariables.arrIdOT])).filter(row => {
                        const count = [...arrIdOT, ...permisoVariables.arrIdOT].filter(item => item === row).length;
                        return count === 1;
                    });

                    break;
            }

            dispatch(changePermisoVariable({ arrIdPaises, arrIdGrupos, arrIdDelegaciones, arrIdOT }));
            dispatch(signIn({ token: '', tokenRefresh: '', user: { ...response.user, DatosPersonal: user.DatosPersonal }, menu: response?.menu, }));

            return {
                pais: arrIdPaises,
                grupo: arrIdGrupos,
                delegacion: arrIdDelegaciones,
                ot: arrIdOT,
                diferencialNewOT: newIdOTS.filter(row => !permisoVariables.arrIdOT.includes(row)),
                diferencialDeleteOT: newIdOTS.filter(row => permisoVariables.arrIdOT.includes(row))
            };
        } catch (error) {
            return defaultValue;
        }
    }

    return {
        init,
        end,

        availableUpdate,
        loadingMaestros,

        updateApp,
        callToAllInitialServices,
        updating,

        forceUpdateApp,
        isForceModeOffline: preferencia.forceOffline,
        onOnline,
        showContainerMessage,
    };
}
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { EntityMain } from '../Domain/EntityMain';
import './Style.scss';

interface PropsViewMain {
    data: EntityMain[];
    formFilter: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    onChangeFechaInicio: Function;
    onChangeFechaFin: Function;
    onRedirectForm: (_id?: string) => void;
    downloadFile: (payload: EntityMain) => void;
    reloadData: Function;
    user: EntityDataUsuario;
    openModal: () => void
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className='ViewRDIListar'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={languageTranslate.moduloRDI.list.navbar} />
                <div className="row">
                    <div className="col-12 text-end">
                        <button className='btn btn-sm btn-primary btn-accion' onClick={() => props.onRedirectForm()}>+ {languageTranslate.moduloRDI.list.btnAgregar}</button>
                    </div>
                </div>
                <div className="row pt-3 mb-5">
                    <div className="col-12 mb-5">
                        {
                            props.data.map((rdi, position) =>
                                <div className="row pb-3" key={rdi._id}>
                                    <div className="col-12">
                                        <div className={`shadow-lg ${rdi.idEstado === -1 ? 'pending' : (rdi.idObservacionSolucion === 1 ? '' : 'levantamiento')}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }}>
                                            <div className="row" style={{ margin: '1px' }}>
                                                <div className="col-7">
                                                    <span className="text-danger" style={{ fontSize: '13px' }}>#{props.data.length - position} / {rdi.codigo}</span>
                                                </div>
                                                <div className="col-5">
                                                    <span className="text-danger" style={{ fontSize: '13px' }}>{rdi.idTipoIncidente === 1 ? languageTranslate.moduloInspeccion.form.tipoSituacion.textoCondicionInsegura : languageTranslate.moduloInspeccion.form.tipoSituacion.textoActoInseguro}</span>
                                                </div>
                                            </div>
                                            <div className="row pt-1" style={{ margin: '1px' }}>
                                                <div className="col-10">
                                                    <span style={{ fontSize: '13px' }}>{rdi.descripcion}</span>
                                                </div>
                                                <div className="col-2 text-center">
                                                    {
                                                        rdi.idObservacionSolucion === 1 ?
                                                            <i className="fa-solid fa-eye" onClick={() => props.onRedirectForm(rdi._id)} style={{ fontSize: '20px' }} />
                                                            :
                                                            <i className="fa-solid fa-pen" onClick={() => props.onRedirectForm(rdi._id)} style={{ fontSize: '20px' }} />
                                                    }
                                                    { rdi.idEstado === -1 ? null : <i className=" mt-2 fa-solid fa-file-pdf" onClick={() => props.downloadFile(rdi)} style={{ fontSize: '20px' }} /> }
                                                </div>
                                            </div>
                                            <div className="row pt-1" style={{ margin: '1px' }}>
                                                <div className="col-7">
                                                    <span className='text-muted' style={{ fontSize: '13px' }}> {AdapterGenerico.calculoTiempo(rdi.fecha as Date, languageTranslate.code as 'es' | 'en' | 'fr' | 'it')}</span>
                                                </div>
                                                <div className="col-5">
                                                    <span className='text-muted' style={{ fontSize: '13px' }}>{languageTranslate.moduloRDI.list.textoEstado}: {rdi.estado}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                {
                    props.data.length === 0 && (
                        <ElementEmptyList className="data-empty" text={languageTranslate.moduloRDI.list.textoVacio} />
                    )
                }

                {
                    props.user.IdUsuario === 0 ?
                        null :
                        <>
                            <div className="position-fixed search bg-primary" onClick={() => props.openModal()}>
                                <i className="fa-solid fa-magnifying-glass" style={{ fontSize: 20 }} />
                            </div>
                            <div className="position-fixed reload bg-primary" onClick={() => props.reloadData()}>
                                <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} />
                            </div>
                        </>
                }
            </div>
        </div >
    )
};
import { InputAutoCompleteLocation, InputSelect, InputText } from '../../../../shared/Components/ElementInputsCostume';
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent';
import { AdapterKeyGeneral } from '../../../../shared/Infraestructure/AdapterKeyGeneral';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { ListFilterOptionSelect } from '../../../../shared/Infraestructure/ListFilterOptionSelect';
import { EntityLoadData, EntityLoadDataGuest } from '../../Domain/EntityUtils';

interface Props {
    onChangeContentForTab: (newKeyTab: number) => Promise<void>;
    onChange: (key: string, value: string) => void;
    formRegistro: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    keyTab: number;
    loading?: boolean;
    isBlockInputs?: boolean;
    selectInformation: EntityLoadData;
    selectInformationGuest: EntityLoadDataGuest;
    paisSelected: { IdPais: number; Pais: string; };
    isCP: boolean;
    isModeGuest: boolean;
}

const ViewDatosGenerales = (props: Props) => {
    const languageTranslate = LanguageTranslate();
    const languageTranslateForm = languageTranslate.moduloAutoChequeo.form;
    const codigoGrupoSelected = props.formRegistro.values.OT?.dataComplete?.Grupo?.Codigo || props.formRegistro.values.OTGuest?.dataComplete?.CodigoGrupo || ''; 

    return (
        <ElementStepperContent
          step={0}
          currentStep={props.keyTab}
          title={languageTranslateForm.tituloDatosGenerales}
          onSubmit={props.onChangeContentForTab}
          activateBackButton={props.isCP}
        >

            {
                props.isModeGuest ?
                <>
                    <InputSelect
                        label={languageTranslateForm.inputs.ot}
                        name="OTGuest"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={props.selectInformationGuest.OTLite}
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                    />

                    <InputSelect
                        label={languageTranslateForm.inputs.subContrata}
                        name="SubContrataGuest"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired={false}
                        isClearable={true}
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformationGuest.SubContrataLite.filter(sbc => sbc.dataComplete.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                        disabledVirtualized
                        arrFilterOption={ListFilterOptionSelect.subcontrata}
                        isMulti
                        closeMenuOnSelect
                        controlShouldRenderValue
                    />

                    <InputText
                        label={languageTranslateForm.inputs.obra}
                        name="Obra"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
        
                    <InputAutoCompleteLocation
                        label={languageTranslateForm.inputs.lugar}
                        name="Lugar"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
                    
                    <InputText
                        label={languageTranslateForm.inputs.trabajoRealizar}
                        name="TrabajoRealizar"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.jefeObra}
                        name="JefeObraGuest"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformationGuest.PersonalJefeObra.filter(psl => psl.dataComplete.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        arrFilterOption={ListFilterOptionSelect.personal}
                        disabled={props.isBlockInputs}
                        isAsync
                        delaySearch={1000}
                    />

                    <InputSelect
                        label={'Usuario Registra'}
                        name="UsuarioRegistraGuest"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformationGuest.PersonalLite.filter(psl => psl.dataComplete.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        arrFilterOption={ListFilterOptionSelect.personal}
                        disabled={props.isBlockInputs}
                        isAsync={true}
                        delaySearch={1000}
                    />

                </>
                : 
                <>
                    {
                        !props.isCP && (
                            <>
                                <InputSelect
                                    label={languageTranslateForm.inputs.ot}
                                    name="OT"
                                    onChange={props.onChange}
                                    values={props.formRegistro.values}
                                    isRequired
                                    options={props.selectInformation.OT}
                                    loading={props.loading}
                                    disabled={props.isBlockInputs}
                                />
                                { props.formRegistro.values['OT']?.dataComplete.CierreCP ? <p className='text-muted'>{languageTranslateForm.textoAyudaControlPreventivo}: {props.formRegistro.values['OT']?.dataComplete.CierreCP}</p> : null }
                            </>
                        )
                    }
        
                    <InputSelect
                        label={languageTranslateForm.inputs.empresa}
                        name="Empresa"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={
                            props.paisSelected.IdPais === -1 || !codigoGrupoSelected ?
                            [] :
                            props.selectInformation.Empresa.filter(emp =>
                                emp.dataComplete.Pais.IdPais === props.paisSelected.IdPais &&
                                emp.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_EMPRESA && (!!row.EstadoPNET || codigoGrupoSelected !== AdapterKeyGeneral.CODIGO_GRUPO_COBRA)) &&
                                emp.dataComplete.Grupo.Codigo === codigoGrupoSelected
                            )
                        }
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                        disabledVirtualized
                        arrFilterOption={ListFilterOptionSelect.empresa}
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.subContrata}
                        name="SubContrata"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired={false}
                        isClearable={true}
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformation.Subcontrata.filter(sbc => sbc.dataComplete.Pais.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                        disabledVirtualized
                        arrFilterOption={ListFilterOptionSelect.subcontrata}
                        isMulti
                        closeMenuOnSelect
                        controlShouldRenderValue
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.delegacion}
                        name="Delegacion"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformation.Delegacion.filter(dlg => dlg.dataComplete.Pais.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        disabled
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.actividad}
                        name="Actividad"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={
                            (props.selectInformation.Actividad[0]?.dataComplete?.Grupo?.Codigo && props.formRegistro.values.Grupo?.dataComplete?.Codigo)?
                                props.selectInformation.Actividad.filter(row => row.dataComplete.Grupo?.Codigo === props.formRegistro.values.Grupo?.dataComplete.Codigo)
                                :
                                props.selectInformation.Actividad
                        }
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.cliente}
                        name="Cliente"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={
                            props.paisSelected.IdPais === -1 || !codigoGrupoSelected ?
                            [] :
                            props.selectInformation.Cliente.filter(clt =>
                                clt.dataComplete.Pais.IdPais === props.paisSelected.IdPais &&
                                clt.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_CLIENTE && (!!row.EstadoPNET || codigoGrupoSelected !== AdapterKeyGeneral.CODIGO_GRUPO_COBRA)) &&
                                clt.dataComplete.Grupo.Codigo === codigoGrupoSelected
                            )
                        }
                        loading={props.loading}
                        disabled={props.isBlockInputs}
                        disabledVirtualized
                        arrFilterOption={ListFilterOptionSelect.cliente}
                    />
        
                    <InputText
                        label={languageTranslateForm.inputs.obra}
                        name="Obra"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
        
                    <InputAutoCompleteLocation
                        label={languageTranslateForm.inputs.lugar}
                        name="Lugar"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
        
                    <InputText
                        label={languageTranslateForm.inputs.trabajoRealizar}
                        name="TrabajoRealizar"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        disabled={props.isBlockInputs}
                    />
        
                    <InputSelect
                        label={languageTranslateForm.inputs.jefeObra}
                        name="JefeObra"
                        onChange={props.onChange}
                        values={props.formRegistro.values}
                        isRequired
                        options={props.paisSelected.IdPais === -1 ? [] : props.selectInformation.PersonalJefeObra.filter(psl => psl.dataComplete.IdPais === props.paisSelected.IdPais)}
                        loading={props.loading}
                        arrFilterOption={ListFilterOptionSelect.personal}
                        disabled={props.isBlockInputs}
                        isAsync
                        delaySearch={1000}
                    />
                
                </>
            }
        </ElementStepperContent>
    )
}

export default ViewDatosGenerales;
// Library
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
// import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { AdapterIndexedDB } from "../../../shared/Infraestructure/AdapterIndexedDB";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Domain
import { EntitySelectAnomaliaInspeccion } from "../Domain/EntitySelectAnomaliaInspeccion";
import { EntitySelectNivelGravedad } from './../Domain/EntitySelectNivelGravedad';
import { EntitySelectTipoInspeccion } from "../Domain/EntitySelectTipoInspeccion";
import { EntitySelectDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntitySelectPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntityDetalleInspeccion } from "../Domain/EntityDetalleInspeccion";
import { EntityAutoCompleteItem } from "../Domain/EntityAutocompleteItem";
import { EntitySelectResultado } from "../Domain/EntitySelectResultado";
import { EntitySelectEmpresa } from "../Domain/EntitySelectEmpresa";
import { EntitySelectOTs } from "../../../shared/Domain/EntityOTs";
import { EntitySelectArea } from "../Domain/EntitySelectArea";
import { EntitySelectPais } from "../Domain/EntitySelectPais";
import { DtoResponseForm } from "../Domain/DtoResponseForm";
import { EntityLoadData } from "../Domain/EntityLoadData";
import { EntityAplica } from "../Domain/EntityAplica";

// Use case
import { useCaseSearchCuadrillaPersonal } from "../Application/useCaseSearchCuadrillaPersonal";
import { UseCaseLoadDataPreview } from "../Application/useCaseLoadDataPreview";
import { UseCaseLoadDataSurvey } from "../Application/useCaseLoadDataSurvey";
import { useCaseSaveInspeccion } from "../Application/useCaseSaveInspeccion";
import { useCaseSaveSurvey } from "../Application/useCaseSaveSurvey";
import { UseCaseLoadData } from "../Application/useCaseLoadData";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityTipoOperacion } from "../../../shared/Domain/Catalogos/EntityTipoOperacion";
import { AdapterLoadMaster } from "../../../shared/Infraestructure/AdapterLoadMaster";

let TipoInspeccion: EntitySelectTipoInspeccion[] = [];
const languageTranslate = LanguageTranslate();
const CODE_TIPO_PERFIL_OBRA = "01"
const CODE_TIPO_PERFIL_DIRECTOR = "03"

export const Controller = () => {
  const [selectInformation, setSelectInformation] = useState<EntityLoadData>({
    OT: [],
    Empresa: [],
    Cliente: [],
    SubContrata: [],
    Area: [],
    Actividad: [],
    JefeObra: [],
    Coordinador: [],
    Encargado: [],
    ResponsableLevantamiento: [],
    InspeccionConjunta: [],
    Formato: [],
    TipoMomento: [],
    Personal: [],
    TipoInspeccion: [],
    TipoOperacion: [],
    AnomaliaInspeccion: [],
    Resultado: [],
    TipoSituacion: [],
    NivelGravedad: [],
  });

  const {
    generico: { dbLocal, websocket },
    auth: { user, preferencia, permisoVariables },
  } = useSelector((state: RootState) => state);

  const [data, setData] = useState<DtoResponseForm>({} as DtoResponseForm);
  const [DetalleInspeccion, setDetalleInspeccion] = useState<EntityDetalleInspeccion[]>([]);
  const [mode, setMode] = useState({ text: "", key: "" });
  const [keyTab, setKeyTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [generateId, setGenerateId] = useState("");
  const [isOPS, setOPS] = useState(false);

  const dispatch: Dispatch = useDispatch();
  const params = useParams();

  const isModePreview = ["preview"].includes(mode.key);
  const isBlockInputs = ["survey", "preview"].includes(mode.key);
  const isModeSurvey = ["survey"].includes(mode.key);

  const repository: RepositoryImplMain = new RepositoryImplMain(
    websocket,
    dbLocal,
    dispatch,
    AdapterConfigure.SCHEMA,
    AdapterConfigure.ENTITY
  );

  const {
    formRegistroItem,
    onChangeItem,
    setInitialValuesFormItem,
    onChangeModalFormItem,
    showModalFormItem,
    actionsFormItem,
    aplica,
    updateAplica,
  } = ControllerFormItem({
    repository,
    DetalleInspeccion,
    setDetalleInspeccion,
    AnomaliaInspeccion: selectInformation.AnomaliaInspeccion,
    NivelGravedad: selectInformation.NivelGravedad,
    isModeSurvey,
    user: user
  });

  const { formRegistro, onChange, save, autoCompleteOnSelectOT } = ControllerForm({
    keyTab,
    DetalleInspeccion,
    repository,
    user,
    aplica,
    dbLocal,
    isModeSurvey,
    generateId,
    isOPS,
    Personal: selectInformation.Personal,
    Area: selectInformation.Area,
    Empresa: selectInformation.Empresa,
    selectInformation
  });

  useEffect(() => {
    if (mode.key === "create" && keyTab === 4) {
      repository.autoSave(
        {
          ...formRegistro.values,
          _id: generateId,
          DatosInspeccion: { DetalleInspeccion },
        },
        keyTab,
        isOPS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DetalleInspeccion]);

  const init = async () => {
    try {
      dispatch(changeSaludo(false));
      setLoading(true);
      params.id ? modeWithIdParams() : modeCreate();
    } catch (error) {
      window.location.reload();
    }
  };

  const modeCreate = async () => {
    setMode({ text: languageTranslate.textoModoCrear, key: "create" });
    await new AdapterLoadMaster(websocket, dbLocal, dispatch, '', '').verifyLoadMasterFromInspecciones({
      delegacion: permisoVariables.arrIdDelegaciones,
      grupo: permisoVariables.arrIdGrupos,
      ot: permisoVariables.arrIdOT,
      pais: permisoVariables.arrIdPaises
    });

    const loadData = await new UseCaseLoadData(repository).exec(user, permisoVariables);

    if([CODE_TIPO_PERFIL_DIRECTOR, CODE_TIPO_PERFIL_OBRA].includes(user.TipoPerfil.Code)) {
      loadData.InspeccionConjunta = loadData.InspeccionConjunta.filter(row => ![CODE_TIPO_PERFIL_DIRECTOR, CODE_TIPO_PERFIL_OBRA].includes(row.dataComplete.CodeTipoPerfil));
    }

    setSelectInformation(loadData);
    setGenerateId(repository.generateId());
    initialValuesModeCreate(loadData);
    setLoading(false);
  };

  const modePreview = async (inspeccionFinded: DtoResponseForm) => {
    setMode({ text: languageTranslate.textoModoVisualizar, key: "preview" });
    const loadData = await new UseCaseLoadDataPreview(repository).exec();
    setSelectInformation(loadData);

    const { data, DetalleInspeccion } =
      repository.formatterReadData(inspeccionFinded);
    formRegistro.setValues(data);
    setDetalleInspeccion(() => [...DetalleInspeccion]);
    setData(inspeccionFinded);
    setLoading(false);
  };

  const modeSurvey = async (inspeccionFinded: DtoResponseForm) => {
    setMode({ text: languageTranslate.textoModoLevantamiento, key: "survey" });
    setKeyTab(4);
    const loadData = await new UseCaseLoadDataSurvey(repository).exec();
    setSelectInformation(loadData);

    const { data, DetalleInspeccion } =
      repository.formatterReadData(inspeccionFinded);
    formRegistro.setValues(data);
    setDetalleInspeccion(() => [...DetalleInspeccion]);
    setData(inspeccionFinded);
    setLoading(false);
  };

  const modeIncomplete = async (inspeccionFinded: DtoResponseForm) => {
    setMode({ text: languageTranslate.textoModoCrear, key: "create" });
    await new AdapterLoadMaster(websocket, dbLocal, dispatch, '', '').verifyLoadMasterFromInspecciones({
      delegacion: permisoVariables.arrIdDelegaciones,
      grupo: permisoVariables.arrIdGrupos,
      ot: permisoVariables.arrIdOT,
      pais: permisoVariables.arrIdPaises
    });
    const loadData = await new UseCaseLoadData(repository).exec(user, permisoVariables);

    if(user.TipoPerfil.Code === CODE_TIPO_PERFIL_OBRA) {
      loadData.InspeccionConjunta = loadData.InspeccionConjunta.filter(row => row.dataComplete.CodeTipoPerfil !== CODE_TIPO_PERFIL_OBRA);
    }

    setSelectInformation(loadData);
    setGenerateId(inspeccionFinded._id);
    const _tempIncomplete = (inspeccionFinded as any).dataIncomplete;

    if (_tempIncomplete?.DatosInspeccion?.DetalleInspeccion) {
      _tempIncomplete.DatosInspeccion.DetalleInspeccion = (_tempIncomplete.DatosInspeccion.DetalleInspeccion || []).map((row: any) => {
        row.Anomalia.Fotos.map((_itemFoto: any) => {
          const urlFoto = (window.URL ? URL : webkitURL).createObjectURL(_itemFoto.value.file);
          _itemFoto.valueToShow = urlFoto;
          return _itemFoto;
        })

        row.Levantamiento.Fotos.map((_itemFoto: any) => {
          const urlFoto = (window.URL ? URL : webkitURL).createObjectURL(_itemFoto.value.file);
          _itemFoto.valueToShow = urlFoto;
          return _itemFoto;
        })

        return row;
      })
    }

    formRegistro.setValues({ ..._tempIncomplete });
    setDetalleInspeccion(() => [
      ...(inspeccionFinded.DatosInspeccion.DetalleInspeccion || []),
    ]);
    updateAplica(inspeccionFinded.DatosInspeccion.DetalleInspeccion || []);
    setLoading(false);
  };

  const initialValuesModeCreate = async (data: EntityLoadData) => {
    if (preferencia.ot) {
      let ot: EntitySelectOTs = preferencia.ot as any;
      autoCompleteOnSelectOT(ot, data);
    }

    if (preferencia.empresa) {
      formRegistro.setFieldValue('Empresa', preferencia.empresa);
    }

    let tipoOperacionFinded: EntitySelectBase<EntityTipoOperacion> | null = null;
    if (data.TipoOperacion.length === 1) {
      tipoOperacionFinded = data.TipoOperacion[0];
    } else {
      const ID_TIPO_OPERACION_DEFAULT = 1;
      tipoOperacionFinded = data.TipoOperacion.find(row => row.value === ID_TIPO_OPERACION_DEFAULT) || null;
    }
    
    let _formato = data.Formato.filter((row) => row.dataComplete.TipoOperacion.IdTipoOperacion === tipoOperacionFinded?.value)

    onChange('TipoOperacion', tipoOperacionFinded);
    if (_formato.length === 1)
      onChange('Formato', _formato[0]);
    else if (_formato.length > 0) {
      const _formatoFinded = _formato.find(row => AdapterGenerico.searchText("cobra", row.dataComplete.FormatoInspeccion)) || null;
      onChange('Formato', _formatoFinded);
    } else {}

    const FechaInspeccion = AdapterGenerico.getFechaISO_Local().substring(0, 19);
    const IdFecha = AdapterGenerico.ObtenerIdFechaISO(FechaInspeccion);

    const _fechaInspeccion = {
      IdFecha: IdFecha,
      FechaInspeccion: FechaInspeccion,
      TimeZone: AdapterGenerico.getTimeZoneOffSet(),
    }

    onChange("FechaInspeccion", _fechaInspeccion);
    // onChange("Lugar", (await AdapterGeolocation.getAllGeolocation()).address);
  };

  const modeWithIdParams = async () => {
    let inspeccionFinded: DtoResponseForm | null =
      await dbLocal.selectByIndexStore({
        nameStore: "Inspeccion",
        value: params.id,
      });
    if (!inspeccionFinded) return;

    const IdInspeccionOK = 5;
    const IdInspeccionAnomaly = 6;
    const IdSinConexion = -1;
    const IdIncompleta = -2;

    if (inspeccionFinded.Estado.IdEstado === IdSinConexion)
      inspeccionFinded = inspeccionFinded.dataSend.INSP as DtoResponseForm;

    switch (inspeccionFinded.Estado.IdEstado) {
      case IdInspeccionOK:
        await modePreview(inspeccionFinded);
        break;
      case IdInspeccionAnomaly:
        await modeSurvey(inspeccionFinded);
        break;
      case IdIncompleta:
        await modeIncomplete(inspeccionFinded);
        break;
    }
  };

  const onChangeContentForTab = async (newKeyTab: number) => {
    if (newKeyTab === keyTab) return;
    if (newKeyTab < keyTab) return setKeyTab(newKeyTab);
    if (isModeSurvey || isModePreview) return setKeyTab(newKeyTab);

    try {
      try {
        await formRegistro.submitForm();
      } catch (error) {}
      try {
        AdapterValidator.validate(await formRegistro.validateForm());
      } catch (error) {
        AdapterGenerico.createMessage(
          languageTranslate.textoIncompleto,
          (error as Error).message,
          "warning",
          false
        );
        return null;
      }
      const tempKeyTab = newKeyTab - 1 === keyTab ? newKeyTab : keyTab + 1;
      await repository.autoSave(
        { ...formRegistro.values, _id: generateId },
        keyTab,
        isOPS
      );
      AdapterGenerico.scrollTopByClassName("ViewInspeccionForm");
      setKeyTab(tempKeyTab);
    } catch (error) {
      AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        (error as Error).message,
        "warning",
        false
      );
    }
  };

  const verifiedIsOPS = () => {
    const regexOPS = new RegExp(
      process.env.REACT_APP_ROUTE_PATH_MAIN_OPS + "",
      "g"
    );
    if (window.location.pathname.match(regexOPS)) setOPS(true);
  };

  return {
    init,
    onChangeContentForTab,
    verifiedIsOPS,
    isOPS,
    keyTab,
    mode,
    loading,

    formRegistro,
    onChange,
    save,
    formRegistroItem,
    onChangeItem,
    actionsFormItem,
    aplica,

    setInitialValuesFormItem,
    onChangeModalFormItem,
    showModalFormItem,

    isBlockInputs,
    isModePreview,
    isModeSurvey,
    data,
    selectInformation,
    DetalleInspeccion,
  };
};

const ControllerForm = (props: {
  keyTab: number;
  DetalleInspeccion: EntityDetalleInspeccion[];
  repository: RepositoryImplMain;
  user: EntityDataUsuario;
  aplica: EntityAplica[];
  dbLocal: AdapterIndexedDB;
  isModeSurvey: boolean;
  generateId: string;
  isOPS: boolean;
  Personal: EntitySelectPersonal[];
  Area: EntitySelectArea[];
  Empresa: EntitySelectEmpresa[];
  selectInformation: EntityLoadData;
}) => {
  const { preferencia } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const params = useParams();

  const formRegistro = useFormik({
    initialValues: {
      Personal: [],
      ParalizacionTrabajo: false,
      ConjuntaTipo: false,
      ReconocimientoOPS: false,
      ObservacionOPS: "",
    } as any,
    validationSchema: Yup.object({
      // tab1
      OT: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro.ot
          )
          .nullable(),
      }),
      Area: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro.area
          )
          .nullable(),
      }),
      Empresa: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .empresa
          )
          .nullable(),
      }),
      Actividad: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .actividad
          )
          .nullable(),
      }),
      Cliente: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .cliente
          )
          .nullable(),
      }),
      JefeObra: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .jefeObra
          )
          .nullable(),
      }),
      Coordinador: Yup.object().when([], {
        is: () => props.keyTab === 1,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .coordinador
          )
          .nullable(),
      }),
      Encargado: Yup.object().nullable(),

      // tab2
      ConjuntaTipo: Yup.bool(),
      ConjuntaPersonal: Yup.object().when(["ConjuntaTipo"], {
        is: (ConjuntaTipo: boolean) => ConjuntaTipo && props.keyTab === 2,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .conjuntaPersonal
          )
          .nullable(),
      }).nullable(),
      SubContrata: Yup.object().nullable(),
      TipoOperacion: Yup.object().when([], {
        is: () => props.keyTab === 2,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .tipoOperacion
          )
          .nullable(),
      }).nullable(),
      Formato: Yup.object().when([], {
        is: () => props.keyTab === 2,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .formato
          )
          .nullable(),
      }).nullable(),
      ParalizacionTrabajo: Yup.boolean(),
      TipoMomentoTrabajo: Yup.object().when([], {
        is: () => props.keyTab === 2,
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .tipoMomentoTrabajo
          )
          .nullable(),
      }).nullable(),
      FueraHorario: Yup.boolean(),
      Lugar: Yup.string().when([], {
        is: () => props.keyTab === 2,
        then: Yup.string()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro.lugar
          )
          .nullable(),
      }),
      Obra: Yup.string().when([], {
        is: () => props.keyTab === 2,
        then: Yup.string()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro.obra
          )
          .nullable(),
      }),
      TrabajoRealizar: Yup.string().when([], {
        is: () => props.keyTab === 2,
        then: Yup.string()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .trabajoRealizar
          )
          .nullable(),
      }),
      Placa: Yup.string(),
      ReconocimientoOPS: Yup.boolean(),
      ObservacionOPS: Yup.string().when(["ReconocimientoOPS"], {
        is: (ReconocimientoOPS: boolean) =>
          ReconocimientoOPS && props.keyTab === 2,
        then: Yup.string()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .observacionOPS
          )
          .nullable(),
      }),

      // tab3
      Personal: Yup.array(),
      PersonalResponsableTrabajo: Yup.object()
        .when(["Personal"], {
          is: (Personal: any[] = []) =>
            props.keyTab === 3 && Personal.length > 0,
          then: Yup.object()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistro
                .personalResponsableTrabajo
            )
            .nullable(),
        })
        .nullable(),

      // tab4

      DetalleInspeccion: Yup.array()
        .when([], {
          is: () => props.keyTab === 4 && props.DetalleInspeccion.length === 0,
          then: Yup.array()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistro
                .detalleInspeccionVacia
            )
            .nullable(),
        })
        .when([], {
          is: () =>
            props.keyTab === 4 &&
            props.DetalleInspeccion.filter((dtl) => dtl.Incompleta).length > 0,
          then: Yup.array()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistro
                .detalleInspeccionIncompleta
            )
            .nullable(),
        })
        .nullable(),
      ResponsableLevantamiento: Yup.object().when([], {
        is: () =>
          props.keyTab === 4 &&
          props.DetalleInspeccion.some(
            (row) => row.Anomalia.ResultadoAnomalia === 1
          ),
        then: Yup.object()
          .required(
            languageTranslate.moduloInspeccion.form.validate.formRegistro
              .responsableLevantamiento
          )
          .nullable(),
      }),
    }),
    onSubmit: (values, formikHelpers) => {},
  });

  const onChange = (name: string, value: any) => {
    //if (value === null) { return; }

    if (
      [
        "OT",
        "PersonalUnidad",
        "DeletePersonal",
        "TipoInspeccionSelect",
        "TipoOperacion",
        "ConjuntaTipo",
      ].includes(name)
    )
      extraActionsOnChange(name, value);
    else formRegistro.setFieldValue(name, value);
    if ("TipoInspeccionSelect" === name) TipoInspeccion = Array.isArray(value) ? value : [value];
  };

  const extraActionsOnChange = (name: string, value: any) => {
    switch (name) {
      case "OT":
        autoCompleteOnSelectOT(value);
        break;
      case "PersonalUnidad":
        addPersonalOnTable(value);
        break;
      case "DeletePersonal":
        deletePersonalOnTable(value);
        break;
      case "TipoInspeccionSelect":
        addOrRemoveTipoInspeccion(value);
        break;
      case "TipoOperacion":
        changeOperationType(value);
        break;
      case "ConjuntaTipo":
        changeConjuntaTipo(value);
        break;
    }
  };

  const autoCompleteOnSelectOT = (value: EntitySelectOTs, selectInformation?: EntityLoadData) => {
    const dataAutoComplete = {
      ...formRegistro.values,
      Pais: AdapterGenerico.formatoPersonalizadoSelect(
        value.dataComplete.Pais,
        "IdPais",
        "Pais"
      ),
      Delegacion: AdapterGenerico.formatoPersonalizadoSelect(
        value.dataComplete.Delegacion,
        "Codigo",
        "Delegacion"
      ),
      Cliente: null,
      Proyecto: AdapterGenerico.formatoPersonalizadoSelect(
        value.dataComplete.Proyecto,
        "IdProyecto",
        "Proyecto"
      ),
      Actividad: null,
      JefeObra: null,
      Coordinador: null,
      Encargado: null,
      Grupo: AdapterGenerico.formatoPersonalizadoSelect(
        value.dataComplete.Grupo,
        "IdGrupo",
        "Grupo"
      ),
      OT: value,
    };
    
    const optionsSelects = selectInformation || props.selectInformation;

    if (![0, undefined, null].includes(value.dataComplete.Cliente.IdCliente)) {
      const itemFinded = optionsSelects.Cliente.find(row => row.dataComplete.IdEmpresa === value.dataComplete.Cliente.IdCliente)
      dataAutoComplete.Cliente = itemFinded || null;
    }

    if (![0, undefined, null].includes(value.dataComplete.Actividad.IdActividad)) {
      const itemFinded = optionsSelects.Actividad.find(row => row.dataComplete.IdActividad === value.dataComplete.Actividad.IdActividad);
      dataAutoComplete.Actividad = itemFinded || null;
    }

    if (!["", undefined, null].includes(value.dataComplete.JefeObra.Identificacion)) {
      const itemFinded = optionsSelects.Personal.find(row => row.dataComplete.IdPersonal === value.dataComplete.JefeObra.IdPersonal);
      dataAutoComplete.JefeObra = itemFinded
    }

    if (!["", undefined, null].includes(value.dataComplete.Coordinador.Identificacion)) {
      const itemFinded = optionsSelects.Personal.find(row => row.dataComplete.IdPersonal === value.dataComplete.Coordinador.IdPersonal)
      dataAutoComplete.Coordinador = itemFinded;
    }

    if (!["", undefined, null].includes(value.dataComplete.Capataz.Identificacion)) {
      const itemFinded = optionsSelects.Personal.find(row => row.dataComplete.IdPersonal === value.dataComplete.Capataz.IdPersonal)
      dataAutoComplete.Encargado = itemFinded;
    }

    if ((dataAutoComplete.Pais).dataComplete.IdPais !== (formRegistro.values["Pais"])?.dataComplete.IdPais)
      Object.assign(dataAutoComplete, { Area: null, Empresa: null });

    let areaSelect = optionsSelects.Area.filter(
      (area) =>
        area.dataComplete.Pais.IdPais ===
          (dataAutoComplete.Pais as EntitySelectPais)?.dataComplete?.IdPais &&
        area.dataComplete.Delegacion.Codigo ===
          (dataAutoComplete.Delegacion as EntitySelectDelegacion)?.value
    );

    if (areaSelect.length === 1) {
      // dataAutoComplete.Area = areaSelect[0];
      Object.assign(dataAutoComplete, { Area: areaSelect[0] });
    }

    let empresaSelect = optionsSelects.Empresa.filter(
      (emp) =>
        emp.dataComplete.Pais.IdPais === (dataAutoComplete.Pais)?.dataComplete?.IdPais
    );

    if (empresaSelect.length === 1) {
      Object.assign(dataAutoComplete, { Empresa: empresaSelect[0] });
    }

    formRegistro.setValues(dataAutoComplete);
  };

  const addPersonalOnTable = async (value: EntitySelectPersonal[]) => {
    const Personal: EntitySelectPersonal[] =
      (formRegistro.values as any).Personal || [];

    const dataAutoCompletePersonal = {
      ...formRegistro.values,
      Personal: value,
      PersonalUnidad: {},
    };
    if (dataAutoCompletePersonal.Personal.length === 1)
      Object.assign(dataAutoCompletePersonal, {
        PersonalResponsableTrabajo: value[0],
      });
    if (
      dataAutoCompletePersonal.Personal.length === 1 &&
      Personal.length === 0 &&
      preferencia.buscarCuadrilla &&
      new AdapterCheckConnection().isStable()
    ) {
      try {
        // Función para buscar cuadrilla
        dispatch(
          addLoading({
            textLoading:
              languageTranslate.moduloInspeccion.form
                .textoBuscarCuadrillaPersonal,
          })
        );
        const response = await new useCaseSearchCuadrillaPersonal(
          props.repository
        ).exec(value[0].dataComplete.Identificacion);
        if (response && response.length > 1) {
          const personalCuadrilla = props.Personal.filter((item) =>
            response.includes(item.dataComplete.Identificacion)
          );
          Object.assign(dataAutoCompletePersonal, {
            Personal: personalCuadrilla,
          });
        }
      } catch (error) {
        AdapterGenerico.createMessage(
          languageTranslate.textoAlerta,
          (error as Error).message,
          "warning",
          false
        );
      } finally {
        dispatch(removeLoading());
      }
    }
    formRegistro.setValues(dataAutoCompletePersonal);
  };

  const deletePersonalOnTable = (value: any) => {
    const Personal: any[] = (formRegistro.values as any).Personal;
    const PersonalResponsableTrabajo = (formRegistro.values as any)
      .PersonalResponsableTrabajo;
    const PositionDelete = Personal.findIndex(
      (psl) => psl.value === value.value
    );
    Personal.splice(PositionDelete, 1);

    const dataAutoCompleteDeletePersonal = {
      ...formRegistro.values,
      Personal: Personal,
    };
    if (
      PersonalResponsableTrabajo &&
      PersonalResponsableTrabajo.value === value.value
    )
      Object.assign(dataAutoCompleteDeletePersonal, {
        PersonalResponsableTrabajo: null,
      });

    formRegistro.setValues(dataAutoCompleteDeletePersonal);
  };

  const addOrRemoveTipoInspeccion = (value: any) => {
    const AllTipoInspeccion: EntitySelectTipoInspeccion[] =
      (formRegistro.values as any).TipoInspeccion || [];
    const indexFinded = AllTipoInspeccion.findIndex(
      (tpi) => tpi.value === value.value
    );
    indexFinded === -1
      ? AllTipoInspeccion.push(value)
      : AllTipoInspeccion.splice(indexFinded, 1);
    const autoCompleteTipoInspeccion = {
      ...formRegistro.values,
      TipoInspeccion: AllTipoInspeccion,
    };
    formRegistro.setValues(autoCompleteTipoInspeccion);
  };

  const changeOperationType = (value: any) => {
    formRegistro.setFieldValue('TipoOperacion', value);
    formRegistro.setFieldValue('Formato', null);
  };

  const changeConjuntaTipo = (value: any) => {
    if (!!value) formRegistro.setFieldValue('InspeccionConSubContrata', false);
    formRegistro.setFieldValue('ConjuntaTipo', value);
  }

  const save = async () => {
    try {
      try {
        await formRegistro.submitForm();
      } catch (error) {}
      try {
        AdapterValidator.validate(await formRegistro.validateForm());
      } catch (error) {
        AdapterGenerico.createMessage(
          languageTranslate.textoIncompleto,
          (error as Error).message,
          "warning",
          false
        );
        return null;
      }
      const { repository, isModeSurvey, user, aplica, DetalleInspeccion } =
        props;

      let countMaxPhotos = 10;
      let countPhotosUsed = 0;
      DetalleInspeccion.forEach((row) => {
        countMaxPhotos += 3;
        countPhotosUsed += row.Anomalia.Fotos.length;

        if (row.Levantamiento.Descripcion) {
          countPhotosUsed += row.Levantamiento.Fotos.length;
          countMaxPhotos += 3;
        }
      });

      if (countPhotosUsed > countMaxPhotos)
        throw Error(
          languageTranslate.moduloInspeccion.form.validate.formRegistro.cantidadImagenes(
            countMaxPhotos
          )
        );

      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      let data = isModeSurvey
        ? await repository.formatterSaveDataSurvey(
            params.id as string,
            DetalleInspeccion,
            user
          )
        : await repository.formatterSaveData(
            formRegistro.values,
            props.isOPS,
            user,
            aplica,
            DetalleInspeccion,
            props.generateId
          );

      if (props.generateId)
        Object.assign(data, { INSP: { ...data.INSP, _id: props.generateId } });

      if (props.isModeSurvey)
        await new useCaseSaveSurvey(props.repository).exec(data);
      else await new useCaseSaveInspeccion(props.repository).exec(data);

      navigate(
        props.isOPS
          ? AdapterConfigure.REDIRECT_LIST_OPS
          : AdapterConfigure.REDIRECT_LIST
      );
    } catch (error) {
      AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        (error as Error).message,
        "warning",
        false
      );
    } finally {
      dispatch(removeLoading());
    }
  };

  return { formRegistro, onChange, save, autoCompleteOnSelectOT };
};

const ControllerFormItem = (props: {
  DetalleInspeccion: EntityDetalleInspeccion[];
  repository: RepositoryImplMain;
  setDetalleInspeccion: React.Dispatch<
    React.SetStateAction<EntityDetalleInspeccion[]>
  >;
  AnomaliaInspeccion: EntitySelectAnomaliaInspeccion[];
  isModeSurvey: boolean;
  NivelGravedad: EntitySelectNivelGravedad[];
  user: EntityDataUsuario;
}) => {
  const [showModalFormItem, setShowModalFormItem] = useState(false);
  const [aplica, setAplica] = useState<EntityAplica[]>([]);
  const [initialValuesFormItem, setInitialValuesFormItem] = useState<any>({
    Resultado: { value: 0, label: languageTranslate.moduloInspeccion.form.textoCorrecto },
    NivelGravedad: null,
    LevantamientoInmediato: false,
    ParalizacionTrabajoItem: false,
    Grave: false,
    Personal: [],
    FotoInspeccion: [],
    FotoLevantamiento: [],
  });

  const formRegistroItem = useFormik({
    initialValues: initialValuesFormItem,
    validationSchema: Yup.object({
      TipoInspeccion: Yup.object()
        .required(
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem
            .tipoInspeccion
        )
        .nullable(),
      TipoItem: Yup.object()
        .required(
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem
            .tipoItem
        )
        .nullable(),
      Item: Yup.object()
        .required(
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem.item
        )
        .nullable(),
      /*Detalle: Yup.object()
        .when(["Item", "TipoInspeccion", "Resultado"], {
          is: (
            Item: any,
            TipoInspeccion: any,
            Resultado: { value: number }
          ) => {
            const IdTipoIspeccionSelected =
              TipoInspeccion?.dataComplete.IdTipoInspeccion || 0;
            const TipoInspeccionFinded =
              Item?.dataComplete.TipoInspeccion?.find(
                (item: any) =>
                  item.id_TipoInspeccion === IdTipoIspeccionSelected
              );
            return Resultado.value === 1 && TipoInspeccionFinded
              ? TipoInspeccionFinded?.DetalleAnomalia.length !== 0
              : false;
          },
          then: Yup.object()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .detalle
            )
            .nullable(),
        })
        .nullable(),*/
      Personal: Yup.array()
        .required(
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem
            .personal
        )
        .nullable(),
      Resultado: Yup.object()
        .required(
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem
            .resultado
        )
        .nullable(),
      NivelGravedad: Yup.object()
        .when(["Resultado"], {
          is: (Resultado?: EntitySelectResultado) => {
            return (Resultado && `${Resultado?.value}` === '1')
          },
          then: Yup.object()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .nivelGravedad
            )
            .nullable(),
          })
        .nullable(),
      Grave: Yup.boolean().nullable(),
      ParalizacionTrabajoItem: Yup.boolean().nullable(),
      Descripcion: Yup.string()
        .when(["Resultado"], {
          is: (Resultado: { value: number }) =>
            Resultado.value === 1 && !props.isModeSurvey,
          then: Yup.string()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .descripcion
            )
            .nullable(),
        })
        .nullable(),
      AccionPropuesta: Yup.string()
        .when(["Resultado", "LevantamientoInmediato"], {
          is: (Resultado: { value: number }, LevantamientoInmediato?: boolean) =>
            (Resultado.value === 1 && !props.isModeSurvey && !LevantamientoInmediato),
          then: Yup.string()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .accionPropuesta
            )
            .nullable(),
        })
        .nullable(),
      TipoSituacion: Yup.object()
        .when(["Resultado"], {
          is: (Resultado: { value: number }) =>
            Resultado.value === 1 && !props.isModeSurvey,
          then: Yup.object()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .tipoSituacion
            )
            .nullable(),
        })
        .nullable(),
      LevantamientoInmediato: Yup.boolean().nullable(),
      DescripcionLevantamiento: Yup.string()
        .when(["LevantamientoInmediato"], {
          is: (LevantamientoInmediato: boolean) => LevantamientoInmediato,
          then: Yup.string()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .descripcionLevantamiento
            )
            .nullable(),
        })
        .nullable(),
      FechaLevantamiento: Yup.string()
        .when(["LevantamientoInmediato"], {
          is: (LevantamientoInmediato: boolean) => LevantamientoInmediato,
          then: Yup.string()
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .fechaLevantamiento
            )
            .nullable(),
        })
        .nullable(),
      FotoLevantamiento: Yup.array()
        .when(["LevantamientoInmediato"], {
          is: (LevantamientoInmediato: boolean) => LevantamientoInmediato,
          then: Yup.array()
            .min(
              1,
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .fotoLevantamiento
            )
            .required(
              languageTranslate.moduloInspeccion.form.validate.formRegistroItem
                .fotoLevantamiento
            )
            .nullable(),
        })
        .nullable(),
      FechaPrevista: Yup.string()
        .when(["LevantamientoInmediato", "Resultado"], {
          is: (LevantamientoInmediato: boolean, resultado?: any) => (!LevantamientoInmediato && resultado?.value === 1),
          then: Yup.string().required(languageTranslate.moduloInspeccion.form.validate.formRegistroItem.fechaPrevista).nullable()
        }).nullable()
    }),
    enableReinitialize: true,
    onSubmit: (values, formikHelpers) => {},
  });

  const onChangeItem = (name: string, value: any) => {
    if (formRegistroItem.values[name]?.value === (value?.value || value))
      return;

    if (
      [
        "TipoItem",
        "Item",
        "TipoInspeccion",
        "FotoInspeccion",
        "FotoLevantamiento",
        "EliminarImage",
        "PreviewListImageInspeccion",
        "Detalle",
        "NivelGravedad",
        "LevantamientoInmediato"
      ].includes(name)
    )
      extraActionsOnChangeItem(name, value);
    else formRegistroItem.setFieldValue(name, value);
  };

  const extraActionsOnChangeItem = (name: string, value: any) => {
    if (name === "TipoItem") {
      formRegistroItem.setFieldValue("Item", null);
      formRegistroItem.setFieldValue("Detalle", null);
      formRegistroItem.setFieldValue(name, value);
    }
    if (["Item", "TipoInspeccion"].includes(name)) {
      formRegistroItem.setFieldValue("Detalle", null);
      formRegistroItem.setFieldValue(name, value);
      if (name === "Item")
        if (value.dataComplete?.NivelCritico) {
          const valueGravedad = `0${value.dataComplete?.NivelCritico}`;
          formRegistroItem.setFieldValue(
            "NivelGravedad",
            props.NivelGravedad.find(row => row.value === valueGravedad)
          );

          formRegistroItem.setFieldValue('Grave', value.value === '03');
        }
    }
    if (["FotoInspeccion", "FotoLevantamiento"].includes(name)) {
      const files = value as FileList;
      let temp: {
        type: string;
        valueToShow: any;
        value: { path: string; file: File };
      }[] = [];
      Array.from(files).forEach((file, index) => {
        temp.push({
          type: "Local",
          valueToShow: (window.URL ? URL : webkitURL).createObjectURL(file),
          value: {
            path: `INSP_${AdapterGenerico.formatoParaGuardarImagenes()}${index}_${
              props.user._id
            }.${file.name.split('.').pop() || ''}`,
            file: file,
          },
        });
      });

      formRegistroItem.setFieldValue(name, [
        ...formRegistroItem.values[name],
        ...temp,
      ]);
    }
    if (name === "PreviewListImageInspeccion") {
      formRegistroItem.setFieldValue("FotoInspeccion", value);
    }
    if (name === "EliminarImage") {
      const { DetalleInspeccion, setDetalleInspeccion } = props;
      const positionFinded = DetalleInspeccion.findIndex(
        (dtl) => dtl.IdAnomalia === formRegistroItem.values.IdAnomalia
      );
      if (positionFinded === -1) return;

      const { type, indexImage, deleteFromList } = value;
      let fotos: any[] = [];

      if (type === "FotoInspeccion" && deleteFromList) {
        DetalleInspeccion[positionFinded].Anomalia.Fotos.splice(indexImage, 1);
        formRegistroItem.setFieldValue(
          type,
          DetalleInspeccion[positionFinded].Anomalia.Fotos
        );
        setDetalleInspeccion(() => [...DetalleInspeccion]);
        return;
      }
      if (type === "FotoInspeccion")
        fotos = fotos.concat(formRegistroItem.values.FotoInspeccion);
      if (type === "FotoLevantamiento")
        fotos = fotos.concat(formRegistroItem.values.FotoLevantamiento);

      fotos.splice(indexImage, 1);
      formRegistroItem.setFieldValue(type, fotos);
    }
    if (name === 'Detalle') {
      if (value.dataComplete?.NivelCritico) {
        const valueGravedad = `0${value.dataComplete?.NivelCritico}`;
        formRegistroItem.setFieldValue("NivelGravedad",  props.NivelGravedad.find(row => row.value === valueGravedad));
        formRegistroItem.setFieldValue('Grave', valueGravedad === '03');
      }

      formRegistroItem.setFieldValue(name, value);
    }
    if (name === 'NivelGravedad') {
      // Si es nivel tipo 3, se marcará automáticamente Grave como true, caso contrario false
      formRegistroItem.setFieldValue('Grave', value.value === '03');
      formRegistroItem.setFieldValue(name, value);
    }
    if (name === 'LevantamientoInmediato') {
      formRegistroItem.setFieldValue('FechaLevantamiento', AdapterGenerico.convertDateToString(new Date(), 6));
      formRegistroItem.setFieldValue(name, value);
    }
  };

  const reloadForm = async () => {
    formRegistroItem.resetForm({
      values: {
        TipoInspeccion: null,
        TipoItem: null,
        Item: null,
        Detalle: null,
        Descripcion: "",
        AccionPropuesta: "",
        DescripcionLevantamiento: "",
        FechaLevantamiento: null,
        FotoInspeccion: [],
        FotoLevantamiento: [],
        FechaPrevista: null,
        ...initialValuesFormItem,
      },
    });
    formRegistroItem.setErrors({});
    await formRegistroItem.setTouched({});
  };

  const saveItem = async (typeSave: number) => {
    const TypeSave = 1;
    const TypeSaveAndContinue = 2;
    try {
      try {
        await formRegistroItem.submitForm();
      } catch (error) {}

      try {
        AdapterValidator.validate(await formRegistroItem.validateForm());
      } catch (error) {
        AdapterGenerico.createMessage(
          languageTranslate.textoIncompleto,
          (error as Error).message,
          "warning",
          false
        );
        return null;
      }

      let dataDetalle =
        formRegistroItem.values.Item.dataComplete.TipoInspeccion.find(
          (row: any) =>
            row.id_TipoInspeccion ===
            formRegistroItem.values.TipoInspeccion.dataComplete.IdTipoInspeccion
        )?.DetalleAnomalia || [];

      const { DetalleInspeccion, repository, setDetalleInspeccion } = props;
      const data = repository.formatterSaveItem({
        values: formRegistroItem.values,
      });

      /* Ahora se podrá duplicar n ítems con el mismo detalle
      const itemDuplicateFinded =
        dataDetalle.length > 0
          ? DetalleInspeccion.find(
              (item) =>
                item.Anomalia.Codigo === data.Anomalia.Codigo &&
                item.Anomalia.TipoAnomalia.Codigo === data.Anomalia.TipoAnomalia.Codigo &&
                item.TipoInspeccion.IdTipoInspeccion === data.TipoInspeccion.IdTipoInspeccion &&
                item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
                item.IdAnomalia !== data.IdAnomalia
            )
          : DetalleInspeccion.find(
              (item) =>
                item.Anomalia.Codigo === data.Anomalia.Codigo &&
                item.Anomalia.TipoAnomalia.Codigo === data.Anomalia.TipoAnomalia.Codigo &&
                // item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
                item.IdAnomalia !== data.IdAnomalia &&
                item.Sancionado === "NO"
            );

      if (itemDuplicateFinded)
        return AdapterGenerico.createMessage(
          languageTranslate.textoAlerta,
          languageTranslate.moduloInspeccion.form.validate.detalleItemDuplicado,
          "warning",
          false
        );
      */

      const countPhotosUsed =
        data.Anomalia.Fotos.length + data.Levantamiento.Fotos.length;
      if (countPhotosUsed > 6) {
        const question = await AdapterGenerico.createMessage(
          "",
          languageTranslate.moduloInspeccion.form.validate.formRegistroItem
            .cantidadImagenes,
          "question",
          true
        );
        if (!question) return;
      }

      if (data.Sancionado === "NO") {
        let itemExistsCorrecto =
          dataDetalle.length > 0
            ? false
            : DetalleInspeccion.find(
                (item) =>
                  item.Anomalia.Codigo === data.Anomalia.Codigo &&
                  item.Anomalia.TipoAnomalia.Codigo ===
                    data.Anomalia.TipoAnomalia.Codigo &&
                  // item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
                  item.IdAnomalia !== data.IdAnomalia
              );

        if (itemExistsCorrecto)
          return AdapterGenerico.createMessage(
            languageTranslate.textoAlerta,
            languageTranslate.moduloInspeccion.form.validate
              .detalleItemNoPermitido,
            "warning",
            false
          );
      }

      const indexUpdate = DetalleInspeccion.findIndex(
        (dti) => dti.IdAnomalia === data.IdAnomalia
      );
      indexUpdate === -1
        ? DetalleInspeccion.push(data)
        : (DetalleInspeccion[indexUpdate] = data);
      setDetalleInspeccion((prev) => [...DetalleInspeccion]);

      updateAplica(DetalleInspeccion);
      await reloadForm();

      if (TypeSave === typeSave) onChangeModalFormItem(false);
      if (TypeSaveAndContinue === typeSave) {
        const nextItem = DetalleInspeccion.find((row) => row.Incompleta);
        nextItem && nextItem.IdAnomalia
          ? openModalEditModeFormItem(nextItem.IdAnomalia)
          : onChangeModalFormItem(true);
        AdapterGenerico.scrollTopByClassName("inicioFormularioItem");
      }
    } catch (error) {
      console.error(error);
      AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        (error as Error).message,
        "warning",
        false
      );
    } finally {
    }
  };

  const saveAutomaticItem = (payload: EntityAutoCompleteItem) => {
    const { repository, setDetalleInspeccion, DetalleInspeccion } = props;

    if (!payload.TipoInspeccion)
      return AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        languageTranslate.moduloInspeccion.form.validate.noTipoInspeccion,
        "error"
      );

    let dataDetalle =
      payload.Item.dataComplete.TipoInspeccion.find(
        (row: any) =>
          row.id_TipoInspeccion ===
          payload.TipoInspeccion.dataComplete.IdTipoInspeccion
      )?.DetalleAnomalia || [];
    // let Detalle = payload?.Detalle || dataDetalle.length > 0 ? dataDetalle[0] : null;

    const nivelGravedadFinded = props.NivelGravedad.find(row => row.value === `0${payload.Item.dataComplete?.NivelCritico}`);

    const values = {
      Resultado: payload.Resultado,
      LevantamientoInmediato: false,
      Grave: (nivelGravedadFinded?.value === '03' && payload.Resultado.value === 1),
      Personal: initialValuesFormItem.Personal,
      TipoInspeccion: payload.TipoInspeccion,
      TipoItem: payload.TipoItem,
      Item: payload.Item,
      Detalle: null,
      Descripcion: null,
      NivelGravedad: payload.Resultado.value === 1 ? nivelGravedadFinded : null
    };

    const data = repository.formatterSaveItem({ values: values });

    const itemDuplicateFinded =
      dataDetalle.length > 0
        ? DetalleInspeccion.find(
            (item) =>
              item.Anomalia.Codigo === data.Anomalia.Codigo &&
              item.Anomalia.TipoAnomalia.Codigo === data.Anomalia.TipoAnomalia.Codigo &&
              item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
              item.TipoInspeccion.IdTipoInspeccion === data.TipoInspeccion.IdTipoInspeccion &&
              item.IdAnomalia !== data.IdAnomalia
          )
        : DetalleInspeccion.find(
            (item) =>
              item.Anomalia.Codigo === data.Anomalia.Codigo &&
              item.Anomalia.TipoAnomalia.Codigo ===
                data.Anomalia.TipoAnomalia.Codigo &&
              // item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
              item.IdAnomalia !== data.IdAnomalia &&
              item.Sancionado === "NO"
          );
    if (itemDuplicateFinded)
      return AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        languageTranslate.moduloInspeccion.form.validate.detalleItemDuplicado,
        "warning",
        false
      );

    if (data.Sancionado === "NO") {
      let itemExistsCorrecto =
        dataDetalle.length > 0
          ? false
          : DetalleInspeccion.find(
              (item) =>
                item.Anomalia.Codigo === data.Anomalia.Codigo &&
                item.Anomalia.TipoAnomalia.Codigo ===
                  data.Anomalia.TipoAnomalia.Codigo &&
                // item.Anomalia.DetalleAnomalia.Codigo === data.Anomalia.DetalleAnomalia.Codigo &&
                item.IdAnomalia !== data.IdAnomalia
            );

      if (itemExistsCorrecto)
        return AdapterGenerico.createMessage(
          languageTranslate.textoAlerta,
          languageTranslate.moduloInspeccion.form.validate
            .detalleItemNoPermitido,
          "warning",
          false
        );
    }

    const detalleInspeccion = DetalleInspeccion;
    detalleInspeccion.push({
      ...data,
      Incompleta: payload.Resultado.value === 0 ? false : true,
    });
    setDetalleInspeccion((prev) => [...detalleInspeccion]);
    updateAplica(detalleInspeccion);
  };

  const openModalEditModeFormItem = (IdAnomalia: number) => {
    const { DetalleInspeccion, repository, AnomaliaInspeccion } = props;
    const ItemFinded = DetalleInspeccion?.find(
      (isp) => isp.IdAnomalia === IdAnomalia
    );
    if (!ItemFinded)
      return AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        languageTranslate.moduloInspeccion.form.validate.noItem,
        "warning",
        false
      );

    const data = repository.formatDataForEditingItem(
      ItemFinded,
      AnomaliaInspeccion
    );

    const IdResultadoNoConforme = 1;
    if (props.isModeSurvey && data.Resultado.value === IdResultadoNoConforme) {
      data.LevantamientoInmediato = true;
    }

    formRegistroItem.setValues({ ...data });
    onChangeModalFormItem(true, true);
  };

  const deleteItem = (IdAnomalia: number) => {
    const indexFinded = props.DetalleInspeccion.findIndex(
      (dti) => dti.IdAnomalia === IdAnomalia
    );
    const { DetalleInspeccion, setDetalleInspeccion } = props;

    if (indexFinded === -1) {
      return AdapterGenerico.createMessage(
        languageTranslate.textoAlerta,
        languageTranslate.moduloInspeccion.form.validate.noDetalleItem,
        "warning",
        false
      );
    } else {
      DetalleInspeccion.splice(indexFinded, 1);
      setDetalleInspeccion((prev) => [...DetalleInspeccion]);
      updateAplica(DetalleInspeccion);
    }
  };

  const onChangeModalFormItem = async (show: boolean = true, isEdit: boolean = false) => {
    if (!show) await reloadForm();
    if ((TipoInspeccion || []).length === 1 && !isEdit) {
      onChangeItem("TipoInspeccion", TipoInspeccion[0]);
    }

    setShowModalFormItem(show);
  };

  const updateImage = (payload: any) => {
    const { setDetalleInspeccion, DetalleInspeccion } = props;
    const files: FileList = payload.file;
    const index = payload.index;

    Array.from(files).forEach((file, indexFile) => {
      DetalleInspeccion[index].Anomalia.Fotos.push({
        type: "Local",
        valueToShow: (window.URL ? URL : webkitURL).createObjectURL(file),
        value: {
          path: `INSP_${AdapterGenerico.formatoParaGuardarImagenes()}${indexFile}_${
            props.user._id
          }.${file.name.split('.').pop() || ''}`,
          file: file,
        },
      });
    });

    setDetalleInspeccion(() => [...DetalleInspeccion]);
  };

  const actionsFormItem = (typeAction: number, payload: any) => {
    switch (typeAction) {
      case 1:
        saveItem(payload);
        break;
      case 2:
        openModalEditModeFormItem(payload);
        break;
      case 3:
        deleteItem(payload);
        break;
      case 4:
        saveAutomaticItem(payload);
        break;
      case 5:
        updateImage(payload);
        break;
    }
  };

  const updateAplica = (payload: EntityDetalleInspeccion[]) => {
    let _aplica: EntityAplica[] = [];
    payload.reduce((prev, current) => {
      const Anomalia = {
        Anomalia: current.Anomalia.Anomalia,
        Codigo: current.Anomalia.Codigo,
        Aplica: 1,
      };
      const aplicaFinded = _aplica.findIndex(
        (apl) => apl.Codigo === current.Anomalia.TipoAnomalia.Codigo
      );
      if (aplicaFinded === -1) {
        prev.push({
          Codigo: current.Anomalia.TipoAnomalia.Codigo,
          TipoAnomalia: current.Anomalia.TipoAnomalia.TipoAnomalia,
          Anomalia: [Anomalia],
        });
      } else {
        let subItemFinded = _aplica[aplicaFinded].Anomalia.findIndex(
          (row) => row.Codigo === Anomalia.Codigo
        );
        if (subItemFinded === -1) _aplica[aplicaFinded].Anomalia.push(Anomalia);
      }
      return prev;
    }, _aplica);

    setAplica(() => [..._aplica]);
  };

  return {
    formRegistroItem,
    onChangeItem,
    setInitialValuesFormItem,
    onChangeModalFormItem,
    showModalFormItem,
    actionsFormItem,
    updateAplica,
    aplica,
  };
};

import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

export interface IConfigModal {
    show: boolean;
    filter: {
        fechaInicio: string;
        fechaFin: string;
        estado: Array<{ label: string; value: number }>;
    };
    option: {
        estado: Array<{ label: string; value: number }>
    }
}

export const initIConfigModal: IConfigModal = {
    show: false,
    filter: {
        fechaInicio: AdapterGenerico.getFirstDayMonth(),
        fechaFin: AdapterGenerico.convertDateToString(new Date(), 6),
        estado: [
            { label: LanguageTranslate().moduloRDI.list.option.pendiente, value: 10 }
        ],
    },
    option: {
        estado: [
            { label: LanguageTranslate().moduloRDI.list.option.solucionado, value: 1 },
            { label: LanguageTranslate().moduloRDI.list.option.pendiente, value: 10 }
        ]
    }
}
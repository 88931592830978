// Components
import { InputAutoCompleteLocation, InputCheck, InputSelect, InputText, InputTextArea } from "../../../shared/Components/ElementInputsCostume";
import { ElementInputFileImage } from "../../../shared/Components/ElementInputFileImage";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import ElementStepperContent from "../../../shared/Components/ElementStepperContent";
import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb";
import Stepper from "../../../shared/Components/ElementStepper";

// Styles
import "./View.scss";

// Infraestructure
import { ListFilterOptionSelect } from "../../../shared/Infraestructure/ListFilterOptionSelect";
import { EntityConfigForm, EntityDataForm, EntityLoadData, EntityLoadDataGuest, EntityModal } from "../Domain/Utils";
import { EntityGenericDetailForm } from "../../../shared/Domain/EntityGenericDetailForm";
import { AdapterConfigure } from "../Infraestructure/AdapterConfigure";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";

interface IView {
  setModalData: React.Dispatch<React.SetStateAction<EntityModal>>;
  formRegistro: EntityGenericDetailForm<EntityDataForm>;
  onChange: (name: any, value: any) => void;
  onSubmit: Function;
  onCancel: Function;
  selectInformation: EntityLoadData;
  selectInformationGuest: EntityLoadDataGuest;
  modalData: EntityModal;
  onChangeContentForTab: (newKey: number) => void;
  isModeInvite: boolean;
  configForm: EntityConfigForm;
}

export const View = (props: IView) => {
  const { onChange, formRegistro, onChangeContentForTab } = props;
  const openModal = (type: 'images' | 'imagesSubsanacion') => props.setModalData((prev) => ({ ...prev, show: true, type }));
  const languageTranslate = LanguageTranslate();
  const valuePais = formRegistro.values['Pais'];
  const valueGrupo = formRegistro.values['OT']?.dataComplete?.Grupo;

  return (
    <div className="ViewRDIForm">
      <div className="container-fluid">
        {/** BLOQUE DE BREADCRUMB */}
        <ElementBreadCrumb list={[...languageTranslate.moduloRDI.form.navbar, AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode) ? { text: languageTranslate.textoModoVisualizar, navigate: false, path: '' } : { text: languageTranslate.textoModoCrear, navigate: false, path: '' }]} />
      </div>
      
      <div className="container-form">

        <Stepper
          data={[
            languageTranslate.moduloRDI.form.stepper.stepper1.text,
            languageTranslate.moduloRDI.form.stepper.stepper2.text,
          ]}
          onChange={props.onChangeContentForTab}
          stepPosition={props.configForm.keyTab}
        />

        {/** STEP 1 */}
        <ElementStepperContent
          step={1}
          currentStep={props.configForm.keyTab}
          title={languageTranslate.moduloRDI.form.stepper.stepper1.content.title}
          onSubmit={onChangeContentForTab}
        >
          <>
            { props.configForm.loading ? <div className="mb-3"> Cargando... </div> : '' }
            {
              ['createGuest'].includes(props.configForm.mode) ?
              <>
                <div className="form-row d-flex mb-0">
                  <InputSelect
                    label={
                      languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                        .ot
                    }
                    name="OTGuest"
                    onChange={onChange}
                    values={formRegistro.values}
                    isRequired
                    options={props.selectInformationGuest.OT}
                    loading={props.configForm.loading}
                    disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                    disabledVirtualized={false}
                    className="w-100"
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoPais}
                    options={props.selectInformation.Pais}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabled
                    className='form-group w-100'
                    disabledVirtualized
                    name='Pais'
                    isRequired
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoDelegacion}
                    options={!valuePais?.value ? [] : props.selectInformation.Delegacion.filter((dlg) => dlg.dataComplete.Pais.IdPais === valuePais.value)}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabled
                    className='form-group w-100'
                    disabledVirtualized
                    name='Delegacion'
                    isRequired
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect
                    label={languageTranslate.moduloRDI.form.textoEmpresa}
                    options={!valuePais?.value ? [] : props.selectInformationGuest.Empresa.filter(row => row.dataComplete.IdPais === valuePais.value)}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    className='form-group w-100'
                    disabledVirtualized
                    name='Empresa'
                    isRequired
                    arrFilterOption={ListFilterOptionSelect.empresa}
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoSubcontrata}
                    options={props.selectInformationGuest.Subcontrata.filter(row => row.dataComplete.IdPais === valuePais?.value)}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                    className='form-group w-100'
                    disabledVirtualized
                    name='SubContrataGuest'
                    arrFilterOption={ListFilterOptionSelect.subcontrataLite}
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoCliente}
                    options={!valuePais?.value ? [] : props.selectInformationGuest.Cliente.filter((clt) => clt.dataComplete.IdPais === valuePais.value)}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabledVirtualized
                    className='form-group w-100'
                    name='Cliente'
                    isRequired
                    arrFilterOption={ListFilterOptionSelect.cliente}
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoActividad}
                    options={(props.formRegistro.values.Delegacion?.dataComplete && props.selectInformation.Actividad[0]?.dataComplete?.Codigo) ?
                      props.selectInformation.Actividad.filter(row => row.dataComplete.Grupo?.Codigo === props.formRegistro.values.Delegacion?.dataComplete.Grupo.Codigo)
                      :
                      props.selectInformation.Actividad
                    }
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabled
                    disabledVirtualized
                    className='form-group w-100'
                    name='Actividad'
                    isRequired
                    isClearable
                  />
                </div>
                <div className="form-row d-flex mb-0">
                  <InputSelect 
                    label={languageTranslate.moduloRDI.form.textoUsuarioRegistra}
                    options={!valuePais?.value ? [] : props.selectInformationGuest.Personal[valuePais.value]}
                    values={formRegistro.values}
                    onChange={props.onChange}
                    loading={props.configForm.loading}
                    disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                    className='form-group w-100'
                    name='UsuarioRegistroGuest'
                    arrFilterOption={ListFilterOptionSelect.personal}
                    isAsync={true}
                    delaySearch={1000}
                    isClearable
                  />
                </div>
              </>
              : null
            }
            {
              ['viewGuest', 'view', 'create', 'levantamiento'].includes(props.configForm.mode) ?
                <>
                  {
                    props.configForm.mode === 'create' ?
                      <div className="form-row d-flex mb-0">
                        <InputSelect
                          label={
                            languageTranslate.moduloInspeccion.form.stepper.stepper1.content
                              .ot
                          }
                          name="OT"
                          onChange={onChange}
                          values={formRegistro.values}
                          isRequired
                          options={props.selectInformation.OT}
                          loading={props.configForm.loading}
                          disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                          disabledVirtualized={false}
                          className="w-100"
                          isClearable
                        />
                      </div>
                    : null
                  }

                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoPais}
                      options={props.selectInformation.Pais}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={props.isModeInvite || AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      className='form-group w-100'
                      disabledVirtualized
                      name='Pais'
                      isRequired
                      isClearable
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoDelegacion}
                      options={!valuePais?.value ? [] : props.selectInformation.Delegacion.filter((dlg) => dlg.dataComplete.Pais.IdPais === valuePais.value)}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      className='form-group w-100'
                      disabledVirtualized
                      name='Delegacion'
                      isRequired
                      isClearable
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoEmpresa}
                      options={!valuePais?.value || !valueGrupo ?
                        [] :
                        props.selectInformation.Empresa.filter(
                          (emp) =>
                            emp.dataComplete.Pais.IdPais === valuePais.value &&
                            emp.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_EMPRESA && (!!row.EstadoPNET || valueGrupo.IdGrupo !== AdapterKeyGeneral.ID_GRUPO_COBRA)) &&
                            emp.dataComplete.Grupo.IdGrupo === valueGrupo.IdGrupo
                          )
                      }
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      className='form-group w-100'
                      disabledVirtualized
                      name='Empresa'
                      isRequired
                      arrFilterOption={ListFilterOptionSelect.empresa}
                      isClearable
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoSubcontrata}
                      options={(!valuePais?.value ? [] : props.selectInformation.Subcontrata.filter((sbc) => sbc.dataComplete.Pais.IdPais === valuePais.value))}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      className='form-group w-100'
                      disabledVirtualized
                      name='Subcontrata'
                      arrFilterOption={ListFilterOptionSelect.subcontrata}
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoCliente}
                      options={(
                        !valuePais?.value || !valueGrupo ?
                        [] :
                        props.selectInformation.Cliente.filter(
                          (clt) =>
                            clt.dataComplete.Pais.IdPais === valuePais.value &&
                            clt.dataComplete.TipoEmpresa.some(row => row.IdTipoEmpresa === AdapterKeyGeneral.TIPO_CLIENTE && (!!row.EstadoPNET || valueGrupo.IdGrupo !== AdapterKeyGeneral.ID_GRUPO_COBRA)) &&
                            clt.dataComplete.Grupo.IdGrupo === valueGrupo.IdGrupo
                          )
                      )}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      disabledVirtualized
                      className='form-group w-100'
                      name='Cliente'
                      isRequired
                      arrFilterOption={ListFilterOptionSelect.cliente}
                      isClearable
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoActividad}
                      options={props.selectInformation.Actividad}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      disabledVirtualized
                      className='form-group w-100'
                      name='Actividad'
                      isRequired
                      isClearable
                    />
                  </div>
                  <div className="form-row d-flex mb-0">
                    <InputSelect 
                      label={languageTranslate.moduloRDI.form.textoUsuarioRegistra}
                      options={!valuePais?.value ? [] : [...(props.selectInformation.Personal[valuePais.value] || []), ...(props.selectInformation.Personal[AdapterConfigure.KEY_ALL_ADMIN] || [])]}
                      values={formRegistro.values}
                      onChange={props.onChange}
                      loading={props.configForm.loading}
                      disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                      className='form-group w-100'
                      name='UsuarioRegistro'
                      arrFilterOption={ListFilterOptionSelect.personal}
                      isRequired
                      isAsync={true}
                      delaySearch={1000}
                      isClearable
                    />
                  </div>
                </>
              : null
            }
          </>
        </ElementStepperContent>
          
        {/** STEP 2 */}
        <ElementStepperContent
          step={2}
          currentStep={props.configForm.keyTab}
          title={languageTranslate.moduloRDI.form.stepper.stepper2.text}
          onSubmit={props.onChangeContentForTab}
          removeButtonSubmit
          isLastStep={props.configForm.keyTab === 2}
        >
          
          <div className="form-row d-flex mb-0">
            <InputText 
              label={languageTranslate.moduloRDI.form.textoFechaHoraIncidencia}
              values={formRegistro.values}
              onChange={props.onChange}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='FechaIncidente'
              type="datetime-local"
              maxdate={new Date().toISOString().split('.')[0]}
              isRequired
            />
          </div>

          <div className="form-row d-flex mb-0">
            <InputSelect 
              label={languageTranslate.moduloRDI.form.textoTipoIncidencia}
              options={props.configForm.mode === 'createGuest' ? props.selectInformationGuest.TipoIncidencia : props.selectInformation.TipoIncidencia}
              values={formRegistro.values}
              onChange={props.onChange}
              loading={props.configForm.loading}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              disabledVirtualized
              name='TipoIncidencia'
              isRequired
              isClearable
            />
          </div>

          <div className="form-row d-flex mb-0">
            <InputTextArea
              label={languageTranslate.moduloRDI.form.textoDescripcionIncidencia}
              values={formRegistro.values}
              onChange={props.onChange}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='BreveDescripcionIncidente'
              isRequired
            />
          </div>

          <div className="form-row d-flex mb-0">
            <InputText 
              label={languageTranslate.moduloRDI.form.textoObra}
              values={formRegistro.values}
              onChange={props.onChange}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='Obra'
            />
          </div>

          {/* <div className="form-row d-flex mb-0">
            <InputText 
              label={languageTranslate.moduloRDI.form.textoProvincia}
              values={formRegistro.values}
              onChange={props.onChange}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='Provincia'
            />
          </div> */}

          <div className="form-row d-flex mb-0">
            <InputAutoCompleteLocation
              label={languageTranslate.moduloRDI.form.textoDireccion}
              values={formRegistro.values}
              onChange={props.onChange}
              onChangeAutoComplete={(name, value, complete) => {
                props.formRegistro.setValues((values: any) => ({
                  ...values,
                  [name]: value,
                  Provincia: complete.district || complete.locality
                }) 
              )}}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='Direccion'
            />
          </div>

          <div className="form-row d-flex mb-0">
            <InputSelect 
              label={languageTranslate.moduloRDI.form.textoFactorRiesgo}
              options={props.configForm.mode === 'createGuest' ? props.selectInformationGuest.FactorRiesgo : props.selectInformation.FactorRiesgo}
              values={formRegistro.values}
              onChange={props.onChange}
              loading={props.configForm.loading}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              disabledVirtualized
              className='form-group w-100'
              name='FactorRiesgo'
              isRequired
              isClearable
            />
          </div>

          <div className="detail">
            <InputSelect 
              label={languageTranslate.moduloRDI.form.textoTipoIncidente}
              options={props.configForm.mode === 'createGuest' ? props.selectInformationGuest.TipoIncidente : props.selectInformation.TipoIncidente}
              values={formRegistro.values}
              onChange={props.onChange}
              loading={props.configForm.loading}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              disabledVirtualized
              // isHorizontal
              className='w-100'
              name='TipoIncidente'
              isRequired
              isClearable
            />

            {/* <InputSelect 
              label={languageTranslate.moduloRDI.form.textoCondicionInsegura}
              options={props.configForm.mode === 'createGuest' ? props.selectInformationGuest.Standar : props.selectInformation.Standar}
              values={formRegistro.values}
              onChange={props.onChange}
              loading={props.configForm.loading}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              disabledVirtualized
              isHorizontal
              className='w-100'
              name='CondicionInsegura'
              isRequired
            /> */}

            <InputCheck
              label={languageTranslate.moduloRDI.form.textoParalizacionTrabajo}
              values={{ ParalizacionTrabajo: formRegistro.values['ParalizacionTrabajo'].value === 1 }}
              onChange={(name, value) => props.onChange(name, !!value ? { value: 1, label: languageTranslate.btnSi, dataComplete: {} } : { value: 0, label: languageTranslate.btnNo, dataComplete: {} } )}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              isHorizontal
              className='w-100'
              name='ParalizacionTrabajo'
              isRequired
            />

            <InputCheck
              label={languageTranslate.moduloRDI.form.textoVehiculoAfectado}
              values={{ VehiculoAfectado: formRegistro.values['VehiculoAfectado'].value === 1 }}
              onChange={(name, value) => props.onChange(name, !!value ? { value: 1, label: languageTranslate.btnSi, dataComplete: {} } : { value: 0, label: languageTranslate.btnNo, dataComplete: {} } )}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              isHorizontal
              className='w-100'
              name='VehiculoAfectado'
              isRequired
            />

            {
              [1, "1"].includes(formRegistro.values["VehiculoAfectado"]?.value) && (
                <InputText 
                  label={languageTranslate.moduloRDI.form.textoPlaca}
                  values={formRegistro.values}
                  onChange={props.onChange}
                  disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
                  className='form-group w-100'
                  name='Placa'
                  isHorizontal
                  uppercase
                />
              )
            }

            <div className="d-flex flex-column">
             <div className="w-100 d-flex form-row">
                <label className="mb-2 w-100">{languageTranslate.moduloRDI.form.textoFotosIncidencia} { AdapterConfigure.MODE_REQUIRED_FILES_BY_TIPO_INCIDENCIA.includes(formRegistro.values.TipoIncidencia?.value) && <span className="text-danger">*</span> }</label>
                {
                  AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode) ? 
                    null :
                    <div className='d-flex align-self-center flex-row'>
                      <span className="camera"> <ElementInputFileImage icon={'fa-solid fa-camera'} capture disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)} onChange={(file) => onChange('File', file)} /> </span>
                      <span className="image"> <ElementInputFileImage icon={'fa-solid fa-image'} multiple disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)} onChange={(file) => onChange('File', file)} /> </span>
                    </div>
                }
             </div>
              {props.modalData.images.length !== 0 && <button className='btn btn-dark' onClick={() => openModal('images')}> {languageTranslate.moduloRDI.form.textoVerImagenes} ({props.modalData.images.length}) <i className="fa-solid fa-eye" style={{ fontSize: 20, marginLeft: 10 }} /> </button>}
            </div>
          </div>
        </ElementStepperContent>

        {/** STEP 2: MED PROPUESTAS */}
        <ElementStepperContent
          step={2}
          currentStep={props.configForm.keyTab}
          title={languageTranslate.moduloRDI.form.stepper.stepper2.textDetalleSolucion}
          onSubmit={props.onChangeContentForTab}
          removeButtonSubmit
          isLastStep={props.configForm.keyTab === 2}
        >
          <div className="form-row d-flex mb-0">
            <InputText 
              label={languageTranslate.moduloRDI.form.textoMedidasPropuestas}
              values={formRegistro.values}
              onChange={props.onChange}
              isRequired
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name='MedidasPropuestas'
            />
          </div>

          <div className="form-row d-flex mb-0">
            <InputSelect 
              label={languageTranslate.moduloRDI.form.textoUsuarioMedidasPropuestas}
              options={!valuePais?.value ? [] : [...((props.configForm.mode === 'createGuest' ? props.selectInformationGuest.Personal : props.selectInformation.Personal)[valuePais.value] || []), ...(props.selectInformation?.Personal[AdapterConfigure.KEY_ALL_ADMIN] || [])]}
              values={formRegistro.values}
              onChange={props.onChange}
              loading={props.configForm.loading}
              isRequired
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)}
              className='form-group w-100'
              name={props.isModeInvite ? 'UsuarioMPGuest' : 'UsuarioMP'}
              arrFilterOption={ListFilterOptionSelect.personal}
              isAsync={true}
              delaySearch={1000}
              isClearable
            />
          </div>
        </ElementStepperContent>

        {/** STEP 2: MED PROPUESTAS */}
        <ElementStepperContent
          step={2}
          currentStep={props.configForm.keyTab}
          title={languageTranslate.moduloRDI.form.stepper.stepper2.textSolucion}
          onSubmit={props.onChangeContentForTab}
          removeButtonSubmit
          isLastStep={props.configForm.keyTab === 2}
        >
          <div className="form-row d-flex">
            <span className="text-danger"><em>{languageTranslate.moduloRDI.form.textoAyudaSolucion}</em></span>
          </div>

          <div className="form-row d-flex mb-0">
            <InputText 
              label={languageTranslate.moduloRDI.form.textoDescripcionSolucion}
              values={formRegistro.values}
              onChange={props.onChange}
              isRequired={props.configForm.mode === 'levantamiento'}
              disabled={AdapterConfigure.MODE_BLOCK_INPUTS_PROPOSED_MEASURES.includes(props.configForm.mode)}
              className='form-group w-100'
              name='DescripcionSolucion'
            />
          </div>

          <div className="d-flex flex-column">
            <div className="w-100 d-flex form-row">
              <label className="mb-2 w-100">{languageTranslate.moduloRDI.form.textoFotoSubsanacion} { props.configForm.mode === 'levantamiento' ? <span style={{ color: 'red' }}>*</span> : null }</label>
              <div className='d-flex align-self-center flex-row gap-3'>
                <span className="camera"> <ElementInputFileImage icon={'fa-solid fa-camera'} capture disabled={AdapterConfigure.MODE_BLOCK_INPUTS_PROPOSED_MEASURES.includes(props.configForm.mode)} onChange={(file) => onChange('FileSubsanacion', file)} /> </span>
                <span className="image"> <ElementInputFileImage icon={'fa-solid fa-image'} multiple disabled={AdapterConfigure.MODE_BLOCK_INPUTS_PROPOSED_MEASURES.includes(props.configForm.mode)} onChange={(file) => onChange('FileSubsanacion', file)} /> </span>
              </div>
            </div>
            {props.modalData.imagesSubsanacion.length !== 0 && <button className='btn btn-dark' onClick={() => openModal('imagesSubsanacion')}> {languageTranslate.moduloRDI.form.textoVerImagenes} ({props.modalData.imagesSubsanacion.length}) <i className="fa-solid fa-eye" style={{ fontSize: 20, marginLeft: 10 }} /> </button>}
          </div>

        </ElementStepperContent>

        {
          props.configForm.keyTab === 2 ?
            <div className='group-button d-flex'>
              <button className='btn btn-dark' onClick={(evt) => props.onCancel(evt)}> {languageTranslate.btnCancelar} </button>
              <button disabled={AdapterConfigure.MODE_BLOCK_INPUTS_PROPOSED_MEASURES.includes(props.configForm.mode)} className='btn btn-primary' onClick={(evt) => props.onSubmit(evt)}> {languageTranslate.btnGuardar} </button>
            </div>
            : null
        }
      </div>
    </div>
  );
};

import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_es: EntityLanguage = {
    code: 'es',
    nombreProyecto: 'Sistema de Gestión de Seguridad Operacional',
    textoLogin: 'Iniciar Sesión',
    textoConfiguracion: 'Configuración',
    textoCatalogos: 'Refrescar datos',
    textoCerrarSesion: 'Cerrar Sesión',
    textoModoCrear: 'CREAR',
    textoModoEditar: 'EDITAR',
    textoModoVisualizar: 'VISUALIZAR',
    textoModoLevantamiento: 'LEVANTAMIENTO',
    textoNavbarInicio: 'Inicio',
    textoConfigurandoAreaTrabajo: 'Configurando su ambiente de trabajo...',
    textoSincronizacionSemanalCatalogo: 'Sincronización semanal de catálogos',
    textoNuevaVersion: 'Nueva versión, click aquí para actualizar',
    textoGuardando: 'Guardando...',
    textoCargando: 'Cargando...',
    textoAlerta: 'Alerta',
    textoIncompleto: 'Incompleto',
    textoExitoso: 'Exitoso',
    textoSinConexion: 'Sin Conexión',
    textoDescripcionSinConexion: 'No posee conexión a internet',
    textoValidacionTerminos: 'Debe de aceptar los términos',
    textoCambioPais: 'Se cambió de país a',
    textoPagina: 'Página',
    textoPaginaDe: 'de',
    textoNro: 'Nro.',
    btnCancelar: 'Cancelar',
    btnGuardar: 'Guardar',
    btnSiguiente: 'Siguiente',
    btnVerImagen: 'Ver imágenes',
    btnRegresar: 'Regresar',
    btnAgregar: 'Agregar',
    textoCargandoAudio: 'Procesando el audio, dependiendo la duración puede tardar mas tiempo...',
    btnDetener: 'Detener',
    btnReproducir: 'Reproducir',
    btnLimpiar: 'Limpiar',
    btnGuardarFirma: 'Guardar Firma',
    btnVerFirma: 'Ver Firma',
    btnSi: 'Si',
    btnNo: 'No',
    btnBuscar: 'Buscar',
    btnSeleccionar: 'Seleccionar',
    btnRecursoPreventivo: 'Recurso Preventivo',
    btnDescargar: 'Descargar',
    btnDescargando: 'Descargando...',
    moduloConfiguracion: {
        textoPreferencia: 'Preferencia',
        textoPais: 'País',
        textoDelegacion: 'Delegación',
        textoEmpresa: 'Empresa',
        textoOT: 'OT',
        textoIdioma: 'Idioma',
        textoCambioPassword: 'Cambiar Contraseña',
        textoPassword: 'Contraseña',
        textoNuevaPassword: 'Nueva Contraseña',
        textoRepetirNuevaPassword: 'Repetir Nueva Contraseña',
        textoBotonSaveCambioPassword: 'Guardar',
        textoPersonalMediosHumanos: 'Personal Medios Humanos',
        textoBuscarCuadrillaPersonal: 'Buscar Cuadrilla Personal',
        textoAutomatizaPersonal: 'Automatiza Personal Medios Humanos',
        validate: {
            formPreferencia: {
                pais: 'Seleccione un País',
                delegacion: 'Seleccione una Delegación',
                ot: 'Seleccione una OT',
                personal: 'Seleccione un personal de Medios Humanos'
            },
            formChangePassword: {
                password: 'Ingresar Contraseña',
                newPassword: 'Ingresar Nueva Contraseña',
                reNewPassword: 'Ingresar Repetir Nueva Contraseña',
                formatNewPassword: 'Tu nueva contraseña debe tener al menos 12 caracteres e incluir al menos una letra mayúscula, un número y un carácter especial'
            },
            cambioPasswordCorrecto: 'Se realizó el cambio de contraseña correctamente'
        }
    },
    moduloLogin: {
        textoTitulo: 'Login',
        txtUsuario: 'Usuario',
        txtPassword: 'Password',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Recuerdame',
        textoOlvidoContrasenia: '¿Olvido su contraseña?',
        textoRegresarMain: 'Volver al principal',
        textoRegistrarRDI: 'Ingresar como Invitado',
        textoTitleSelectPais: 'Seleccionar País',
        textoPlaceholderSelectPais: 'País',
        textoErrorSelectPais: 'Debe seleccionar una opción',
        validate: {
            formLogin: {
                username: 'Ingresar Usuario',
                usernameMinimo: 'Mímimo 3 caracteres',
                password: 'Ingresar Contraseña',
                passwordMinimo: 'Mímimo 3 caracteres'
            }
        }
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recuperar Contraseña',
        textoUsuario: 'Usuario',
        textoIdentificacion: 'Número de Identificación',
        textoCorreoElectronico: 'Correo Electrónico',
        textoAccion: 'Recuperar',
        textoRegresarLogin: 'Regresar Login',
        validate: {
            form: {
                username: 'Ingresar Usuario',
                usernameMinimo: 'Mímimo 3 caracteres',
                identificacion: 'Ingresar Número Doc. Identidad',
                identificacionFormato: 'El Número Doc. Identidad es incorrecto',
                correoElectronico: 'Ingresar Correo Electrónico',
                correoElectronicoFormato: 'El Correo Electrónico es incorrecto'
            },
            recuperarPasswordCorrecto: 'Se realizó la recuperación de contraseña correctamente, recibirá un mensaje al correo electrónico registrado'
        }
    },
    modulo404: {
        textoDescripcion: 'El recurso solicitado no esta disponible',
        textoHome: 'Volver al Inicio'
    },
    moduloNotAvailable: {
        textoCabecera: 'Recurso No Habilitado',
        textoDescripcion: 'El recurso solicitado no se encuentra disponible para su dispositivo',
    },
    moduloMain: {
        textoTitulo: 'Sistema de Gestión de Seguridad Operacional',
        textoBienvenida: '¡Te damos la bienvenida!',
        textoInicio: 'Inicio',
        textoAyuda: 'Ayuda',
        textoTituloPreguntaAyuda: 'Redirección',
        textoDescripcionPreguntaAyuda: 'Será redirigido a la documentación de ayuda del sistema, ¿Está seguro?',
        textoReporte: 'Reportar',
        textoRefrescarApp: 'Refrescar App',
        textoNotificacion: 'Notificación',
        textoTituloPreguntaReporte: 'Redirección',
        textoDescripcionPreguntaReporte: 'Será redirigido a la Sistema de Gestión de Servicios, ¿Está seguro?',
    },
    moduloCatalogo: {
        textoRefrescar: 'Refrescar',
        textoRefrescarTodo: 'Refrescar Todo',
        textoSincronizado: 'Estado: Sincronizado',
        textoNoSincronizado: 'Estado: No Sincronizado',
    },
    moduloRDI: {
        list: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'Lista', navigate: false, path: '' }],
            btnAgregar: 'AGREGAR',
            textoVacio: 'Sin Información',
            textoEstado: 'Estado',
            textoFechaInicio: 'Fecha inicio',
            textoFechafin: 'Fecha fin',
            validate: {
                formFilter: {
                    fechaInicio: 'Ingresar Fecha inicio',
                    fechaFin: 'Ingresar Fecha Fin',
                    estado: 'Seleccionar un estado'
                }
            },
            option: {
                pendiente: 'Pendientes',
                solucionado: 'Solucionados'
            }
        },
        form: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'Lista', navigate: false, path: '' }],
            textoPais: 'Pais',
            textoDelegacion: 'Delegación',
            textoEmpresa: 'Empresa',
            textoSubcontrata: 'Sub Contrata',
            textoCliente: 'Cliente',
            textoActividad: 'Actividad',
            textoUsuarioRegistra: 'Usuario Registra',
            textoFechaHoraIncidencia: 'Fecha y Hora Incidencia',
            textoTipoIncidencia: 'Tipo Incidencia',
            textoDescripcionIncidencia: 'Descripción Incidencia',
            textoMedidasPropuestas: 'Propuesta solución',
            textoDescripcionSolucion: 'Descripción solución',
            textoFotoSubsanacion: 'Foto subsanación',
            textoUsuarioMedidasPropuestas: 'Reponsable solución',
            textoObra: 'Obra',
            textoProvincia: 'Provincia',
            textoDireccion: 'Dirección',
            textoFactorRiesgo: 'Factor Riesgo',
            textoTipoIncidente: 'Propio / Subcontrata',
            textoParalizacionTrabajo: 'Paralización Trabajo',
            textoCondicionInsegura: 'Condición Insegura',
            textoVehiculoAfectado: 'Vehículo Afectado',
            textoVerImagenes: 'Ver imagen',
            textoPlaca: 'Placa del Vehículo Afectado',
            textMensajeAgradecimiento: 'El RDI ha sido grabado con éxito y ya se ha comunicado al resto de personas implicadas. Muchas gracias por comunicar esta incidencia, con tu reporte ayudas a mejorar tu seguridad y la de tus compañeros',
            textoFotosIncidencia: 'Fotos Incidencia',
            textoAyudaSolucion: 'Rellenar solamente si está solucionado la incidencia',
            modal: {
                textoTitulo: 'Imagen',
                btnEliminarImagen: 'ELIMINAR IMAGEN',
                textoSinImagenes: 'Sin imagenes'
            },
            btnNext: 'Siguiente',
            btnSave: 'Guardar',
            stepper: {
                stepper1: {
                    text: 'Información General',
                    content: {
                        title: 'INFORMACIÓN GENERAL',
                    }
                },
                stepper2: {
                    text: 'Detalle Incidencia',
                    textDetalleSolucion: 'Medidas Propuestas',
                    textSolucion: 'Solución',
                    content: {
                        title: 'DETALLE INCIDENCIA',

                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Seleccione una OT',
                    delegacion: 'Seleccione una Delegación',
                    empresa: 'Seleccione una Empresa',
                    cliente: 'Seleccione un Cliente',
                    actividad: 'Seleccione una Actividad',
                    usuarioRegistro: 'Seleccione un Usuario Registra',

                    fechaIncidente: 'Seleccione una Fecha y Hora Incidencia',
                    tipoIncidencia: 'Seleccione un Tipo Incidencia',
                    breveDescripcionIncidente: 'Ingrese una Descripción Incidencia',
                    factorRiesgo: 'Seleccione mínimo un Factor Riesgo',
                    tipoIncidente: 'Seleccione mínimo un Tipo Incidente',
                    condicionInsegura: 'Seleccione una Condición Insegura',
                    vehiculoAfectado: 'Seleccione Vehículo Afectado',
                    cantidadImagenes: 'La cantidad máxima de imágenes permitida es de 10',

                    propuestaSolucion: 'Ingrese una propuesta de solución',
                    descripcionSolucion: 'Ingrese una descripción de solución',
                    responsableSolucion: 'Ingrese un responsable de solución',
                    fotoSolucion: 'Ingrese una foto de subsanación',

                    fotoIncidencia: 'Ingrese una imágen de la incidencia',
                    fotoSolucionRequerimientroPrevioFotoIncidencia: 'Primero debe ingresar una foto de la incidencia'
                }
            },
            TipoIncidentePersonal: "PP(Pers.Propio)",
            TipoIncidenteSubContrata: "PS(Pers.Contra)"
        }
    },
    moduloInspeccion: {
        list: {
            navbar: [{ text: 'Inspecciones', navigate: false, path: '' }],
            navbarOPS: [{ text: 'OPS', navigate: false, path: '' }],
            btnAgregar: 'AGREGAR',
            textoVacio: 'Sin Inspecciones',
            textoVacioOPS: 'Sin Inspecciones OPS',
            textoEnviandoCorreo: 'Enviando correo...',
            modalEmail: {
                title: 'Enviar Correo',
                correoElectronico: 'Correo Electrónico',
                tableEmail: 'CORREOS',
                cc: 'CC',
                btnEnviar: 'Enviar'
            },
            modalSearch: {
                titulo: 'Búsqueda personalizada',
                fechaInicial: 'Fecha Inicial',
                fechaFinal: 'Fecha Final',
                tipoOperacion: 'Tipo Operación',
                identificacion: 'Identificación',
                buscarInspeccionesFinalizadas: 'Buscar inspecciones finalizadas',
                codigoInspeccion: 'Código Inspección',
                btnBuscar: 'Buscar'
            },
            item: {
                jefeObra: 'Jefe de Obra',
                autor: 'Autor',
                nroNoConformidad: 'Nro. no conformidad',
                fechaInspeccion: 'Fecha de Inspección',
                empresa: 'Empresa',
                subContrata: 'Sub Contrata',
                tipoOperacion: 'TipoOperacion',
                personal: 'Personal'
            },
            validate: {
                correoExiste: `Correo ya existe`,
                correoEnviado: `Se envió correctamente`,
                fechaInicio: 'Ingrese Fecha Inicial',
                fechaFinal: 'Ingrese Fecha Final',
                fechaRangoInicioFinal: 'La fecha inicial no debe ser mayor que la final',
                tipoOperacion: 'Ingrese mínimo un tipo de operación',
                identificacion: 'Ingrese identificación',
            }
        },
        form: {
            navbar: [{ text: 'Inspecciones', navigate: true, path: '/main/Inspeccion' }],
            navbarOPS: [{ text: 'OPS', navigate: true, path: '/main/OPS' }],
            navbarText: {
                modeCrear: 'Crear',
                modeVisualizar: 'Visualizar',
                modeLevantamiento: 'Levantamiento'
            },
            btnNext: 'Siguiente',
            btnSave: 'Guardar',
            stepper: {
                stepper1: {
                    text: 'Información General',
                    content: {
                        title: 'INFORMACIÓN GENERAL',
                        ot: 'OT',
                        area: 'Área / Filial',
                        empresa: 'Empresa',
                        actividad: 'Actividad',
                        cliente: 'Cliente',
                        jefeObra: 'Jefe de Obra',
                        coordinador: 'Técnico Seguridad',
                        encargado: 'Encargado'
                    }
                },
                stepper2: {
                    text: 'Información Inspección',
                    content: {
                        title: 'INFORMACIÓN INSPECCIÓN',
                        responsableLevantamiento: 'Responsable levantamiento',
                        inspeccionConjunta: 'Inspección en conjunta',
                        personalConjunta: 'Personal en Conjunta',
                        inspeccionSubcontrataConjunta: 'Inspección Subcontrata Conjunta',
                        subContrata: 'Sub Contrata',
                        tipoOperacion: 'Tipo Operación',
                        formato: 'Formato',
                        paralizacionTrabajo: 'Paralización del trabajo',
                        momento: 'Momento',
                        lugar: 'Lugar',
                        obraTD: 'Obra / TD',
                        trabajoRealizar: 'Trabajo a realizar',
                        placa: 'Placa / Matrícula',
                        reconocimientoSST: 'Reconocimiento por buen desempeño en SST',
                        observacionOPS: 'Observación OPS',
                        fueraHorario: 'Fuera del Horario',
                    }
                },
                stepper3: {
                    text: 'Información Personal',
                    content: {
                        title: 'INFORMACIÓN PERSONAL',
                        personal: 'Personal',
                        table: {
                            id: 'ID',
                            name: 'Nombre',
                            lastName: 'Apellidos',
                            responsable: 'Jefe Trabajo',
                            recursoPreventivo: 'Recurso Preventivo',
                        }
                    }
                },
                stepper4: {
                    text: 'Información Registro',
                    content: {
                        details: {
                            title: 'TIPO INSPECCIÓN',
                            btnSelect: 'Seleccionar',
                            listEmpty: 'Sin Tipo inspección seleccionado',
                            titleModal: 'Seleccione Tipo Inspección',
                            titleModalView: 'Información ítem',
                            titleModalCreate: 'Crear ítem',
                            titleModalEdit: 'Editar ítem'
                        },
                        items: {
                            title: 'LISTA ÍTEMS',
                            btnList: 'Lista',
                            btnAdd: 'Agregar',
                            listEmpty: 'Sin registros',
                            modal: {
                                tipoInspeccion: 'Tipo Inspección',
                                tipoItem: 'Tipo Ítem',
                                item: 'Ítem',
                                detalle: 'Detalle',
                                personal: 'Personal',
                                resultado: 'Resultado',
                                nivelGravedad: 'Nivel Gravedad',
                                tipoSituacion: 'Tipo Situación',
                                grave: 'Grave',
                                descripcion: 'Descripción',
                                accionPropuesta: 'Acción propuesta',
                                levantamientoInmediato: 'Levantamiento Inmediato',
                                descripcionLevantamiento: 'Descripción Levantamiento',
                                fechaLevantamiento: 'Fecha Levantamiento',
                                imgTextoInspeccion: 'Inspeccion',
                                imgTextoLevantamiento: 'Levantamiento',
                                titleModalImage: 'Visualizar imágenes',
                                fechaPrevista: 'Fecha Prevista',
                                btnSave: 'Guardar',
                                btnSaveAndContinue: 'Guardar y Continuar',
                                btnCancel: 'Cancelar'
                            },
                            item: {
                                tipoInspeccion: 'Tipo Inspección',
                                tipoItem: 'Tipo Ítem',
                                item: 'Ítem',
                                descripcion: 'Descripción',
                                textIncompleta: 'Incompleta',
                                textCorrecto: 'Correcto',
                                textGrave: 'Grave',
                                textNoConforme: 'No Conforme',
                                textImgInspeccion: 'Inspección'
                            }
                        },
                        textoCompruebaInformacion: 'Comprueba tu información en',
                        textObservacionGeneral: 'Observación General',
                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Seleccione una OT',
                    area: 'Seleccione una Area',
                    empresa: 'Seleccione una Empresa',
                    actividad: 'Seleccione una Actividad',
                    cliente: 'Seleccione un Cliente',
                    jefeObra: 'Seleccione un Jefe de obra',
                    coordinador: 'Seleccione un Coordinador',

                    conjuntaPersonal: 'Seleccione un Personal en Conjunta',
                    tipoOperacion: 'Seleccione un Tipo Operación',
                    formato: 'Seleccione un Formato',
                    tipoMomentoTrabajo: 'Seleccione un Tipo de momento',
                    lugar: 'Ingrese un Lugar',
                    obra: 'Ingrese una Obra',
                    trabajoRealizar: 'Ingrese un Trabajo a realizar',
                    observacionOPS: 'Ingrese una observación OPS',

                    personalResponsableTrabajo: 'Seleccione un Responsable del trabajo',

                    detalleInspeccionVacia: 'Ingrese mínimo un ítem',
                    detalleInspeccionIncompleta: 'Complete todos los ítems',
                    responsableLevantamiento: 'Seleccione un Responsable',

                    cantidadImagenes: (cantidadMaxima: any) => `La cantidad máxima de imágenes permitidas es de ${cantidadMaxima}.`, 
                },
                formRegistroItem: {
                    tipoInspeccion: 'Seleccione Tipo Inspección',
                    tipoItem: 'Seleccione Tipo Item',
                    item: 'Seleccione Item',
                    detalle: 'Seleccione Detalle',
                    personal: 'Seleccione un Personal',
                    resultado: 'Seleccione un Resultado',
                    nivelGravedad: 'Seleccione Nivel Gravedad',
                    descripcion: 'Seleccione una Descripción',
                    accionPropuesta: 'Ingrese una Acción Propuesta',
                    tipoSituacion: 'Seleccione un Tipo Situación',
                    descripcionLevantamiento: 'Ingrese una Descripción de Levantamiento',
                    fechaLevantamiento: 'Ingrese una Fecha de Levantamiento',
                    fotoLevantamiento: 'Ingrese una Imagen de Levantamiento',
                    fechaPrevista: 'Ingrese una Fecha Prevista',
                    cantidadImagenes: '¿Todas las imágenes son necesarias? (verificar que no se repita ninguna imagen)',
                },
                noItem: 'No se encontró el ítem',
                noDetalleItem: 'Detalle de ítem no encontrado',
                detalleItemDuplicado: 'Detalle de ítem duplicado',
                detalleItemNoPermitido: 'No se permite agregar el item',
                noTipoInspeccion: 'Debe seleccionar un Tipo de Inspección para autocompletar'
            },
            TipoMomento: {
                inicio: 'Inicio del Trabajo',
                durante: 'Durante Trabajo',
                fin: 'Fin Trabajo'
            },
            textoBuscarCuadrillaPersonal: "Buscando cuadrilla del personal...",
            textoCorrecto: "Correcto",
            textoNoConforme: "No Conformidad",
            nivelSeguridad: {
                textoAlta: "Alta",
                textoMedia: "Media",
                textoLeve: "Leve",
            },
            tipoSituacion: {
                textoActoInseguro: "Acto Inseguro",
                textoCondicionInsegura: "Condición Insegura"
            }
        }
    },
    moduloAutoChequeo: {
        list: {
            TituloListaAutochequeo: "LISTA AUTOCHEQUEO",
            TituloListaControlPreventivo: "CONTROL PREVENTIVO",
            navbar: [{ text: 'Gestión Autochequeo', navigate: false, path: '' }],
            navbarGuest: [{ text: 'Charla Pretarea', navigate: false, path: '' }],
            Estado: "Estado",
            textoCPPendiente: 'CP Pendiente',
            textoCPCerrado: 'CP Cerrado',
            EstadoItems: {
                EstadoActivo: "Activo",
                EstadoInactivo: "Inactivo"
            },
            Resultado: "Resultado",
            ResultadoItems: {
                ResultadoCorrecto: "Correcto",
                ResultadoIncorrecto: "Incorrecto"
            },
            Autor: "Autor",
            FechaRegistro: "Fecha Registro",
            textAudio: 'Audio',
            textPDF: 'PDF',
            search: {
                Titulo: "Buscar Autochequeo",
                FechaInicial: "Fecha Inicial",
                FechaFinal: "Fecha Final",
                Identificacion: "Identificacion",
                BtnBuscar: "Buscar",
                BusquedaGrupal: "Búsqueda grupal",
                BuscarRegistrosActivos: "Búsqueda Registros Activos"
            },
            validate: {
                formFilter: {
                    fechaInicial: 'Seleccione una Fecha Inicial',
                    fechaFinal: 'Seleccione una Fecha Final',
                    identificacion: 'Ingrese una Identificación'
                }
            }
        },
        form: {
            navBarTextCharlaPretarea: 'Charla Pretarea',
            navBarTextListaAutochequeo: 'Lista Autochequeo',
            navBarTextControlPreventivo: 'Control Preventivo',
            navBarTextCierreControlPreventivo: 'Cierre Control Preventivo',
            navBarTextPreview: 'Visualizar',
            navBarGuest: [{ text: 'Charla Pretarea', navigate: true, path: '/main/GestionAutochequeo' }, { text: 'Detalle', navigate: false, path: '' }],
            tituloDatosGenerales: 'Datos Generales',
            tituloDatosControlPreventivo: 'Datos Control Preventivo',
            textoAyudaControlPreventivo: 'Contiene cierre control preventivo',
            textoAyudaNecesitaRegistrarLAutochequeo: 'Esta OT necesita que primero se haga la lista autochequeo, si lo hizo ignore este mensaje',
            btnAutcompletarUltimoRegistro: 'Autocompletar del último registro',
            inputs: {
                ot: 'OT',
                empresa: 'Empresa',
                subContrata: 'SubContrata',
                delegacion: 'Delegación',
                actividad: 'Actividad',
                recursoPreventivo: 'Recurso Preventivo',
                cliente: 'Cliente',
                obra: 'Obra',
                lugar: 'Lugar',
                trabajoRealizar: 'Trabajo a realizar',
                jefeObra: 'Jefe de Obra',
                tituloGrupo: 'Información por grupo',
                ordenServicio: 'Orden Servicio / Orden Trabajo',
                txtObservacion: 'Observación',
                txtPersonal: 'Personal',
                textFirmaJefeTrabajo: 'Firma del Jefe de Trabajo',
                textBien: 'Bien',
                textMal: 'Mal'
            },
            preguntarRealizarCierreControlPreventivo: '¿Desea realizar el Cierre del Control Preventivo?',
            advertenciaListaAutochequeoRealizado: 'Ya se realizó la lista Autochequeo',
            advertenciaSinRegistro: 'No se encontró el registro',
            advertenciaRegistroConCierre: 'Este registro ya cuenta con un Cierre',
            advertenciaCargarOT: 'Ocurrió un error al cargar las OTs',
            advertenciaBuscarOTRegistro: 'No se encontró la OT vinculada al registro',
            advertenciaRegistroSinCierre: 'Esta OT no cuenta con un Cierre Control Preventivo',
            advertenciaOTSinTipologias: 'Esta OT no cuenta con tipologías',
            advertenciaOTSinPreguntas: 'Esta OT no cuenta con ninguna pregunta',
            advertenciaOTPreview: (text: string) => `No se pudo encontrar la OT que registró en ${text}, realice de nuevo todo el proceso`,
            advertenciaOTSinPreguntasAutochequeo: 'Esta OT no cuenta con preguntas para mostrar, pruebe con otra',
            textTipologia: 'Tipologías',
            tipologiaVacio: 'Sin Tipologías',
            textCargandoPreguntas: 'Cargando Preguntas',
            textCargandoTipologias: 'Cargando Tipologías',
            textRegistroIncompleto: '¿Tiene un registro que no fue completado, desea cargarlo?',
            validate: {
                formRegistro: {
                    ordenServicio: 'Ingrese un Orden Servicio / Orden Trabajo',
                    ot: 'Seleccione una OT',
                    otDirectoCP: `Necesita completar primero 'Lista de Autochequeo' ó 'Charla Pretarea'`,
                    tipologia: 'Seleccione mínimo una tipología',
                    empresa: 'Seleccione una Empresa',
                    delegacion: 'Seleccione una Delegación',
                    actividad: 'Seleccione una Actividad',
                    cliente: 'Seleccione un Cliente',
                    obra: 'Ingrese una Obra',
                    lugar: 'Ingrese un Lugar',
                    trabajoRealizar: 'Ingrese un Trabajo a realizar',
                    jefeObra: 'Seleccione un Jefe de obra',
                    usuarioRegistra: 'Seleccione Usuario Registra'
                },
                formDinamico: {
                    preguntaNoRespondido: 'No ha respondido la pregunta número',
                    preguntaNoEstadoEquipo: `Seleccione 'estado del equipo' en la pregunta número`,
                    preguntaNoObservacion: `Ingrese una 'observación' en la pregunta número`,
                    preguntaNoAudio: `Registre un 'audio' en la pregunta número`,
                    preguntaNoPersonal: `Ingrese 'personal' en la pregunta número`,
                    preguntaNoImagen: (count: number) => `La cantidad máxima de imágenes es de ${count} en la pregunta número`,
                    preguntaImagen: `Ingrese una imagen en la pregunta número`,
                    preguntaBloqueo: `No se permite continuar, debido a falta de formación técnica en la pregunta número`,
                    preguntaNoImagenGrupal: `Ingrese una imagen grupal`,
                    preguntaNoObservacionGrupal: `Ingrese una observación grupal`,
                    preguntaBlockDevolverOrden: (numero: any) => `Si no cumple con el material necesario en la pregunta número ${numero} no podrá registrar`,
                    preguntaModuloAUT: 'Solo puede registrar en "Lista Autochequeo" si es el único componente de la brigada, los grupales se hacen en "Charla Pretarea"',
                    preguntaModuloCPT: 'Solo puede registrar en "Charla Pretarea" si son varios componentes de la brigada, los individuales se hacen en "Lista Autochequeo"',
                    preguntaJefeTrabajo: 'Seleccione un Jefe de trabajo en la pregunta número',
                    preguntaRiesgo: 'Seleccione un riesgo en la pregunta número',
                    preguntaMedidaPreventiva: 'Seleccione una Medida Preventiva en la pregunta número',
                    preguntaEPCS: 'Seleccione una EPCS en la pregunta número',
                    preguntaRecursoPreventivo: 'Seleccione el recurso Preventivo en la pregunta número',
                    preguntaFirmaJefeTrabajo: 'Ingrese la firma del Jefe de Trabajo en la pregunta número',
                    preguntaFirmaJefeTrabajoVacio: 'Dibuje una firma para poder guardar',
                }
            }
        },
        comentario: {
            textComentario: 'Comentario',
            titleModalAgregar: 'Agregar comentario',
            titleModalList: 'Lista comentarios',
            table: {
                autor: 'Autor',
                puntuacion: 'Puntuación',
                Comentario: 'Comentario'
            },
            requiredAutochequeo: 'Primero debe revisar el PDF antes de ingresar un comentario',
            requiredCharlaPretarea: 'Primero debe revisar el audio y el PDF antes de ingresar un comentario'
        }
    },
    moduloNotificacion: {
        textoNoLeido: 'No leídas',
        textoAcabaRecibirNotificacion: 'Acaba de recibir un nuevo comentario en el registro nro:',
        textoNuevo: 'Nuevo'
    },
    menu: { 
        '010': {
            titulo: 'Inspecciones',
            descripcion: 'Registro de Inspecciones'
        },
        '020': {
            titulo: 'Inspecciones OPS',
            descripcion: 'Registro de Inspecciones OPS'
        },
        '030': {
            titulo: 'Lista de Autochequeo',
            descripcion: 'Registro de Lista de Autochequeo'
        },
        '031': {
            titulo: 'Charla Pretarea',
            descripcion: 'Grabación de la Charla Pretarea'
        },
        '040': {
            titulo: 'Control Preventivo',
            descripcion: 'Registro de Preguntas de Control Preventivo'
        },
        '050': {
            titulo: 'RDI',
            descripcion: 'Registro de Incidentes de Seguridad'
        },
        '060': {
            titulo: 'Gestión Autochequeo',
            descripcion: 'Gestión Autochequeo'
        },
    },
    languages: [
        { code: 'es', name: 'Español' },
        { code: 'en', name: 'Inglés' },
        { code: 'it', name: 'Italiano' },
        { code: 'fr', name: 'Francés' },
        { code: 'pt', name: 'Portugués' },
    ]
}
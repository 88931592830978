import { InputCheck } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";

interface ContainerProps {
    openModalForm: () => void;
    Comentarios: any[];
    show: boolean;
    closeModal: () => void;
    onSubmitFeedback: (_: string, value: boolean) => Promise<void>
    valueFeedback: boolean;
    disabledFunctions: boolean;
}

const LIST_STAR = [1, 2, 3, 4, 5];
const COLOR_CALIFICACION: any = {
    1: 'red',
    2: 'red',
    3: '#e4c01b',
    4: 'green',
    5: 'green'
}
const languageTranslate = LanguageTranslate();

export const ViewListComentarios = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            show={props.show}
            closeModal={props.closeModal}
            title={languageTranslate.moduloAutoChequeo.comentario.titleModalList}
            height="320px"
        >
            <div className="d-flex">
                <InputCheck
                    label="Feedback"
                    name="Feedback"
                    onChange={props.onSubmitFeedback}
                    values={{ Feedback: props.valueFeedback }}
                    className="w-50"
                    disabled={props.disabledFunctions}
                />
                {
                    props.disabledFunctions ?
                        null
                    :
                        <button className="btn btn-primary d-flex" style={{ marginLeft: 'auto', height: 30, alignItems: 'center' }} onClick={() => props.openModalForm()}>{ languageTranslate.btnAgregar }</button>
                        
                }
            </div>
            <div className="py-4" style={{ height: 200, overflowY: 'scroll' }}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{languageTranslate.moduloAutoChequeo.comentario.table.autor}</th>
                            <th scope="col">{languageTranslate.moduloAutoChequeo.comentario.table.puntuacion}</th>
                            <th scope="col">{languageTranslate.moduloAutoChequeo.comentario.table.Comentario}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.Comentarios.map(row => {

                                return (
                                    <tr>
                                        <td>{ row.Autor.Nombres }</td>
                                        <td>
                                            <div className="d-flex justify-content-center mb-3">
                                                {
                                                    LIST_STAR.map(_row => 
                                                        <i
                                                            className="fa-sharp fa-solid fa-star"
                                                            style={{ fontSize: 8, color: _row <= row.Puntuacion ? COLOR_CALIFICACION[row.Puntuacion] : '#bcbfbc' }}
                                                        ></i>    
                                                    )
                                                }
                                            </div>
                                        </td>
                                        <td>{ row.Comentario }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </ElementModalCostume>
    )
}
import './Style.scss';

import logo from '../../../../assets/img/IconosAppSGSOMovil/ic_nuevo2_round.png';
import logoSafeIS from '../../../../assets/img/safeis/logo-safeis-rounded.png';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';

//Imagenes


export const ViewMain = () => {
    const { generico: { textLoadingMaestro }, auth: { preferencia } } = useSelector((root: RootState) => root)

    return (
        <div className="ViewSplash">
            <div className="fondo"></div>
            <div className="login-block">
                {/* <div className="container"> */}
                <div className="row text-center">
                    <div className="col-12 text-center">
                        <img src={preferencia.appv2 ? logoSafeIS : logo} alt="Logo" className='img-fluid' width={110} />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12 text-center">
                        <span className="textLoader" dangerouslySetInnerHTML={{ __html: textLoadingMaestro }} ></span>
                    </div>
                </div>
            </div>
        </div >
    )
};
import { InputSelectSimple } from "../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../shared/Components/ElementModalCostume"
import { EntityLanguage } from "../../../shared/Domain/EntityLanguage"
import { IConfigModal } from "../Domain/Utils";

interface IProps {
    languageTranslate: EntityLanguage;
    onChangeModal(param: Partial<IConfigModal>): void;
    onSubmitModal(): Promise<void>;
    configModal: IConfigModal;
}

export const ViewModal = (props: IProps) => {
    const {
        languageTranslate,
        onChangeModal,
        configModal,
        onSubmitModal
    } = props;

    return (
        <ElementModalCostume
            title={languageTranslate.moduloInspeccion.list.modalSearch.titulo}
            closeModal={() => onChangeModal({ show: false })}
            show={configModal.show}
        >
            <div className="row pb-3 ViewInspeccionListar">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="mb-1">{languageTranslate.moduloRDI.list.textoFechaInicio} <span className="text-danger">*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                value={props.configModal.filter['fechaInicio']}
                                onChange={(evt) => onChangeModal({ 'filter': { ...configModal.filter, fechaInicio: evt.target.value } })}
                            />
                        </div>
                        <div className="col-12 topInput mb-3">
                            <label className="mb-1">{languageTranslate.moduloRDI.list.textoFechafin} <span className="text-danger">*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                value={props.configModal.filter['fechaFin']}
                                onChange={(evt) => onChangeModal({ 'filter': { ...configModal.filter, fechaFin: evt.target.value } })}
                            />
                        </div>
                        <div className="col-12 topInput mb-3">
                            <label className="mb-1">{languageTranslate.moduloRDI.list.textoEstado} <span className="text-danger">*</span></label>
                            <InputSelectSimple
                                label=''
                                name='estado'
                                options={configModal.option.estado}
                                onChange={(name, value) => onChangeModal({ 'filter': { ...configModal.filter, [name]: value } })}
                                values={{ estado: props.configModal.filter.estado }}
                                isMulti
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between mt-5 mb-3 enviar-email">
                <button className="btn btn-primary w-100" style={{ minWidth: 120 }} onClick={onSubmitModal}> {languageTranslate.moduloInspeccion.list.modalSearch.btnBuscar} </button>
            </div>
        </ElementModalCostume>
    )
}
// Domain
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityAutochequeoItems, EntityAutochequeoItemsDisponibilidad } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityMedidaPreventivas } from "../../../shared/Domain/Catalogos/EntityMedidaPreventivas";
import { EntityAutochequeoTipologia } from "../../../shared/Domain/EntityAutochequeoTipologia";
import { EntityPersonal, EntitySelectPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityLiteEmpresa } from "../../../shared/Domain/CatalogosLite/EntityLiteEmpresa";
import { EntityLitePersonal } from "../../../shared/Domain/CatalogosLite/EntityLitePersonal";
import { EntityOpcionAudio } from "../../../shared/Domain/Catalogos/EntityOpcionAudio";
import { EntityTipoPregunta } from "../../../shared/Domain/Catalogos/EntityTipoPregunta";
import { EntityPreguntaLA } from "../../../shared/Domain/Catalogos/EntityPreguntaLA";
import { EntityTipoRiesgo } from "../../../shared/Domain/Catalogos/EntityTipoRiesgo";
import { EntityOTs, EntitySelectOTs } from "../../../shared/Domain/EntityOTs";
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityEPCS } from "../../../shared/Domain/Catalogos/EntityEPCS";
import { EntityActividad } from "../../../shared/Domain/EntityActividad";
import { EntityModuleForm, KeyModule } from "../Domain/EntityModuleForm";
import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { EntityQuestionEmail } from "../Domain/EntityQuestionEmail";
import { EntityDocumentation } from "../Domain/EntityDocumentation";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { EntityConfigModalComentarios, EntityDataForm, EntityFormIncomplete, EntityLoadData, EntityLoadDataGuest } from "../Domain/EntityUtils";
import { DtoRequestForm, EntityRequestFormComentario, EntityRequestFormFeedback } from "../Domain/DtoRequestForm";

// Infraestructure
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { AdapterConfigure } from "./AdapterConfigure";
import { AdapterLoadMaster } from "../../../shared/Infraestructure/AdapterLoadMaster";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { LanguageApp } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterKeyGeneral } from "../../../shared/Infraestructure/AdapterKeyGeneral";
import { EntityFormatos } from "../../../shared/Domain/CatalogosV2/EntityFormatos";

export class RepositoryCollections extends RepositoryImplGenerico<any> {

    public async getAutochequeoTipologia(): Promise<EntityAutochequeoTipologia[]> {
        return await this.dbLocal.selectAllStore('AutochequeoTipologia')
    }

    public async getAutochequeoTitulos(): Promise<EntityAutochequeoTitulos[]> {
        return await this.dbLocal.selectAllStore('AutochequeoTitulos')
    }

    public async getAutochequeoItems(): Promise<EntityAutochequeoItems[]> {
        return await this.dbLocal.selectAllStore('AutochequeoItems')
    }

    public async getTipoPreguntas(): Promise<EntityTipoPregunta[]> {
        return await this.dbLocal.selectAllStore('CTTipoPregunta');
    }

    public async getPreguntasLA(): Promise<EntityPreguntaLA[]> {
        return await this.dbLocal.selectAllStore('CTPreguntaLA');
    }

    public async getEPCS(): Promise<EntityEPCS[]> {
        return await this.dbLocal.selectAllStore('CTEPCS');
    }

    public async getTipoRiesgo(): Promise<EntityTipoRiesgo[]> {
        return await this.dbLocal.selectAllStore('CTTipoRiesgo');
    }

    public async getMedidasPreventivas(): Promise<EntityMedidaPreventivas[]> {
        return await this.dbLocal.selectAllStore('CTMedidaPreventivas');
    }

    public async getOpcionAudio(): Promise<EntityOpcionAudio[]> {
        return await this.dbLocal.selectAllStore('CTOpcionAudio');
    }

    public async getAutochequeo(): Promise<EntityAutochequeo[]> {
        return await this.dbLocal.selectAllStore('Autochequeo');
    }

    public async getFormatosV2(): Promise<EntityFormatos[]> {
        return await this.dbLocal.selectAllStore('CT_v2_Formato');
    }

    public async getPersonnelLastRegister(): Promise<EntitySelectPersonal[]> {
        const result: EntityAutochequeo | undefined  = (await this.getAutochequeo()).filter(row => ![-2, -1].includes(row.Estado.IdEstado) && row.Momento.IdMomento === 3).pop();
        if (!result) return [];
        
        let identifierPersonnel = result.Personal.map(row => row.Identificacion);
        let filter = AdapterData.personal.filter(row => identifierPersonnel.some(psl => psl === row.Identificacion));
        return (AdapterGenerico.formatoPersonalizadoSelect(filter, 'Identificacion', ['Nombres', 'Apellidos']) as EntitySelectPersonal[]);
    }
}

export class RepositoryImplMain extends RepositoryCollections implements RepositoryMain {
    public async save(params: DtoRequestForm, modeSave: 1 | 2, modeForm: KeyModule): Promise<EntityAutochequeo | null> {
        try {
            let response = await this._save(params, modeSave, modeForm);
            let rowLocal = await this._saveLocal(params, modeSave, modeForm);

            await this.dbLocal.deleteByIndexStore({ nameStore: 'Autochequeo', value: rowLocal._id });
            await this.dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: DtoRequestForm, modeSave: 1 | 2, modeForm: KeyModule): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }

        let response = { ...params.autochequeo, modeSave, Estado: StatusOffline, modeForm, dataSend: params, guest: modeForm === 'CPTGuest'};
        if (!!response._id) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: response });
        return response;
    }

    private async _save(params: DtoRequestForm, modeSave: 1 | 2, modeForm: KeyModule): Promise<EntityAutochequeo | null> {
        const url = `${this.urlBase}${AdapterConfigure.SAVE_LISTAAUTOCHEQUEO}`
        if (!new AdapterCheckConnection().isStable()) { this.dispatch(addCountProcess()); return null; }

        const formData = new FormData();
        params.Files.map(file => formData.append("File", file.value, file.name));
        if (modeSave === 1) {
            const { _id, ...rest } = params.autochequeo;
            formData.append('AUTOCHEQUEO', JSON.stringify(rest));
        } else {
            formData.append('AUTOCHEQUEO', JSON.stringify(params.autochequeo));
        }
        formData.append('CORREO', JSON.stringify(params.correo));
        formData.append('DOCUMENTACION', JSON.stringify(params.documentacion));

        if (modeForm === 'CPTGuest') formData.append('Guest', "true");

        const response = await this.service.call<any>("POST", url, formData, "bearer", "form", 'json', {}, 0);
        if (Array.isArray(response)) {
            if (response.length > 0) return { ...response[0], guest: modeForm === 'CPTGuest' }
            return null;
        }

        return response.Data ? {...response.Data, guest: modeForm === 'CPTGuest' } : null;
    }

    public async formatterSave(data: EntityDataForm, user: EntityDataUsuario, questions: EntityLoadQuestion, moduleForm: EntityModuleForm, opcionAudio: EntitySelectBase<EntityOpcionAudio>[], generateId: string): Promise<DtoRequestForm> {
        const { Files, autochequeo, emails, questionForEmail } = await this.formatterFormAutochequeo(data, user, questions, moduleForm, opcionAudio, generateId);
        const correo = this.formatterEmail(questionForEmail, emails, user);
        const documentacion = this.formatterDocumentation(autochequeo, user, moduleForm);

        return ({
            autochequeo,
            correo: autochequeo.ResultadoPreguntas === 1 ? correo : {},
            documentacion,
            Files
        })
    }

    public async formatterSaveCCP(data: any, user: EntityDataUsuario, id: string, questions: EntityLoadQuestion): Promise<DtoRequestForm> {
        const response: any = await this.getItemAutochequeo(id);
        if (response === null) return ({} as DtoRequestForm);

        let FechaTemp = AdapterGenerico.getFechaISO_Local();
        const { ArrayFotosCP, EstadoCP, EstadoRevision, GrupoPreguntasCP, ObservacionCP, ResultadoCierreCP, allFiles } = this.formatterFormCierreControlPreventivo(data, user, questions);
        const FechaCierreCP = {
            IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
            Fecha: FechaTemp,
            TimeZone: AdapterGenerico.getTimeZoneOffSet()
        }

        if (response.Estado.IdEstado === -1) {
            let dataSend = response.dataSend as DtoRequestForm;
            const { _id, ...rest } = dataSend.autochequeo;
            let _autochequeo = dataSend.autochequeo.Procesos.Registrar.IdFecha ? dataSend.autochequeo : rest;

            return ({
                autochequeo: {
                    ..._autochequeo,
                    ArrayFotosCP,
                    EstadoCP,
                    EstadoRevision,
                    GrupoPreguntasCP,
                    ObservacionCP,
                    ResultadoCierreCP,
                    FechaCierreCP
                },
                correo: dataSend.correo,
                documentacion: dataSend.documentacion,
                Files: ([] as any[]).concat(dataSend.Files, allFiles)
            })
        }

        return ({
            autochequeo: {
                ...response,
                ArrayFotosCP,
                EstadoCP,
                EstadoRevision,
                GrupoPreguntasCP,
                ObservacionCP,
                ResultadoCierreCP,
                FechaCierreCP
            },
            correo: {},
            documentacion: {} as any,
            Files: allFiles
        })
    }

    public async formatterSaveCP(data: EntityAutochequeo, formValue: EntityDataForm, user: EntityDataUsuario, question: EntityLoadQuestion, generateId: string) {
        const allFiles: { name: string; value: any }[] = [];
        const questionForEmail: EntityQuestionEmail[] = [];
        const emails = this.recolectEmails(formValue, user.Email);
        const OT: EntityOTs = formValue.OT.dataComplete;

        let FechaTemp = AdapterGenerico.getFechaISO_Local();
        let ResultadoPreguntas = 0, ResultadoGravedad = 0, ResultadoRiesgosEspeciales = 0;

        let EstadoCP = { Estado: '', IdEstado: 0 };
        let geolocation = { lat: '', lng: '' };
        let GrupoPreguntas: any[] = [];
        let Idioma = LanguageApp();
        let CharlaPreTarea: any = data.CharlaPreTarea || {
            PersonalResponsableTrabajo: {
                _id: '',
                IdPersonal: 0,
                Identificacion: '',
                Apellidos: '',
                Nombres: '',
                Cargo: ''
            },
            RecursoPreventivo: [] as {
                IdPersonal: 0,
                Identificacion: '',
                Apellidos: '',
                Nombres: '',
                Cargo: ''
            }[],
            riesgos: [] as any[],
            medidasPreventivas: [] as any[],
            epcs: [] as any[],
            opcionAudio: [] as any[]
        }

        // Plantilla de Grupo Preguntas
        let countImages = 0;
        Object.keys(formValue.grupoPreguntas).forEach((key: string, index) => {
            const valueGroupQuestions = formValue.grupoPreguntas[key] || {};
            const valueTitle = formValue.grupoTitulos[key] || {};
            const titleSelected = question.AutochequeoTitulos[index];
            let ResRiesgosEspecialesGrupo = 0, ResultadoGrupo = 0;

            // Plantilla para las fotos de grupo
            const FotoGrupo = (formValue.grupoTitulos[key]?.images || []).map((file: { type: string; valueToShow: string; value: any; }, _indexFile: number) => {
                countImages +=1;
                const formattedNameFile = `C_${user.IdUsuario}_${countImages}${index}${_indexFile}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
                allFiles.push({ name: formattedNameFile, value: file.value.file });
                return formattedNameFile;
            });

            // Plantilla de Preguntas
            const Preguntas = Object.keys(valueGroupQuestions).map((idRegistroQuestion: string, indexPregunta) => {
                const itemQuest = question.AutochequeoItems.find((qst) => qst.IdRegistro === parseInt(idRegistroQuestion));
                if (!itemQuest) return {};
                const disponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Disponibilidad || '[]');
                const estadoEquipos: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Estados || '[]');
                const valueForQuestion = valueGroupQuestions[idRegistroQuestion] || {};

                const disponibilidadSelected = disponibilidad.find(dsp => dsp.Respuesta === valueForQuestion.respuesta || '');
                const estadoEquiposSelected = estadoEquipos.find(dsp => dsp.Respuesta === valueForQuestion.estadoEquipo || '');

                let ResRiesgosEspecialesItem = 'NO';
                let ResultadoItem = 0;

                if (disponibilidadSelected?.EnviarCorreo === 1 || disponibilidadSelected?.DevolverOrden === 1 || estadoEquiposSelected?.EnviarCorreo === 1 || estadoEquiposSelected?.DevolverOrden === 1) {
                    ResultadoItem = 1; // resultado incorrecto
                    ResultadoGrupo = 1; // resultado incorrecto
                    ResultadoPreguntas = 1; // resultado incorrecto
                    ResultadoGravedad = 1; // resultado incorrecto

                    // registros que se envían al correo
                    const questionIndexFinded = questionForEmail.findIndex(qst => qst.idGroup === titleSelected.IdTitulo);
                    const newQuest = { respuesta: valueForQuestion.respuesta || '', status: valueForQuestion.estadoEquipo || '', quest: `${indexPregunta + 1}. ${itemQuest.Item}` }
                    if (questionIndexFinded === -1) {
                        questionForEmail.push({
                            group: titleSelected.Titulo,
                            idGroup: titleSelected.IdTitulo,
                            observacionGrupal: valueTitle?.observacion || '',
                            quest: [newQuest]
                        });
                    } else {
                        questionForEmail[questionIndexFinded].quest.push(newQuest);
                    }
                }

                if (disponibilidadSelected?.RiesgosEspeciales === "SI" || estadoEquiposSelected?.RiesgosEspeciales === "SI") {
                    ResRiesgosEspecialesGrupo = 1;
                    ResultadoRiesgosEspeciales = 1;
                    ResRiesgosEspecialesItem = 'SI';
                }

                valueForQuestion.personal?.forEach((psl: EntitySelectPersonal) => {
                    if (psl.dataComplete.CorreoElectronico) emails.push(psl.dataComplete.CorreoElectronico);
                })

                const NombreFotos = (valueForQuestion.images || []).map((file: { type: string; valueToShow: string; value: any; }, _indexImage: number) => {
                    countImages += 1;
                    const formattedNameFile = `C_${user.IdUsuario}_${countImages}${index}${_indexImage}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
                    allFiles.push({ name: formattedNameFile, value: file.value.file });
                    return formattedNameFile;
                })

                return {
                    IdPregunta: itemQuest?.IdRegistro,
                    Item: itemQuest.Item,
                    Help: itemQuest.Help,
                    ResDisponibilidad: valueForQuestion.respuesta || '',
                    ResEstado: valueForQuestion.estadoEquipo || '',
                    ResRiesgosEspeciales: ResRiesgosEspecialesItem,
                    ObservacionIngresada: valueForQuestion.observacion || '',
                    NombreFotos,
                    Resultado: ResultadoItem,
                    CharlaPretarea: disponibilidadSelected?.CharlaPreTarea || 0,
                    IdPadre: disponibilidadSelected?.IdHijo || 0,
                    IdArrayPadres: titleSelected.adittionalIdTitle || []
                };
            })

            // Asignar si tiene riesgos especiales
            
            const tipologiaSelected = formValue.Tipologia.find(row => row.IdTipologia === titleSelected.Tipologia.IdTipologia)
            if (tipologiaSelected && tipologiaSelected.RiesgosEspeciales === 1) {
                ResRiesgosEspecialesGrupo = 1;
                ResultadoRiesgosEspeciales = 1;
            }

            // Plantilla grupo de preguntas
            const templateItemQuest = {
                IdTipologia: titleSelected.Tipologia.IdTipologia,
                Tipologia: titleSelected.Tipologia.TipologíaTrabajo,
                IdTitulo: titleSelected.IdTitulo,
                Titulo: titleSelected.Titulo,
                FotoGrupo,
                ObservacionGrupo: valueTitle?.observacion || '',
                ResultadoGrupo,
                ResRiesgosEspeciales: ResRiesgosEspecialesGrupo,
                TotalPreguntas: Preguntas.length,
                Preguntas
            }

            GrupoPreguntas.push(templateItemQuest)
        })
        
        // Si la OT tiene CONTROL PREVENTIVO
        if ((["si"].includes(OT.CierreCP.toLocaleLowerCase()) || !!formValue.Delegacion.dataComplete.CierreCP) && question.cierreControlPreventivoHasQuestion)
            EstadoCP = { Estado: 'CP PENDIENTE', IdEstado: 10 };
        
        // Guardar la geolocalización
        await AdapterGeolocation.getLocation().then((res) =>
            (geolocation = { lat: `${res.latitud}`, lng: `${res.longitud}` })
        ).catch((reason) => { });
        
        const autochequeo: EntityAutochequeo = {
            Id: 0,
            IdAppLocal: `${user.Identificacion}_${generateId}`,
            IdControlPreventivo: data.Id || data._id, // se guarda el _id solo para los registros offline, ya que no cuenta con el Id del registro padre asignado por la BD
            CharlaPreTarea,
            Momento: { IdMomento: 2, Momento: 'Control Preventivo' },
            EstadoCP,
            Idioma,
            DatosRegistro: {
                Latitud: geolocation.lat,
                Longitud: geolocation.lng
            },
            GrupoPreguntas: [data.GrupoPreguntas[0], ...GrupoPreguntas],
            ResultadoPreguntas,
            ResultadoGravedad,
            ResultadoRiesgosEspeciales,
            Tecnico: data.Tecnico,
            Personal: data.Personal,
            Archivos: data.Archivos,
            Empresa: data.Empresa,
            Pais: data.Pais,
            IdDelegacion: data.IdDelegacion,
            Delegacion: data.Delegacion,
            IdActividad: data.IdActividad,
            Actividad: data.Actividad,
            EmpresaSeleccionada: data.EmpresaSeleccionada,
            ContrataSeleccionada: data.ContrataSeleccionada,
            JefeObraRecurso: data.JefeObraRecurso,
            Obra: data.Obra,
            Lugar: formValue.Lugar,
            TrabajoaRealizar: data.TrabajoaRealizar,
            RecursoPreventivo: data.RecursoPreventivo,
            Cliente: data.Cliente,
            Area: data.Area,
            OT: data.OT,
            OrdenServicio: formValue?.OrdenServicio || "",
            DatosApp: data.DatosApp,
            FechaRegistro: {
                IdFecha: formValue?.FechaRegistro?.IdFecha || AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                FechaRegistro: formValue?.FechaRegistro?.Fecha || FechaTemp,
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            Procesos: data.Procesos,
            Estado: { Estado: "ACTIVO", IdEstado: 1 },
            ArrayFotosCP: [],
            ResultadoCierreCP: 0,
            Grupo: {
                IdGrupo: OT.Grupo.IdGrupo,
                Grupo: OT.Grupo.Grupo,
            }
        }

        const correo = this.formatterEmail(questionForEmail, emails, user);
        const documentacion = this.formatterDocumentation(autochequeo, user, { key: 'CP', title: '' });

        return ({
            autochequeo,
            correo: autochequeo.ResultadoPreguntas === 1 ? correo : {},
            documentacion,
            Files: allFiles
        })
    }

    private formatterFormCierreControlPreventivo(data: any, user: EntityDataUsuario, questions: EntityLoadQuestion) {
        const allFiles: { name: string; value: any; }[] = [];
        let ResultadoCierreCP = 0;
        const questionForEmail: EntityQuestionEmail[] = [
            {
                group: 'Cierre Control Preventivo',
                idGroup: 0,
                observacionGrupal: '',
                quest: [] as any[]
            }
        ];

        const valueGroupQuestions = data.grupoPreguntas[-2] || {};
        const valueGroupTitle = data.grupoTitulos[-2] || {};
        let countImages = 0;
        const ArrayFotosCP: string[] = (valueGroupTitle.images || []).map((file: { type: string; valueToShow: string; value: any; }, index: number) => {
            countImages++;
            const formattedNameFile = `C_${user.IdUsuario}_${countImages}${index}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
            allFiles.push({ name: formattedNameFile, value: file.value.file })
            return formattedNameFile;
        })

        const preguntasRespuestasUnicas = {
            Item: 'Preguntas únicas',
            Help: '',
            Opciones: [] as any[],
            Respuesta: {
                Opcion: '',
                FotoTomada: [],
                inputText: '',
                EsAnomalia: 0
            }
        };
    
        const GrupoPreguntasCP: Array<any> = [];
        Object.keys(valueGroupQuestions).forEach((idRegistroQuestion: string, indexPregunta) => {
            const itemQuest = questions.AutochequeoItems.find((qst) => qst.IdRegistro === parseInt(idRegistroQuestion));
            if (!itemQuest) return {};
            const disponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Disponibilidad || '[]');
            const estadoEquipos: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Estados || '[]');
            const valueForQuestion = valueGroupQuestions[idRegistroQuestion] || {};
            
            const disponibilidadSelected = disponibilidad.find(dsp => (dsp.Respuesta || dsp.Opcion) === valueForQuestion.respuesta || '');
            const estadoEquiposSelected = estadoEquipos.find(dsp => (dsp.Respuesta || dsp.Opcion) === valueForQuestion.estadoEquipo || '');

            let ResultadoItem = 0;

            if (disponibilidadSelected?.EnviarCorreo === 1 || disponibilidadSelected?.DevolverOrden === 1 || estadoEquiposSelected?.EnviarCorreo === 1 || estadoEquiposSelected?.DevolverOrden === 1) {
                ResultadoItem = 1; // resultado incorrecto
                ResultadoCierreCP = 1; // resultado incorrecto

                // registros que se envían al correo
                const newQuest = { respuesta: valueForQuestion.respuesta || '', status: valueForQuestion.estadoEquipo || '', quest: `${indexPregunta + 1}. ${itemQuest.Item}` }
                questionForEmail[0].quest.push(newQuest);
            }

            const NombreFotos = (valueForQuestion.images || []).map((file: { type: string; valueToShow: string; value: any; }, _indexFile: number) => {
                const formattedNameFile = `C_${user.IdUsuario}_${countImages}${indexPregunta}${_indexFile}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
                countImages++;
                allFiles.push({ name: formattedNameFile, value: file.value.file })
                return formattedNameFile;
            })

            // RespuestaHard: solo si es de una opción para el CCP
            const isOneOption = (disponibilidad.some(row => ['SI', 'NO', 'NA'].includes((row.Opcion || row.Respuesta || '').toLocaleUpperCase())));
            if (isOneOption) {
                preguntasRespuestasUnicas.Opciones.push({
                    Opcion: itemQuest.Item || '',
                    Observacion: 0,
                    CampoObservacion: '', //valueForQuestion.observacion || '',
                    inputText: valueForQuestion.observacion || '',
                    Foto: 0,
                    FotoTomada: NombreFotos,
                    EnviarCorreo: 0,
                    EsAnomalia: ResultadoItem,
                    RespuestaHard: valueForQuestion.respuesta
                })
                return;
            }
            
            GrupoPreguntasCP.push({
                Item: itemQuest.Item,
                Help: itemQuest.Help,
                Opciones: disponibilidad.map(dsp => ({
                    Opcion: (dsp.Opcion || dsp.Respuesta),
                    Observacion: dsp.Observacion || 0,
                    CampoObservacion: dsp.CampoObservacion || '',
                    inputText: ((dsp.Opcion || dsp.Respuesta) === valueForQuestion.respuesta) ? valueForQuestion.observacion || '' : '',
                    Foto: dsp.Foto || 0,
                    FotoTomada: [],
                    EnviarCorreo: dsp.EnviarCorreo || 0,
                    EsAnomalia: 0
                })),
                Respuesta: {
                    Opcion: valueForQuestion.respuesta || '',
                    FotoTomada: NombreFotos,
                    inputText: valueForQuestion.observacion || '',
                    EsAnomalia: ResultadoItem
                }
            });
        })

        GrupoPreguntasCP.push(preguntasRespuestasUnicas);

        return {
            GrupoPreguntasCP,
            ArrayFotosCP,
            ObservacionCP: valueGroupTitle.observacion,
            ResultadoCierreCP,
            EstadoCP: { Estado: 'CP CERRADO', IdEstado: 1 },
            EstadoRevision: { Estado: 'INCOMPLETO', IdEstado: 1 },
            allFiles,
            questionForEmail
        }
    }

    private async formatterFormAutochequeo(data: EntityDataForm, user: EntityDataUsuario, questions: EntityLoadQuestion, moduleForm: EntityModuleForm, opcionAudioData: EntitySelectBase<EntityOpcionAudio>[], generateId: string): Promise<{ autochequeo: EntityAutochequeo; Files: any[]; emails: string[]; questionForEmail: EntityQuestionEmail[]; }> {
        const allFiles: { name: string; value: any }[] = [];
        const questionForEmail: EntityQuestionEmail[] = [];
        const OT: EntityOTs = data.OT?.dataComplete;
        const emails = this.recolectEmails(data, user.Email);
        const Momento = moduleForm.key === 'AUT' ? { IdMomento: 1, Momento: 'Lista Autochequeo' } : { IdMomento: 3, Momento: 'Charla Pretarea' };
        let EstadoCP = moduleForm.key === 'AUT' ? {} : { Estado: '', IdEstado: 0 };
        const IdControlPreventivo = 0;
        let ResultadoPreguntas = 0, ResultadoGravedad = 0, ResultadoRiesgosEspeciales = 0;
        let FechaTemp = AdapterGenerico.getFechaISO_Local();
        let GrupoPreguntas: any[] = [];
        let Personal: any[] = [];
        let NombreAudio = '';
        let TiempoAudio = '';
        let Idioma = LanguageApp();
        let countImages = 0;
        let geolocation = { lat: '', lng: '' };
        let CharlaPreTarea = {
            PersonalResponsableTrabajo: {
                _id: '',
                IdPersonal: 0,
                Identificacion: '',
                Apellidos: '',
                Nombres: '',
                Cargo: ''
            },
            RecursoPreventivo: [] as {
                IdPersonal: 0,
                Identificacion: '',
                Apellidos: '',
                Nombres: '',
                Cargo: ''
            }[],
            riesgos: [] as any[],
            medidasPreventivas: [] as any[],
            epcs: [] as any[],
            opcionAudio: [] as any[]
        }


        // Plantilla de Grupo Preguntas
        Object.keys(data.grupoPreguntas).forEach((key: string, index) => {
            const valueGroupQuestions = data.grupoPreguntas[key] || {};
            const valueTitle = data.grupoTitulos[key] || {};
            const titleSelected = questions.AutochequeoTitulos[index];
            let ResRiesgosEspecialesGrupo = 0, ResultadoGrupo = 0;

            // Plantilla para las fotos de grupo
            const FotoGrupo = (data.grupoTitulos[key]?.images || []).map((file: { type: string; valueToShow: string; value: any; }, _indexFile: number) => {
                countImages++;
                const formattedNameFile = `L_${user.IdUsuario}_${countImages}${index}${_indexFile}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
                allFiles.push({ name: formattedNameFile, value: file.value.file });
                return formattedNameFile;
            });

            // Plantilla de Preguntas
            const Preguntas = (Object.keys(valueGroupQuestions) || []).map((idRegistroQuestion: string, indexPregunta) => {
                const itemQuest = questions.AutochequeoItems.find((qst) => qst.IdRegistro === parseInt(idRegistroQuestion) || qst.IdRegistro === idRegistroQuestion as any);
                if (!itemQuest) return {};

                const disponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Disponibilidad || '[]');
                const estadoEquipos: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(itemQuest.Estados || '[]');
                const valueForQuestion = valueGroupQuestions[idRegistroQuestion] || {};

                const disponibilidadSelected = disponibilidad.find(dsp => dsp.Respuesta === valueForQuestion.respuesta || '');
                const estadoEquiposSelected = estadoEquipos.find(dsp => dsp.Respuesta === valueForQuestion.estadoEquipo || '');

                let ResRiesgosEspecialesItem = 'NO';
                let NombreImageFirma = '';
                let ResultadoItem = 0;

                if (disponibilidadSelected?.EnviarCorreo === 1 || disponibilidadSelected?.DevolverOrden === 1 || estadoEquiposSelected?.EnviarCorreo === 1 || estadoEquiposSelected?.DevolverOrden === 1) {
                    ResultadoItem = 1; // resultado incorrecto
                    ResultadoGrupo = 1; // resultado incorrecto
                    ResultadoPreguntas = 1; // resultado incorrecto
                    ResultadoGravedad = 1; // resultado incorrecto

                    // registros que se envían al correo
                    const questionIndexFinded = questionForEmail.findIndex(qst => qst.idGroup === titleSelected.IdTitulo);
                    const newQuest = { respuesta: valueForQuestion.respuesta || '', status: valueForQuestion.estadoEquipo || '', quest: `${indexPregunta + 1}. ${itemQuest.Item}` }
                    if (questionIndexFinded === -1) {
                        questionForEmail.push({
                            group: titleSelected.Titulo,
                            idGroup: titleSelected.IdTitulo,
                            observacionGrupal: valueTitle?.observacion || '',
                            quest: [newQuest]
                        });
                    } else {
                        questionForEmail[questionIndexFinded].quest.push(newQuest);
                    }
                }

                if (disponibilidadSelected?.RiesgosEspeciales === "SI" || estadoEquiposSelected?.RiesgosEspeciales === "SI") {
                    ResRiesgosEspecialesGrupo = 1;
                    ResultadoRiesgosEspeciales = 1;
                    ResRiesgosEspecialesItem = 'SI';
                }

                if (valueForQuestion.PersonalResponsableTrabajo) {
                    CharlaPreTarea.PersonalResponsableTrabajo = {
                        _id: valueForQuestion.PersonalResponsableTrabajo.dataComplete._id || '',
                        IdPersonal: valueForQuestion.PersonalResponsableTrabajo.dataComplete.IdPersonal || 0,
                        Identificacion: valueForQuestion.PersonalResponsableTrabajo.dataComplete.Identificacion || '',
                        Apellidos: valueForQuestion.PersonalResponsableTrabajo.dataComplete.Apellidos || '',
                        Nombres: valueForQuestion.PersonalResponsableTrabajo.dataComplete.Nombres || '',
                        Cargo: valueForQuestion.PersonalResponsableTrabajo.dataComplete.Cargo || ''
                    }
                }

                if (valueForQuestion.riesgos) {
                    Object.values(valueForQuestion.riesgos).forEach((_row: any) => {
                        CharlaPreTarea.riesgos.push({
                            IdTipoRiesgo: _row.dataComplete.IdTipoRiesgo,
                            codigo: _row.dataComplete.codigo,
                            iteminIdioma: _row.dataComplete.iteminIdioma,
                            value: _row.value,
                            otroRiesgo: (_row.value === "Si" && _row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtrosRiesgos) ? valueForQuestion.otroRiesgo : ''
                        });
                    })
                }

                if (valueForQuestion.opcionAudio) {
                    const formatedOpcionAudio = opcionAudioData.map(row => {
                        const valueChecked = !!valueForQuestion.opcionAudio[row.dataComplete.codigo];
                        return ({
                            IdOpcionAudio: row.dataComplete.IdOpcionAudio,
                            iteminIdioma: row.dataComplete.iteminIdioma, 
                            codigo: row.dataComplete.codigo,
                            value: valueChecked,
                        })
                    });
                    CharlaPreTarea.opcionAudio = formatedOpcionAudio;
                }

                if (valueForQuestion.medidasPreventivas) {
                    valueForQuestion.medidasPreventivas.forEach((_row: any) => {
                        CharlaPreTarea.medidasPreventivas.push({
                            IdMedidasPreventivas: _row.dataComplete.IdMedidasPreventivas,
                            codigo: _row.dataComplete.codigo,
                            iteminIdioma: _row.dataComplete.iteminIdioma,
                            tipoRiesgo: _row.dataComplete.tipoRiesgo,
                            otroMedidasPreventivas: _row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroMedidasPreventivas ? valueForQuestion.otroMedidasPreventivas : ''
                        })
                    })
                }

                if (valueForQuestion.epcs) {
                    valueForQuestion.epcs.forEach((_row: any) => {
                        CharlaPreTarea.epcs.push({
                            IdEPCS: _row.dataComplete.IdEPCS,
                            codigo: _row.dataComplete.codigo,
                            iteminIdioma: _row.dataComplete.iteminIdioma,
                            tipoRiesgo: _row.dataComplete.tipoRiesgo,
                            otroEPCS: _row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroEpcs ? valueForQuestion.otroEPCS : ''
                        })
                    })
                }

                if (valueForQuestion.RecursoPreventivo) {
                    CharlaPreTarea.RecursoPreventivo = Array.isArray(valueForQuestion.RecursoPreventivo) ? 
                    valueForQuestion.RecursoPreventivo.map((row: EntitySelectBase<any>) => ({
                        _id: row.dataComplete._id,
                        IdPersonal: row.dataComplete?.IdPersonal || 0,
                        Identificacion: row.dataComplete.Identificacion || '',
                        Apellidos: row.dataComplete.Apellidos,
                        Nombres: row.dataComplete.Nombres,
                        Cargo: row.dataComplete.Cargo || ''
                    }))
                    : [{
                        _id: valueForQuestion.RecursoPreventivo.dataComplete._id,
                        IdPersonal: valueForQuestion.RecursoPreventivo.dataComplete.IdPersonal || 0,
                        Identificacion: valueForQuestion.RecursoPreventivo.dataComplete.Identificacion || '',
                        Apellidos: valueForQuestion.RecursoPreventivo.dataComplete.Apellidos || '',
                        Nombres: valueForQuestion.RecursoPreventivo.dataComplete.Nombres || '',
                        Cargo: valueForQuestion.RecursoPreventivo.dataComplete.Cargo || ''
                    }]
                }

                if (valueForQuestion.charlaPreTarea) {
                    const formattedNameFile = `A_${user.IdUsuario}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.mp3`;
                    NombreAudio = formattedNameFile;
                    allFiles.push({ name: formattedNameFile, value: valueForQuestion.charlaPreTarea })
                }


                valueForQuestion.personal?.forEach((psl: EntitySelectPersonal) => {
                    if (psl.dataComplete.CorreoElectronico) emails.push(psl.dataComplete.CorreoElectronico);
                    Personal.push({
                        _id: psl.dataComplete._id || '',
                        IdPersonal: psl.dataComplete.IdPersonal || 0,
                        Identificacion: psl.dataComplete.Identificacion || '',
                        Apellidos: psl.dataComplete.Apellidos || '',
                        Nombres: psl.dataComplete.Nombres || '',
                        Cargo: psl.dataComplete.Cargo || ''
                    })
                })

                const NombreFotos = (valueForQuestion.images || []).map((file: { type: string; valueToShow: string; value: any; }, _indexFile: number) => {
                    countImages++;
                    const formattedNameFile = `L_${user.IdUsuario}_${countImages}${indexPregunta}${_indexFile}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(file.value.path as string).split('.').pop()}`;
                    allFiles.push({ name: formattedNameFile, value: file.value.file });
                    return formattedNameFile;
                })

                if (valueForQuestion.firma) {
                    countImages++;
                    const formattedNameFile = `L_${user.IdUsuario}_${countImages}${indexPregunta}_${AdapterGenerico.formatoParaGuardarImagenesV2()}.${(valueForQuestion.firma.name as string).split('.').pop()}`;
                    allFiles.push({ name: formattedNameFile, value: valueForQuestion.firma});
                    NombreImageFirma = formattedNameFile;
                }

                return {
                    IdPregunta: itemQuest?.IdRegistro,
                    Item: itemQuest.Item,
                    Help: itemQuest.Help,
                    ResDisponibilidad: valueForQuestion.respuesta || '',
                    ResEstado: valueForQuestion.estadoEquipo || '',
                    ResRiesgosEspeciales: ResRiesgosEspecialesItem,
                    ObservacionIngresada: valueForQuestion.observacion || '',
                    NombreFotos,
                    NombreImageFirma: NombreImageFirma,
                    Resultado: ResultadoItem,
                    CharlaPretarea: disponibilidadSelected?.CharlaPreTarea || 0,
                    IdPadre: disponibilidadSelected?.IdHijo || 0,
                    TypeInput: disponibilidadSelected?.TypeInput || itemQuest.TypeInput || '' ,
                    otroRiesgo: valueForQuestion.otroRiesgo,
                    otroEPCS: valueForQuestion.otroEPCS,
                    otroMedidasPreventivas: valueForQuestion.otroMedidasPreventivas
                };
            })

            // Plantilla grupo de preguntas
            const templateItemQuest = {
                IdTipologia: titleSelected.Tipologia.IdTipologia,
                Tipologia: titleSelected.Tipologia.TipologíaTrabajo,
                IdTitulo: titleSelected.IdTitulo,
                Titulo: titleSelected.Titulo,
                FotoGrupo,
                ObservacionGrupo: valueTitle?.observacion || '',
                ResultadoGrupo,
                ResRiesgosEspeciales: ResRiesgosEspecialesGrupo,
                TotalPreguntas: Preguntas.length,
                Preguntas
            }

            GrupoPreguntas.push(templateItemQuest)
        })

        // Si la OT tiene CONTROL PREVENTIVO ó Delegación tiene CierreCP -- Solo para CHPT - LA no contiene Cierre
        if ((["si"].includes(OT?.CierreCP?.toLocaleLowerCase()) || !!data.Delegacion?.dataComplete?.CierreCP) && questions.cierreControlPreventivoHasQuestion)
            EstadoCP = { Estado: 'CP PENDIENTE', IdEstado: 10 };

        const audioData = allFiles.find(row => row.name === NombreAudio)?.value;
        if (audioData) TiempoAudio = await AdapterGenerico.getTimeFromAudio(audioData) as any;

        await AdapterGeolocation.getLocation().then((res) =>
            (geolocation = { lat: `${res.latitud}`, lng: `${res.longitud}` })
        ).catch((reason) => { });

        const autochequeoForm: any = {
            Id: 0,
            IdAppLocal: `${user.Identificacion}_${generateId}`,
            IdControlPreventivo,
            Tecnico: {
                _id: data.UsuarioRegistraGuest?.dataComplete._id || user._id,
                IdPersonal: user.IdUsuario,
                Codigo: data.UsuarioRegistraGuest?.dataComplete.Codigo || user.DatosPersonal[0]?.Codigo || "",
                Identificacion: user.Identificacion,
                Apellidos: data.UsuarioRegistraGuest?.dataComplete.Apellidos || `${user.ApellidoPaterno} ${user.ApellidoMaterno}`,
                Nombres: data.UsuarioRegistraGuest?.dataComplete.Nombres || user.Nombres,
            },
            Personal,
            CharlaPreTarea,
            Archivos: NombreAudio,
            TiempoAudio,
            Empresa: {
                NroDocumento: data.Empresa?.dataComplete.NroDocumento || "",
                RazonSocial: data.Empresa?.dataComplete.RazonSocial || ""
            },
            Pais: {
                IdPais: data.Pais?.dataComplete?.IdPais || 0,
                Pais: data.Pais?.dataComplete?.Pais || ""
            },
            IdDelegacion: data.Delegacion?.dataComplete.IdDelegacion || data.OTGuest?.dataComplete.IdDelegacion || 0,
            Delegacion: data.Delegacion?.dataComplete.Delegacion || '',
            IdActividad: data.Actividad?.dataComplete?.IdActividad || data.OTGuest?.dataComplete.IdActividad || 0,
            Actividad: data.Actividad?.dataComplete?.Actividad || '',
            EmpresaSeleccionada: {
                CodigoEmpresa: data.Empresa?.dataComplete.Codigo || '',
                IdEmpresa: data.Empresa?.dataComplete.IdEmpresa || 0,
                Nombre: data.Empresa?.dataComplete.Nombre || '',
                NroDocumento: data.Empresa?.dataComplete.NroDocumento || '',
                RazonSocial: data.Empresa?.dataComplete.RazonSocial || '',
                TipoDocumento: data.Empresa?.dataComplete.TipoDocumento?.TipoDocumento || ''
            },
            ContrataSeleccionada: (data.SubContrata?.length > 0 ? (data.SubContrata || []) : (data.SubContrataGuest || [])).map((row: any) => ({
                _id: row?.dataComplete?._id || '',
                CodigoEmpresa: row?.dataComplete?.Codigo || '',
                IdEmpresa: row?.dataComplete?.IdEmpresa || 0,
                Nombre: row?.dataComplete?.Nombre || '',
                NroDocumento: row?.dataComplete?.NroDocumento || '',
                RazonSocial: row?.dataComplete?.RazonSocial || '',
                TipoDocumento: row?.dataComplete?.TipoDocumento?.TipoDocumento || ''
            })),
            JefeObraRecurso: {
                _id: data.JefeObraGuest?.dataComplete._id || '',
                Identificacion: data.JefeObra?.dataComplete.Identificacion || '',
                apellidos: data.JefeObra?.dataComplete.Apellidos || data.JefeObraGuest?.dataComplete.Apellidos || '',
                codigo: data.JefeObra?.dataComplete.Codigo || data.JefeObraGuest?.dataComplete.Codigo || '',
                nombres: data.JefeObra?.dataComplete.Nombres  || data.JefeObraGuest?.dataComplete.Nombres || ''
            },
            Obra: data.Obra,
            Lugar: data.Lugar,
            TrabajoaRealizar: data.TrabajoRealizar,
            RecursoPreventivo: (data.OT?.dataComplete.Pais.IdPais || data.OTGuest?.dataComplete.IdPais) === 1 ? 
                (data?.RecursoPreventivo || []).map(row => ({
                    _id: row.dataComplete._id || '',
                    IdPersonal: row.dataComplete?.IdPersonal || 0,
                    Identificacion: row.dataComplete?.Identificacion || '',
                    Apellidos: row.dataComplete?.Apellidos || '',
                    Nombres: row.dataComplete?.Nombres || '',
                    Cargo: row.dataComplete?.Cargo || ''
                })) : [] as any[],
            Cliente: {
                IdCliente: data.Cliente?.dataComplete?.IdEmpresa || 0,
                Cliente: data.Cliente?.dataComplete?.Nombre || ''
            },
            Area: {
                IdArea: 0,
                Area: ''
            },
            OT: {
                _id: data.OT?.dataComplete._id || data.OTGuest?.dataComplete._id || '',
                IdOT: data.OT?.dataComplete.IdOT || 0,
                OT: data.OT?.dataComplete.OT || data.OTGuest?.dataComplete.OT || '',
                Codigo: data.OT?.dataComplete?.Codigo  || data.OTGuest?.dataComplete?.Codigo || ''
            },
            Momento,
            OrdenServicio: data?.OrdenServicio || '',
            GrupoPreguntas,
            ResultadoPreguntas,
            ResultadoGravedad,
            ResultadoRiesgosEspeciales,
            Idioma,
            DatosRegistro: {
                Latitud: geolocation.lat,
                Longitud: geolocation.lng
            },
            DatosApp: {
                Origen: `${process.env.REACT_APP_TITLE}`,
                IsOnline: true,
                Latitude: geolocation.lat,
                Longitude: geolocation.lng,
                Modo: `${process.env.REACT_APP_PUBLISH_ENV}`,
                PlayStore: false,
                VersionName: `${process.env.REACT_APP_VERSION_SYSTEM}`,
                IdOrigen: 2
            },
            FechaRegistro: {
                IdFecha: data?.FechaRegistro?.IdFecha || AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                FechaRegistro: data?.FechaRegistro?.Fecha || FechaTemp,
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            Procesos: {
                Registrar: {
                    Fecha: "",
                    DatosUsuario: {
                        ApellidoMaterno: user.ApellidoMaterno,
                        ApellidoPaterno: user.ApellidoMaterno,
                        Nombres: user.Nombres
                    },
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    ...(moduleForm.key === 'CPTGuest' ? { _id: data.UsuarioRegistraGuest.dataComplete?._id || '' } : {})
                },
                Eliminar: {
                    Fecha: "",
                    DatosUsuario: {
                        ApellidoMaterno: "",
                        ApellidoPaterno: "",
                        Nombres: ""
                    },
                    IdUsuario: 0,
                    Usuario: ""
                },
                Modificar: {
                    Fecha: "",
                    DatosUsuario: {
                        ApellidoMaterno: "",
                        ApellidoPaterno: "",
                        Nombres: ""
                    },
                    IdUsuario: 0,
                    Usuario: ""
                }
            },
            Estado: {
                Estado: 'ACTIVO',
                IdEstado: 1
            },
            EstadoCP,
            ArrayFotosCP: [],
            ResultadoCierreCP: 0,
            Grupo: {
                IdGrupo: data.OT?.dataComplete.Grupo.IdGrupo || 0,
                Grupo: data.OT?.dataComplete.Grupo.Grupo || '',
            }
        }

        return ({
            autochequeo: autochequeoForm,
            Files: allFiles,
            emails,
            questionForEmail
        })
    }

    private formatterEmail(list: EntityQuestionEmail[], emails: string[], user: EntityDataUsuario, isControlPreventivo = false) {
        const userNameComplete = `${user.Nombres.toLocaleUpperCase()} ${(user.ApellidoPaterno || '').toLocaleUpperCase()} ${(user.ApellidoMaterno || '').toLocaleUpperCase()}`;
        let templateBase = (message: string) => `<span style='color:#1F497D; font-family: calibri; font-size: 11pt;'> Personal: ${userNameComplete} / DNI: ${user.Identificacion} Tiene las siguientes incidencias: <br> ${message} </span>`

        const result = list.reduce((totalValue, currentValue, currentIndex) => {
            let tempMessage = `
                <br> ${currentValue.group}
                <br> ${currentValue.quest.map((quest) => ` ${quest.quest}${quest.respuesta ? `: Disponibilidad: ${quest.respuesta}` : ''}${quest.status ? `, Estado: ${quest.status}` : ''} `).join(' <br> ')}
                <br> Observación: ${currentValue.observacionGrupal}
                <br>
            `;

            totalValue.groups.push(currentValue.group);
            totalValue.message += tempMessage;
            return totalValue;
        }, { groups: [] as string[], message: '' })

        return {
            Asunto: `Incidencias ${isControlPreventivo ? 'Control Preventivo' : 'Lista Autochequeo'} (revisión de medios)`,
            CorreoElectronico: emails,
            Mensaje: templateBase(result.message)
        }
    }

    private formatterDocumentation(autochequeo: EntityAutochequeo, user: EntityDataUsuario, moduleForm: EntityModuleForm): EntityDocumentation {

        return {
            IdDocumento: 0,
            TipoDocumento: { IdDocumento: 3, Documento: "Notificacion Audio" },
            TituloDocumento: "Notificacion Que debes descargar",
            Contenido: "Descargar Audio",
            TiempoLecturas: 0,
            Tecnico: autochequeo.Tecnico,
            Pais: autochequeo.Pais,
            Delegacion: { IdDelegacion: autochequeo.IdDelegacion, Delegacion: autochequeo.Delegacion },
            Rol: { IdRol: 0, Rol: "" },
            Archivos: [{ "NombreFile": autochequeo.Archivos, Tipo: ".mp3" }],
            Estado: { "IdEstado": 1, "Estado": "ACTIVO" },
            FechaFinal: {
                IdFecha: 0,
                Fecha: null,
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            FechaInicial: {
                IdFecha: 0,
                Fecha: null,
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            FechaRegistro: {
                IdFecha: autochequeo.FechaRegistro?.IdFecha || 0,
                Fecha: autochequeo.FechaRegistro?.FechaRegistro || '',
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            Personal: autochequeo.Personal.map((psl) => ({
                ...(['CPTGuest', 'CPT'].includes(moduleForm.key) ? { _id: psl._id } : {}),
                Nombres: psl.Nombres,
                Apellidos: psl.Apellidos,
                Cargo: psl.Cargo || '',
                Identificacion: psl.Identificacion,
                IdPersonal: psl.IdPersonal
            })),
            Procesos: {
                Registrar: {
                    DatosUsuario: {
                        ApellidoMaterno: user.ApellidoMaterno,
                        ApellidoPaterno: user.ApellidoPaterno,
                        Nombres: user.Nombres
                    },
                    Fecha: '',
                    IdFecha: 0,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    Observacion: '',
                }
            }
        };
    }

    public formatterReadImage(idFecha: number, file: string): string {
        let variable_descarga = `${process.env.REACT_APP_PATH_IMAGES_CP}||${idFecha}||${file}`;
        let path_imagen = btoa(unescape(encodeURIComponent(variable_descarga)));
        return this.urlBase + "/download/Imagen/" + path_imagen;
    }

    private recolectEmails = (data: any, currentEmailUser: string | null) => {
        // const empresa = data.Empresa as EntitySelectEmpresa;
        // const subContrata = data.Empresa as EntitySelectEmpresa;
        // let emails = ([] as string[]).concat(empresa.dataComplete.DatosEmpresa.CorreosElectronicos, subContrata.dataComplete.DatosEmpresa.CorreosElectronicos);
        let emails: string[] = [];
        const jefeObra = data.JefeObra as EntitySelectPersonal;
        const OT = data.OT as EntitySelectOTs;

        if (jefeObra?.dataComplete?.CorreoElectronico) emails.push(jefeObra.dataComplete.CorreoElectronico);
        if (OT?.dataComplete?.Coordinador.Correo) emails.push(OT.dataComplete.Coordinador.Correo);
        if (currentEmailUser) emails.push(currentEmailUser);

        return emails;
    }

    public async getItemAutochequeo(_id: string, consultServer: boolean = false): Promise<EntityAutochequeo | null> {
        let result: EntityAutochequeo | null = await this.dbLocal.selectByIndexStore({ nameStore: 'Autochequeo', value: _id });
        const allNotification = await this.getListNotificationPending();
        if (!result && !consultServer) return null;

        if (consultServer) {
            let url: string = `${this.urlBase}${AdapterConfigure.LIST_CONTROLPREVENTIVO}`;
            const response: EntityAutochequeo[] = await this.service.bgCall<any>("POST", url, JSON.stringify({ "Filtros": [ { "$match": {  _id } }] }), "basic", "json", 'json', {}, 0);
            if (response?.length === 0) return null;
            await this.dbLocal.insertDataStore({ nameStore: 'Autochequeo', data: response });
            result = response[0];
        };

        return ({
            ...result as any,
            isHasNotification: allNotification.some(_row => _row.modulo.some(x => x._id === result?._id))
        })
    }

    public async selfCheckListWasRegistered(): Promise<EntityAutochequeo | undefined> {
        let FechaTemp = AdapterGenerico.getFechaISO_Local();
        const list = (await this.getAutochequeo()).filter(item => item.Estado.IdEstado !== -2 && item.Momento.IdMomento === 1 && item?.Estado?.IdEstado === 1);
        return list.find(item => item.FechaRegistro.FechaRegistro.split("T")[0].toString() === FechaTemp.split("T")[0]);
    }

    public async permissToRegisterControlPreventivo(user: EntityDataUsuario): Promise<EntityAutochequeo | undefined> {
        const list = (await this.getAutochequeo()).filter(item =>
                (item.Estado.IdEstado !== -2 && !item.guest) &&
                (item.Momento.IdMomento === 1 || item.Momento.IdMomento === 3) &&
                (item.Tecnico.IdPersonal === user.IdUsuario)
            );
        let item = list.sort((itemA, itemB) => Number(new Date(itemA.FechaRegistro.FechaRegistro)) - Number(new Date(itemB.FechaRegistro.FechaRegistro))).reverse()[0];
        if (item) return ([3, 1].includes(item?.Momento?.IdMomento) && item?.EstadoCP?.IdEstado === 1) ? undefined : item;

        return undefined;
    }

    public async loadDataGuest(): Promise<EntityLoadDataGuest> {
        const PersonalPorTipo = {
            JefeObra: [] as EntitySelectBase<EntityLitePersonal>[],
            Personal: [] as EntitySelectBase<EntityLitePersonal>[]
        }
        const TIPO_PERSONAL_JEFEOBRA = ["02"];
        const TIPO_EMPRESA_SUBCONTRATA = 2;
        const [liteEmpresa]: [EntityLiteEmpresa[]] = await Promise.all([
            this.dbLocal.selectAllStore('CTLiteEmpresa')
        ]);

        const SubcontrataFormatted = AdapterGenerico.formatoPersonalizadoSelect(liteEmpresa.filter(row => row.IdTipoEmpresa.some(_row => _row === TIPO_EMPRESA_SUBCONTRATA)), '_id', 'Nombre') as any;
        const OTFormatted = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.otLite, '_id', ['Codigo', 'OT'], ' - ') as any[];
        const PersonalFormatted = AdapterData.personalLite.reduce((total, currentValue) => {
            const item = (AdapterGenerico.formatoPersonalizadoSelect(currentValue, '_id', ['Nombres', 'Apellidos'])) as EntitySelectBase<EntityLitePersonal>;
            const CodePerfil = currentValue.CodePerfil;

            if (TIPO_PERSONAL_JEFEOBRA.includes(CodePerfil)) {
                total.JefeObra.push(item);
            }

            total.Personal.push(item);
            return total;
        }, PersonalPorTipo);

        return ({
            OTLite: OTFormatted,
            PersonalLite: PersonalFormatted.Personal,
            PersonalJefeObra: PersonalFormatted.JefeObra,
            SubContrataLite: SubcontrataFormatted,
        })
    }

    public async loadData(user: EntityDataUsuario, permisoVariabls: {
        arrIdPaises: number[];
        arrIdGrupos: number[];
        arrIdDelegaciones: number[];
        arrIdOT: number[];
    }): Promise<EntityLoadData> {

        const TIPO_PERSONAL_JEFEOBRA = ["02"];

        let [Delegacion, Empresa, Actividad, OT]: [EntitySelectBase<EntityDelegacion>[], EntityEmpresa[], EntityActividad[], EntitySelectOTs[]] = await Promise.all([
            this.getDelegacionForRol(user),
            this.dbLocal.selectAllStore('Empresa'),
            this.getTranslateCatalog('Actividad'),
            this.getOtForRol(user),
        ]);

        const Empresas: EntitySelectBase<EntityEmpresa>[] = AdapterGenerico.formatoPersonalizadoSelect(Empresa, 'IdEmpresa', 'RazonSocial') as EntitySelectBase<EntityEmpresa>[];

        const EmpresaFormatted = Empresas.reduce((total, currentValue) => {
            const arrTipoEmpresa = currentValue.dataComplete.TipoEmpresa;

            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === AdapterKeyGeneral.TIPO_EMPRESA)) {
                total.Empresa.push(currentValue);
            }
            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === AdapterKeyGeneral.TIPO_SUBCONTRATA)) {
                total.SubContrata.push(currentValue);
            }
            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === AdapterKeyGeneral.TIPO_CLIENTE)) {
                total.Cliente.push(currentValue);
            }
            return total;
        }, { Empresa: [] as EntitySelectBase<EntityEmpresa>[], SubContrata: [] as EntitySelectBase<EntityEmpresa>[], Cliente: [] as EntitySelectBase<EntityEmpresa>[] });
        
        const PersonalFormatted = AdapterData.personal.reduce((total, currentValue) => {
            const item = (AdapterGenerico.formatoPersonalizadoSelect(currentValue, 'Identificacion', ['Nombres', 'Apellidos'])) as EntitySelectBase<EntityPersonal>;
            const CodePerfil = currentValue.CodePerfil;

            if (TIPO_PERSONAL_JEFEOBRA.includes(CodePerfil)) {
                total.JefeObra.push(item);
            }

            total.Personal.push(item);
            return total;
        }, {
            JefeObra: [] as EntitySelectBase<EntityPersonal>[],
            Personal: [] as EntitySelectBase<EntityPersonal>[]
        });

        const ActividadFormatted = AdapterGenerico.formatoPersonalizadoSelect(Actividad, 'Codigo', 'label') as EntitySelectBase<EntityActividad>[];;

        if (permisoVariabls.arrIdGrupos.length > 0) {
            Delegacion = Delegacion.filter(row => permisoVariabls.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
            EmpresaFormatted.Cliente = EmpresaFormatted.Cliente.filter(row => permisoVariabls.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
            EmpresaFormatted.Empresa = EmpresaFormatted.Empresa.filter(row => permisoVariabls.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
            EmpresaFormatted.SubContrata = EmpresaFormatted.SubContrata.filter(row => permisoVariabls.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
            OT = OT.filter(row => permisoVariabls.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
        }

        return ({
            Delegacion,
            Empresa: EmpresaFormatted.Empresa,
            Subcontrata: EmpresaFormatted.SubContrata,
            Cliente: EmpresaFormatted.Cliente,
            Actividad: ActividadFormatted,
            PersonalJefeObra: PersonalFormatted.JefeObra,
            OT,
            Personal: PersonalFormatted.Personal,
            EPCS: [],
            MedidasPreventivas: [],
            TipoRiesgo: [],
            OpcionAudio: []
        })
    }

    public async autoSave(payload: any, module: KeyModule): Promise<boolean> {
        if (module === 'PREVIEW' || module === 'CCP') return true
        const _payload: EntityFormIncomplete = {
            _id: payload._id,
            dataIncomplete: payload,
            Estado: {
                Estado: "Incompleto",
                IdEstado: -2
            },
            module: ''
        };
        switch(module) {
            case 'AUT':
                _payload.module = 'AUT';
            break;
            case 'CPT':
                _payload.module = 'CPT';
            break;
            case 'CP':
                _payload.module = 'CP';
            break;
            case 'CPTGuest':
                _payload.module = 'CPTGuest';
        }

        await this.dbLocal.updateByIndexStore({ nameStore: 'Autochequeo', value: _payload });

        return true;
    }

    public async verifiedRegisterIncomplete(module: KeyModule): Promise<EntityFormIncomplete | null> {
        const response: EntityAutochequeo[] = await this.dbLocal.selectAllStore('Autochequeo');
        return response.find(row => row.Estado.IdEstado === -2 && row.module === module) as any;
    }

    public async deleteRegisterIncomplete(_id: string): Promise<boolean> {
        await this.dbLocal.deleteByIndexStore({ nameStore: 'Autochequeo', value: _id });
        return true;
    }

    public async reloadEntityPregunta() {
        let result: EntityTipoPregunta[] = [];
        try {
            await new AdapterLoadMaster(this.websocket, this.dbLocal, this.dispatch, this.schema, this.entity).selectCTTipoPregunta({ pais: [], delegacion: [], grupo: [], ot: [] }, {} as any, 'All');
            result = await this.getTipoPreguntas();
        } catch (error) { }

        return result;
    }

    public async reloadPreguntaLA() {
        let result: EntityPreguntaLA[] = [];
        try {
            await new AdapterLoadMaster(this.websocket, this.dbLocal, this.dispatch, this.schema, this.entity).selectCTPreguntaLA({ pais: [], delegacion: [], grupo: [], ot: [] }, {} as any, 'All');
            result = await this.getPreguntasLA();
        } catch (error) { }

        return result;
    }

    public async saveComentario(payload: EntityRequestFormComentario): Promise<EntityAutochequeo[] | null> {
        const url = `${this.urlBase}${AdapterConfigure.SAVE_COMENTARIO}`
        const response: EntityAutochequeo[] | null = await this.service.call<any>("POST", url, JSON.stringify(payload), "bearer", "json", 'json', {}, 0);
        if (response) this.dbLocal.updateByIndexStore({ nameStore: 'Autochequeo', value: response[0] });
        return response;
    }

    public formateSaveComentario(payload: EntityConfigModalComentarios, user: EntityDataUsuario): EntityRequestFormComentario {
        let FechaTemp = AdapterGenerico.getFechaISO_Local();

        return ({
            Id: payload.form.Id,
            Bitacora: {
                Accion: 'Agregando Puntuación (App)',
                Datos: {},
                DatosUsuario: {
                    ApellidoMaterno: user.ApellidoMaterno,
                    ApellidoPaterno: user.ApellidoPaterno,
                    Identificacion: user.Identificacion,
                    IdUsuario: user.IdUsuario,
                    Nombres: user.Nombres,
                    Usuario: user.Usuario
                },
                Fecha: {
                    IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                    Fecha: FechaTemp,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet()
                }
            },
            Procesos: {
                Modificar: {
                    Fecha: FechaTemp,
                    DatosUsuario: {
                        ApellidoMaterno: user.ApellidoMaterno,
                        ApellidoPaterno: user.ApellidoPaterno,
                        Nombres: user.Nombres
                    },
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario
                }
            },
            Puntuacion: [
                ...payload.comentarios,
                {
                    Autor: {
                        ApellidoMaterno: user.ApellidoMaterno,
                        ApellidoPaterno: user.ApellidoPaterno,
                        Apellidos: `${user.ApellidoPaterno} ${user.ApellidoMaterno}`,
                        Identificacion: user.Identificacion,
                        IdUsuario: user.IdUsuario,
                        Nombres: user.Nombres,
                        Usuario: user.Usuario
                    },
                    Comentario: payload.form.Comentario,
                    Puntuacion: payload.form.Puntuacion,
                    Fecha: {
                        IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                        Fecha: FechaTemp,
                        TimeZone: AdapterGenerico.getTimeZoneOffSet()
                    }
                }
            ]
        })
    }

    public async formateSaveFeedback(payload: EntityConfigModalComentarios, user: EntityDataUsuario, value: boolean): Promise<EntityRequestFormFeedback> {
        let FechaTemp = AdapterGenerico.getFechaISO_Local();
        const findCurrentRegister: EntityAutochequeo | null = await this.dbLocal.selectByIndexStore({ nameStore: 'Autochequeo', value: payload.form._id });

        return ({
            _id: payload.form._id,
            Id: payload.form.Id,
            Bitacora: {
                Accion: 'Agregando Puntuación (App)',
                Datos: {},
                DatosUsuario: {
                    ApellidoMaterno: user.ApellidoMaterno,
                    ApellidoPaterno: user.ApellidoPaterno,
                    Identificacion: user.Identificacion,
                    IdUsuario: user.IdUsuario,
                    Nombres: user.Nombres,
                    Usuario: user.Usuario
                },
                Fecha: {
                    IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                    Fecha: FechaTemp,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet()
                }
            },
            Procesos: {
                Modificar: {
                    Fecha: FechaTemp,
                    IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    DatosUsuario: {
                        Apellidos: `${user.ApellidoMaterno} ${user.ApellidoPaterno}`,
                        Identificacion: user.Identificacion,
                        Nombres: `${user.Nombres}`
                    },
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    Observacion: "Agregando Feedback (App)"
                }
            },
            FeedBack: {
                FeedBack: value,
                Personal: {
                    Nombres: user.Nombres,
                    Identificacion: user.Identificacion,
                    IdUsuario: user.IdUsuario,
                    Codigo: user.DatosPersonal[0]?.Codigo || '',
                    Apellidos: `${user.ApellidoPaterno} ${user.ApellidoMaterno}`
                }
            },
            IdControlPreventivo: 0,
            Tecnico: {
                Identificacion: findCurrentRegister?.Tecnico.Identificacion || ''
            }
        })
    }

    public async saveFeedback(payload: EntityRequestFormFeedback): Promise<EntityAutochequeo | null> {
        const url = `${this.urlBase}${AdapterConfigure.SAVE_FEEDBACK}`
        const { _id, ...rest } = payload;
        const response: EntityAutochequeo[] | null = await this.service.call<any>("POST", url, JSON.stringify(rest), "bearer", "json", 'json', {}, 0);
        const updateData = (Array.isArray(response) ? response[0] : response)
        if (response) this.dbLocal.updateByIndexStore({ nameStore: 'Autochequeo', value: updateData });
        return updateData;
    }

    public async saveNotification(payload: EntityConfigModalComentarios, currentRegister: EntityAutochequeo): Promise<void> {
        const listModule = {
            1: 'LA',
            2: 'CP',
            3: 'CHPT'
        }

        const _payload = {
            codigo : `${Date.now()}`,
            tipoNotificacion : {
                _id: "6426466b52652d4dfe34e246",
                codigo: "01"
            },
            tituloinIdioma: [ 
                {
                    codigo: "es",
                    nombre: "Notificacion"
                }
            ],
            contenidoinIdioma: [ 
                {
                    codigo: "es",
                    cuerpo: `Calificación de ${payload.form.Puntuacion} estrellas, ${payload.form.Comentario}`,
                    subContenido: "",
                    piePagina: ""
                }
            ],
            modulo: [
                {
                    codigo: listModule[currentRegister.Momento.IdMomento as keyof typeof listModule],
                    _id: currentRegister._id || ''
                }
            ],
            opcionesContenido : null,
            opciones: null,
            tiempoLecturas: 0,
            rol: [],
            fechaNotificacion: {
                fechaInicial: {
                    idFecha: parseInt(new Date().toISOString().slice(0, 10).replaceAll('-', '')),
                    fecha: new Date().toISOString()
                },
                fechaObligatorio: {
                    idFecha: parseInt(new Date().toISOString().slice(0, 10).replaceAll('-', '')),
                    fecha: new Date().toISOString()
                },
                fechaFinal: {
                    idFecha: parseInt(new Date().toISOString().slice(0, 10).replaceAll('-', '')),
                    fecha: new Date().toISOString()
                }
            },
            plataformas: [ 
                {
                    id : 2,
                    tipoPlataforma : "Web"
                }, 
                {
                    id : 1,
                    tipoPlataforma : "Movil"
                }
            ],
            pais: null,
            personalInspeccionado: [
                {
                    _id: currentRegister.Tecnico._id || currentRegister.Tecnico.IdPersonal,
                    identificacion: currentRegister.Tecnico.Identificacion,
                    apellidos: currentRegister.Tecnico.Apellidos,
                    nombres: currentRegister.Tecnico.Nombres,
                    revisado: false
                }
            ],
            files: null
        };

        try {
            const url = `${this.urlBaseNewBackend}${AdapterConfigure.SAVE_NOTIFICATION}`
            await this.service.call<any>("POST", url, JSON.stringify(_payload), "basicNewBackend", "json", 'json', { "Request-Decrypt-Response": true }, 0);
        } catch(err) {}
    }

    public async getFatherRegister(id: number): Promise<EntityAutochequeo | null> {
        const itemFinded = (await this.getAutochequeo()).find(row => row.Id === id) || null;
        return itemFinded;
    }
}
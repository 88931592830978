import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_it: EntityLanguage = {
    code: 'it',
    nombreProyecto: 'Sistema di Gestione della Sicurezza',
    textoLogin: 'Inizio sezione',
    textoConfiguracion: 'Configurazione',
    textoCatalogos: 'Aggiornare i dati',
    textoCerrarSesion: 'Chiudere sessione',
    textoModoCrear: 'CREARE',
    textoModoEditar: 'EDITARE',
    textoModoVisualizar: 'VISUALIZZARE',
    textoModoLevantamiento: 'INSURREZIONE',
    textoNavbarInicio: 'Inizio',
    textoConfigurandoAreaTrabajo: 'Configurare il tuo ambiente di lavoro...',
    textoSincronizacionSemanalCatalogo: "Sincronizzazione settimanale del catalogo",
    textoNuevaVersion: 'Nuova versione, clicca qui per aggiornare',
    textoGuardando: 'Salvando...',
    textoCargando: 'Caricamento...',
    textoAlerta: 'Avvisare',
    textoIncompleto: 'Incompleto',
    textoExitoso: 'Successo',
    textoSinConexion: 'Senza connessione',
    textoDescripcionSinConexion: 'Non dispone di connessione a Internet',
    textoValidacionTerminos: 'Devi accettare i termini',
    textoCambioPais: 'Cambiato paese in',
    textoPagina: 'Pagina',
    textoPaginaDe: 'di',
    textoNro: 'Nro.',
    btnCancelar: 'Annullare',
    btnGuardar: 'Salvare',
    btnSiguiente: 'Successivo',
    btnVerImagen: 'Vedi immagine',
    btnRegresar: 'Ritornare',
    btnAgregar: 'Aggiungere',
    textoCargandoAudio: "L'elaborazione dell'audio, a seconda della durata, potrebbe richiedere più tempo...",
    btnDetener: 'Pausa',
    btnReproducir: 'Riproduci',
    btnLimpiar: 'Pulisci',
    btnGuardarFirma: 'Salva Firma',
    btnVerFirma: 'Visualizza Firma',
    btnSi: 'Sì',
    btnNo: 'No',
    btnBuscar: 'Per trovare',
    btnSeleccionar: 'Selezionare',
    btnRecursoPreventivo: 'Risorsa Preventiva',
    btnDescargar: 'Scaricare',
    btnDescargando: 'Scaricare...',
    moduloConfiguracion: {
        textoPreferencia: 'Preferenza',
        textoPais: 'Paese',
        textoDelegacion: 'Delegazione',
        textoEmpresa: 'Società',
        textoOT: 'OT',
        textoIdioma: 'Lingua',
        textoCambioPassword: 'Cambiare Password',
        textoPassword: 'Password',
        textoNuevaPassword: 'Nueva Password',
        textoRepetirNuevaPassword: 'Repetere Nuova Password',
        textoBotonSaveCambioPassword: 'Salvare',
        textoPersonalMediosHumanos: 'Personale risorse umane',
        textoBuscarCuadrillaPersonal: 'Cerca squadra personale',
        textoAutomatizaPersonal: 'Automatizza le risorse umane',
        validate:{
            formPreferencia:{
                pais: 'Seleziona un Paese',
                delegacion: 'Seleziona una delegazione',
                ot: 'Seleziona un OT',
                personal: 'Seleziona uno staff di Human Media'
            },
            formChangePassword:{
                password: 'Inserire la password',
                newPassword: 'Inserire una nuova password',
                reNewPassword: 'Ripetere la nuova password',
                formatNewPassword: 'La nuova password deve essere lunga almeno 12 caratteri e includere almeno una lettera maiuscola, un numero e un carattere speciale'
            },
            cambioPasswordCorrecto: 'La modifica della password è andata a buon fine'
        }
    },
    moduloLogin: {
        textoTitulo: 'Login',
        txtUsuario: 'Utente',
        txtPassword: 'Password',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Ricordami',
        textoOlvidoContrasenia: 'Password dimenticata',
        textoRegresarMain: 'Torna al principale',
        textoRegistrarRDI: 'Accedi come ospite',
        validate: {
            formLogin: {
                username: 'Inserisci Utente',
                usernameMinimo: 'Minimo 3 caratteri',
                password: 'Inserire la password',
                passwordMinimo: 'Minimo 3 caratteri'
            }
        },
        textoTitleSelectPais: "",
        textoPlaceholderSelectPais: "",
        textoErrorSelectPais: ""
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recuperare Password',
        textoUsuario: 'Utente',
        textoIdentificacion: `Numero d'identificazione`,
        textoCorreoElectronico: 'Indirizzo e-mail',
        textoAccion: 'Recuperare',
        textoRegresarLogin: 'Ritornare al Login',
        validate:{
            form:{
                username: 'Inserisci Utente',
                usernameMinimo: 'Minimo 3 caratteri',
                identificacion:'Inserisci il numero del documento di identità',
                identificacionFormato: `Il numero del documento di identità non è corretto`,
                correoElectronico: `Inserisci l'email`,
                correoElectronicoFormato: `L'e-mail è sbagliata`
            },
            recuperarPasswordCorrecto: `Il recupero della password è andato a buon fine, riceverai un messaggio all'email registrata`
        }
    },
    modulo404: {
        textoDescripcion: 'La risorsa richiesta non è disponibile',
        textoHome: `Torna all'inizio`
    },
    moduloNotAvailable: {
        textoCabecera: 'Risorsa Non Abilitata',
        textoDescripcion: 'La risorsa richiesta non è disponibile per il tuo dispositivo',
    },
    moduloMain: {
        textoTitulo: 'Sistema di Gestione della Sicurezza',
        textoBienvenida: 'Ti diamo il benvenuto!',
        textoInicio: 'Inizio',
        textoAyuda: 'Aituo',
        textoTituloPreguntaAyuda: 'La direzione',
        textoDescripcionPreguntaAyuda: 'Sarai reindirizzato alla documentazione di supporto del sistema, sei sicuro?',
        textoReporte: 'Segnalare',
        textoRefrescarApp: `Aggiorna l'app`,
        textoNotificacion: 'Notifica',
        textoTituloPreguntaReporte: 'Redirección',
        textoDescripcionPreguntaReporte: 'Sarai reindirizzato alla documentazione di supporto del sistema, sei sicuro?',
    },
    moduloCatalogo: {
        textoRefrescar: 'Aggiorna',
        textoRefrescarTodo: 'Aggiorna tutto',
        textoSincronizado: 'Stato: Sincronizzato',
        textoNoSincronizado: 'Stato: Non Sincronizzato',
    },
    moduloRDI: {
        list: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'Lista', navigate: false, path: '' }],
            btnAgregar: 'AGGIUNGERE',
            textoVacio: 'Senza Informazione',
            textoEstado: 'Stato',
            textoFechaInicio: 'Data di inizio',
            textoFechafin: 'Data di fine',
            validate: {
                formFilter: {
                    fechaInicio: 'Inserire la data di inizio',
                    fechaFin: 'Inserisci la data di fine',
                    estado: 'Seleziona uno stato'
                }
            },
            option: {
                "pendiente": "In sospeso",
                "solucionado": "Risolto"
            }            
        },
        form: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'Lista', navigate: false, path: '' }],
            textoPais: 'Paese',
            textoDelegacion: 'Delegazione',
            textoEmpresa: 'Impresa',
            textoSubcontrata: 'Subappalto',
            textoCliente: 'Cliente',
            textoActividad: 'Attività',
            textoUsuarioRegistra: 'Registra utente',
            textoFechaHoraIncidencia: 'Data e ora Incidente',
            textoTipoIncidencia: 'Tipo Incidente',
            textoDescripcionIncidencia: 'Descrizione Incidente',
            textoMedidasPropuestas: 'Soluzione proposta',
            textoDescripcionSolucion: 'Descrizione della soluzione',
            textoFotoSubsanacion: 'Correzione fotografica',
            textoUsuarioMedidasPropuestas: 'Responsabile delle soluzioni',
            textoObra: 'Cantiere',
            textoProvincia: 'Provincia',
            textoDireccion: 'Direzione',
            textoFactorRiesgo: 'Fattore di Rischio',
            textoTipoIncidente: 'Proprio / Subappalto',
            textoParalizacionTrabajo: 'Paralizzazione del lavoro',
            textoCondicionInsegura: 'Condizione Insicura',
            textoVehiculoAfectado: 'Veicolo coinvolto',
            textoVerImagenes: 'Vedi immagine',
            textoPlaca: 'Targa del Veicolo coninvolto',
            textMensajeAgradecimiento: 'La RSI è stata registrata con successo ed è già stata comunicata al resto delle persone coinvolte. Grazie mille per aver segnalato questo incidente, con la tua segnalazione contribuisci a migliorare la tua sicurezza e quella dei tuoi colleghi',
            textoFotosIncidencia: "Foto dell'incidente",
            textoAyudaSolucion: 'Compilare solo se il problema è risolto',
            modal: {
                textoTitulo: 'Immagine',
                btnEliminarImagen: 'ELIMINA IMMAGINE',
                textoSinImagenes: 'Senza immagine'
            },
            btnNext: 'Seguente',
            btnSave: 'Salvare',
            stepper: {
                stepper1: {
                    text: 'Informazioni generali',
                    content: {
                        title: 'INFORMAZIONI GENERALI',
                    }
                },
                stepper2: {
                    text: 'Informazione incidenza',
                    textDetalleSolucion: 'Misure proposte',
                    textSolucion: 'Soluzione',
                    content: {
                        title: 'INFORMAZIONE INCIDENZA',

                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Seleziona un OT',
                    delegacion: 'Seleziona una delegazione',
                    empresa: "Seleziona un'azienda",
                    cliente: 'Seleziona un Cliente',
                    actividad: `Seleziona un'attività`,
                    usuarioRegistro: 'Seleziona un Registra utente',

                    fechaIncidente: `Selezionare una data e un'ora dell'incidente`,
                    tipoIncidencia: 'Seleziona un tipo di incidente',
                    breveDescripcionIncidente: `Immettere una descrizione dell'incidente`,
                    factorRiesgo: 'Seleziona almeno un fattore di rischio',
                    tipoIncidente: 'Seleziona almeno un tipo di incidente',
                    condicionInsegura: 'Seleziona una condizione non sicura',
                    vehiculoAfectado: 'Seleziona Veicolo interessato',
                    cantidadImagenes: "Le nombre maximum d'images autorisé est de 10",

                    propuestaSolucion: 'Inserisci una proposta di soluzione',
                    descripcionSolucion: 'Inserisci una descrizione della soluzione',
                    responsableSolucion: 'Inserisci un responsabile della soluzione',
                    fotoSolucion: 'Inserisci una foto della riparazione',

                    fotoIncidencia: 'Carica un’immagine del problema',
                    fotoSolucionRequerimientroPrevioFotoIncidencia: 'Devi prima caricare una foto del problema'
                }
            },
            TipoIncidentePersonal: "Personale proprio",
            TipoIncidenteSubContrata: "Personale subappaltato"
        }
    },
    moduloInspeccion: {
        list: {
            navbar: [{ text: 'Ispezioni', navigate: false, path: '' }],
            navbarOPS: [{ text: 'OPS', navigate: false, path: '' }],
            btnAgregar: 'AGGIUNGI',
            textoVacio: 'Senza Ispezione',
            textoVacioOPS: 'Senza Ispezione OPS',
            textoEnviandoCorreo: 'Invio di posta...',
            modalEmail: {
                title: 'Invia e-mail',
                correoElectronico: 'E-mail',
                tableEmail: 'E-mail',
                cc: 'CC',
                btnEnviar: 'Invia'
            },
            modalSearch: {
                titulo: 'Ricerca personalizzata',
                fechaInicial: 'Data di inizio',
                fechaFinal: 'Data di fine',
                tipoOperacion: 'Tipo di operazione',
                identificacion: 'Identificazione',
                buscarInspeccionesFinalizadas: 'Ricerca delle ispezioni completate',
                codigoInspeccion: 'Codice di ispezione',
                btnBuscar: 'Per trovare'
            },
            item: {
                jefeObra: 'Capo cantiere',
                autor: 'Autore',
                nroNoConformidad: 'No. Non conformità',
                fechaInspeccion: 'Data ispezione',
                empresa: 'Impresa',
                subContrata: 'Subappalto',
                tipoOperacion: 'Tipo operazione',
                personal: 'Personale'
            },
            validate:{
                correoExiste: `L'e-mail esiste`,
                correoEnviado: `Inviato con successo`,
                fechaInicio: 'Inserisci la data di inizio',
                fechaFinal: 'Inserisci la data di fine',
                fechaRangoInicioFinal: 'La data di inizio non deve essere superiore alla data finale',
                tipoOperacion: 'Inserisci almeno un tipo di operazione',
                identificacion: 'Inserisci ID',
            }
        },
        form: {
            navbar: [{ text: 'Ispezioni', navigate: true, path: '/main/Inspeccion' }],
            navbarOPS: [{ text: 'OPS', navigate: true, path: '/main/OPS' }],
            navbarText: {
                modeCrear: 'Crea',
                modeVisualizar: 'Visualizza',
                modeLevantamiento: 'Risoluzione'
            },
            btnNext: 'Seguente',
            btnSave: 'Salvare',
            stepper: {
                stepper1: {
                    text: 'Informazioni generali',
                    content: {
                        title: 'INFORMAZIONI GENERALI',
                        ot: 'OT',
                        area: 'Area / Filiale',
                        empresa: 'Impresa',
                        actividad: 'Attività',
                        cliente: 'Cliente',
                        jefeObra: 'Capo cantiere',
                        coordinador: 'Tecnico della sicurezza',
                        encargado: 'Responsabile'
                    }
                },
                stepper2: {
                    text: 'Informazione ispezioni',
                    content: {
                        title: 'INFORMAZIONE ISPEZIONI',
                        responsableLevantamiento: 'Responsabile Risoluzione',
                        inspeccionConjunta: 'Ispezione congiunta',
                        personalConjunta: 'Personale Congiunta',
                        subContrata: 'Subabbalto',
                        inspeccionSubcontrataConjunta: 'Ispezione congiunta dei subappalti',
                        tipoOperacion: 'Tipo Operazione',
                        formato: 'Formato',
                        paralizacionTrabajo: 'Paralizzazione del lavoro',
                        momento: 'Momento',
                        lugar: 'Luogo',
                        obraTD: 'Cantiere / TD',
                        trabajoRealizar: 'Lavoro da realizzare',
                        placa: 'Targa / Targa',
                        reconocimientoSST: 'Riconoscimento per le buone prestazioni in materia di SSL',
                        observacionOPS: 'Osservazione OPS',
                        fueraHorario: "Dopo l'orario di chiusura",
                    }
                },
                stepper3: {
                    text: 'Informazione Personale',
                    content: {
                        title: 'INFORMAZIONE PERSONALE',
                        personal: 'Personale',
                        table: {
                            id: 'ID',
                            name: 'Nome',
                            lastName: 'Cognome',
                            responsable: 'Job Manager',
                            recursoPreventivo: 'Ricorso preventivo',
                        }
                    }
                },
                stepper4: {
                    text: 'Informazione Registro',
                    content: {
                        details: {
                            title: 'TIPO DI ISPEZIONE',
                            btnSelect: 'Selezionare',
                            listEmpty: 'Senza Tipo ispezione selezionato',
                            titleModal: 'Seleziona Tipo di ispezione',
                            titleModalView: "Informazioni sull'oggetto",
                            titleModalCreate: 'Crea elemento',
                            titleModalEdit: 'Modifica elemento'
                        },
                        items: {
                            title: 'LISTA ITEM',
                            btnList: 'Lista',
                            btnAdd: 'Aggiungi',
                            listEmpty: 'Senza registro',
                            modal: {
                                tipoInspeccion: 'Tipo Ispezione',
                                tipoItem: 'Tipo Item',
                                item: 'Item',
                                detalle: 'Dettaglio',
                                personal: 'Personale',
                                resultado: 'Risultato',
                                nivelGravedad: 'Livello di gravità',
                                tipoSituacion: 'Tipo Situazione',
                                grave: 'Grave',
                                descripcion: 'Descrizione',
                                accionPropuesta: 'Azione proposta',
                                levantamientoInmediato: 'Risoluzione Immediata',
                                descripcionLevantamiento: 'Descrizione Risoluzione',
                                fechaLevantamiento: 'Data del sondaggio',
                                imgTextoInspeccion: 'Ispezione',
                                imgTextoLevantamiento: 'Risoluzione',
                                titleModalImage: 'Visualizza Immagine',
                                fechaPrevista: 'Data prevista',
                                btnSave: 'Salvare',
                                btnSaveAndContinue: 'Salva e Continua',
                                btnCancel: 'Annulla'
                            },
                            item: {
                                tipoInspeccion: 'Tipo Ispezione',
                                tipoItem: 'Tipo Item',
                                item: 'Item',
                                descripcion: 'Descrizione',
                                textIncompleta: 'Incompleta',
                                textCorrecto: 'Corretto',
                                textGrave: 'Grave',
                                textNoConforme: 'Non Conforme',
                                textImgInspeccion: 'Ispezione'
                            }
                        },
                        textoCompruebaInformacion: 'Controlla le tue informazioni su',
                        textObservacionGeneral: 'Commento generale',
                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Seleziona un OT',
                    area: `Seleziona un'area`,
                    empresa: "Seleziona un'azienda",
                    actividad: `Seleziona un'attività`,
                    cliente: 'Seleziona un Cliente',
                    jefeObra: 'Seleziona un responsabile del sito',
                    coordinador: 'Seleziona un coordinatore',

                    conjuntaPersonal: 'Seleziona un rigo in comune',
                    tipoOperacion: 'Seleziona un tipo di operazione',
                    formato: 'Seleziona un formato',
                    tipoMomentoTrabajo: 'Seleziona un tipo di momento',
                    lugar: 'Inserisci un Luogo',
                    obra: `Inserisci un'opera`,
                    trabajoRealizar: 'Inserisci un lavoro da svolgere',
                    observacionOPS: 'Inserisci un OPS di osservazione',

                    personalResponsableTrabajo: 'Seleziona un Job Manager',

                    detalleInspeccionVacia: 'Inserisci almeno un elemento',
                    detalleInspeccionIncompleta: 'Completa tutti gli elementi',
                    responsableLevantamiento: 'Seleziona un responsabile',
                    cantidadImagenes: (cantidadMaxima: any) => `Il numero massimo di immagini consentite è ${cantidadMaxima}`, 
                },
                formRegistroItem: {
                    tipoInspeccion: 'Selezionare Tipo di ispezione',
                    tipoItem: 'Seleziona il tipo di Item',
                    item: 'Seleziona un Item',
                    detalle: 'Seleziona un dettaglio',
                    personal: 'Seleziona un bastone',
                    resultado: 'Seleziona un risultato',
                    nivelGravedad: 'Seleziona Livello di gravità',
                    descripcion: 'Inserisci una descrizione',
                    accionPropuesta: `Immettere un'azione proposta`,
                    tipoSituacion: 'Seleziona un tipo di situazione',
                    descripcionLevantamiento: 'Inserisci una descrizione del sondaggio',
                    fechaLevantamiento: 'Inserisci una data di scadenza',
                    fotoLevantamiento: `Inserisci un'immagine del sondaggio`,
                    cantidadImagenes: 'Tutte le immagini sono necessarie? (verificare che nessuna immagine sia ripetuta)',
                    fechaPrevista: 'Inserisci una data prevista'
                },
                noItem: 'Item non trovato',
                noDetalleItem: 'Dettaglio Item non trovato',
                detalleItemDuplicado: 'Dettaglio Item duplicato',
                detalleItemNoPermitido: 'Articolo non consentito per essere aggiunto',
                noTipoInspeccion: 'È necessario selezionare un tipo di ispezione per il completamento automatico'
            },
            TipoMomento: {
                inicio: 'Inizio del lavoro',
                durante: 'Durante il lavoro',
                fin: 'Fine del lavoro'
            },
            textoBuscarCuadrillaPersonal: "Ricerca personale equipaggio...",
            textoCorrecto: "Corretto",
            textoNoConforme: "Non conformità",
            nivelSeguridad: {
                textoAlta: "Alta",
                textoMedia: "Media",
                textoLeve: "Lieve",
            },
            tipoSituacion: {
                textoActoInseguro: "Atto non Sicuro",
                textoCondicionInsegura: "Condizione non Sicura"
            }
        }
    },
    moduloAutoChequeo: {
        list: {
            TituloListaAutochequeo: "LISTA AUTOCONTROLLO",
            TituloListaControlPreventivo: "CONTROLLO PREVENTIVO",
            navbar: [{ text: 'Gestisci Autocontrollo', navigate: false, path: '' }],
            navbarGuest: [{ text: 'Colloquio pre-attività', navigate: false, path: '' }],
            Estado: "Stato",
            textoCPPendiente: 'CP in sospeso',
            textoCPCerrado: 'CP chiuso',
            EstadoItems: {
                EstadoActivo: "Attivo",
                EstadoInactivo: "Inattivo"
            },
            Resultado: "Risultato",
            Autor: "Autore",
            textAudio: 'Audio',
            textPDF: 'PDF',
            ResultadoItems: {
                ResultadoCorrecto: "Corretto",
                ResultadoIncorrecto: "Incorretto"
            },
            FechaRegistro: "Data Registro",
            search: {
                Titulo: "Cerca Autocontrollo",
                FechaInicial: "Data Iniziale",
                FechaFinal: "Data Finale",
                Identificacion: "Identificazione",
                BtnBuscar: "Cerca",
                BusquedaGrupal: "Ricerca di gruppo",
                BuscarRegistrosActivos: "Cerca nei record attivi"
            },
            validate: {
                formFilter: {
                    fechaInicial: 'Seleziona una data di inizio',
                    fechaFinal: 'Seleziona una data di fine',
                    identificacion: 'Inserisci un ID'
                }
            }
        },
        form: {
            navBarTextCharlaPretarea: 'Discorso pre-task',
            navBarTextListaAutochequeo: 'Lista Autocontrollo',
            navBarTextControlPreventivo: 'Controllo Preventivo',
            navBarTextCierreControlPreventivo: 'Chiudi Controllo Preventivo',
            navBarTextPreview: 'Visualizzzare',
            navBarGuest: [{ text: 'Colloquio pre-attività', navigate: true, path: '/main/GestionAutochequeo' }, { text: 'Dettaglio', navigate: false, path: '' }],
            tituloDatosGenerales: 'Dati Generali',
            tituloDatosControlPreventivo: 'Dati Controllo Preventivo',
            textoAyudaControlPreventivo: "Contiene la chiusura preventiva del controllo",
            textoAyudaNecesitaRegistrarLAutochequeo: "Questo OT ha bisogno che la lista di autocontrollo sia fatta prima, se ha ignorato questo messaggio",
            btnAutcompletarUltimoRegistro: "Completamento automatico dall'ultimo record",
            inputs: {
                ot: 'OT',
                empresa: 'Impresa',
                subContrata: 'Subappalto',
                delegacion: 'Delegazione',
                actividad: 'Attività',
                recursoPreventivo: 'Preposto',
                cliente: 'Cliente',
                obra: 'Cantiere',
                lugar: 'Luogo',
                trabajoRealizar: 'Lavoro da realizzare',
                jefeObra: 'Capo cantiere',
                tituloGrupo: 'Informazione per gruppo',
                ordenServicio: 'Ordine di servizio / Ordine di lavoro',
                txtObservacion: 'Osservazione',
                txtPersonal: 'Personale',
                textFirmaJefeTrabajo: 'Firma del Capo del Lavoro',
                textBien: 'Bene',
                textMal: 'Male'
            },
            preguntarRealizarCierreControlPreventivo: 'Vuoi effettuare la chiusura del controllo preventivo?',
            advertenciaListaAutochequeoRealizado: 'La lista di autocontrollo è già realizzata',
            advertenciaSinRegistro: 'Il registro di autocontrollo non è stato trovato',
            advertenciaRegistroConCierre: 'Questa registrazione ha già una Chiusura',
            advertenciaCargarOT: 'Si è verificato un errore durante il caricamento delle OT',
            advertenciaBuscarOTRegistro: 'Non si riscontra la OT vincolata al registro',
            advertenciaRegistroSinCierre: 'Questa OT non ha una chiusira di un controllo preventivo',
            advertenciaOTSinTipologias: 'Questa OT non ha tipigia',
            advertenciaOTSinPreguntas: 'Questa OT non ha nessuna domanda',
            advertenciaOTSinPreguntasAutochequeo: `Questa OT non ha domande da visualizzare, provare con un'altra`,
            advertenciaOTPreview: (text: string) => `Non riesci a trovare l'OT in cui ti sei registrato ${text}, ripeti l'intero processo`,
            textTipologia: 'Tipologie',
            tipologiaVacio: 'Senza Tipologie',
            textCargandoPreguntas: 'Caricamento Domande',
            textCargandoTipologias: 'Caricando Tipologia',
            textRegistroIncompleto: 'Hai una registrazione che non è stata completata, vuoi caricarla?',
            validate:{
                formRegistro:{
                    ordenServicio: 'Immettere un ordine di servizio / Ordine di lavoro',
                    ot: 'Seleziona un OT',
                    otDirectoCP: `Devi prima completare "Self-Check Checklist" o "Pre-Task Talk"`,
                    tipologia: 'Seleziona almeno una tipologia',
                    empresa: `Seleziona un'azienda`,
                    delegacion: 'Seleziona una delegazione',
                    actividad: `Seleziona un'attività`,
                    cliente: 'Seleziona un Cliente',
                    obra: `Inserisci un'opera`,
                    lugar: 'Inserisci un Luogo',
                    trabajoRealizar: 'Inserisci un lavoro da svolgere',
                    jefeObra: 'Seleziona un responsabile del sito',
                    usuarioRegistra: 'Seleziona Registri utente'
                },
                formDinamico: {
                    preguntaNoRespondido: 'Non hai risposto alla domanda numero',
                    preguntaNoEstadoEquipo: `Seleziona 'stato della squadra' nel numero della domanda`,
                    preguntaNoObservacion: `Inserisci una 'osservazione' nel numero della domanda`,
                    preguntaNoAudio: `Registra un 'audio' sulla domanda numero`,
                    preguntaNoPersonal: `Inserisci "personale" nel numero della domanda`,
                    preguntaNoImagen: (count: number) => `Il numero massimo di immagini è ${count} nel numero di domande`,
                    preguntaImagen: `Inserisci un'immagine nel numero della domanda`,
                    preguntaBloqueo: `Non è consentito continuare, per mancanza di formazione tecnica nella domanda numero`,
                    preguntaNoImagenGrupal: `Inserisci un'immagine di gruppo`,
                    preguntaNoObservacionGrupal: `Inserisci un'osservazione di gruppo`,
                    preguntaBlockDevolverOrden: (numero: any) => `Se non soddisfi il materiale necessario nella domanda numero ${numero} non sarai in grado di registrarti`,
                    preguntaModuloAUT: `Puoi registrarti solo nella "Lista di Autocontrollo" se sei l'unico componente della brigata; le registrazioni di gruppo vengono effettuate nella "Briefing Pre-lavoro"`,
                    preguntaModuloCPT: 'Puoi registrarti solo nella "Briefing Pre-lavoro" se ci sono diversi membri della brigata; le registrazioni individuali vengono effettuate nella "Lista di Autocontrollo"',
                    preguntaJefeTrabajo: 'Seleziona un Capo Lavoro nella domanda numero',
                    preguntaRiesgo: 'Seleziona un rischio nella domanda numero',
                    preguntaMedidaPreventiva: 'Seleziona una Misura Preventiva nella domanda numero',
                    preguntaEPCS: 'Seleziona un Dispositivo di Protezione Individuale (DPI) nella domanda numero',
                    preguntaRecursoPreventivo: 'Seleziona la Risorsa Preventiva nella domanda numero',
                    preguntaFirmaJefeTrabajo: 'Inserisci la firma del Capo Lavoro nella domanda numero',
                    preguntaFirmaJefeTrabajoVacio: 'Disegna una firma per salvare',
                }
            }
        },
        comentario: {
            textComentario: 'Commento',
            titleModalAgregar: 'Aggiungi un commento',
            titleModalList: 'Elenco dei commenti',
            table: {
                autor: 'Autore',
                puntuacion: 'Punteggiatura',
                Comentario: 'Commento'
            },
            requiredAutochequeo: 'Dovresti prima rivedere il PDF prima di inserire un commento',
            requiredCharlaPretarea: "Dovresti prima rivedere l'audio e il PDF prima di inserire un commento"
        }
    },
    moduloNotificacion: {
        textoNoLeido: 'Non letto',
        textoAcabaRecibirNotificacion: 'Hai appena ricevuto un nuovo commento nel numero di registrazione:',
        textoNuevo: 'Nuovo'
    },
    menu: {
        '010': {
            titulo: 'Ispezioni',
            descripcion: 'Registro di Ispezioni'
        },
        '020': {
            titulo: 'Ispezioni OPS',
            descripcion: 'Registro di Ispezioni OPS'
        },
        '030': {
            titulo: 'Lista di Autocontrollo',
            descripcion: 'Registro di Lista di Autocontrollo'
        },
        '031': {
            titulo: 'Discorso pre-task',
            descripcion: 'Registrazione del discorso pre-task'
        },
        '040': {
            titulo: 'Controllo Preventivo',
            descripcion: 'Registro delle domande del Controllo Preventivo'
        },
        '050': {
            titulo: 'RDI',
            descripcion: 'Rapporto di incidenti'
        },
        '060': {
            titulo: 'Gestisci Autocontrollo',
            descripcion: 'Gestisci Autocontrollo'
        },
    },
    languages: [
        { code: 'es', name: 'Spagnolo' },
        { code: 'en', name: 'Inglese' },
        { code: 'it', name: 'Italiano' },
        { code: 'fr', name: 'Francese' },
        { code: 'pt', name: 'Portoghese' },
    ]
}
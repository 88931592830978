import { AdapterGenerico } from "./AdapterGenerico";
import { LanguageTranslate } from "./LanguageTranslate";

export interface IAddressComplete {
    country: string;
    region: string;
    district: string;
    locality: string;
}

export class AdapterGeolocation {
    public static async getLocation(): Promise<{ latitud: number, longitud: number }> {
        return new Promise<{ latitud: number, longitud: number }>((resolve, reject) => {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };

            const onSuccess: PositionCallback = (position: GeolocationPosition) => {
                resolve({ latitud: position.coords.latitude, longitud: position.coords.longitude });
            };

            const onError: PositionErrorCallback = (e: GeolocationPositionError) => {
                const message: any = {
                    0: 'Error desconocido al obtener la ubicación', // UNKNOWN_ERROR 
                    1: 'El acceso a la ubicación fue denegado', // PERMISSION_DENIED 
                    2: 'No se pudo obtener la ubicación actual, inténtelo mas tarde', // POSITION_UNAVAILABLE 
                    3: 'No se pudo obtener la ubicación actual, inténtelo mas tarde', // TIMEOUT
                    20: 'Navegador no compatible con la geolocalización', // UNSUPPORTED_BROWSER
                    21: 'Solicitud de la ubicación fue cancelada', // USER_ABORTED 
                }
                reject(new Error(`${message[e.code] || 'Ocurrío un error al obtener la ubicación actual, inténtelo mas tarde ó ingréselo manualmente'}`));
            };

            navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
        })
    }

    public static async getAddress(params: { latitud: number, longitud: number }) {
        try {
            let response = await fetch(`${process.env.REACT_APP_GEO_URL}${params.longitud},${params.latitud}.json?access_token=${process.env.REACT_APP_GEO_TOKEN}`);
            let result = await response.json();

            let addres: string = '';
            let neighborhood: string = '';
            let postcode: string = '';
            let locality: string = '';
            let place: string = '';
            let district: string = '';
            let region: string = '';
            let country: string = '';

            for (let row of result.features) {
                if (row.id.includes('address')) {
                    addres = row.text;
                } else if (row.id.includes('postcode')) {
                    postcode = row.text;
                } else if (row.id.includes('neighborhood')) {
                    neighborhood = row.text;
                } else if (row.id.includes('locality')) {
                    locality = row.text;
                } else if (row.id.includes('place')) {
                    place = row.text;
                } else if (row.id.includes('district')) {
                    district = row.text;
                } else if (row.id.includes('region')) {
                    region = row.text;
                } else if (row.id.includes('country')) {
                    country = row.text;
                }
            }

            let data: string = `${addres}`;
            data += `${!neighborhood.length ? '' : `, ${neighborhood}`}`;
            data += `${!postcode.length ? '' : `, ${postcode}`}`;
            data += `${!locality.length ? '' : `, ${locality}`}`;
            data += `${!place.length ? '' : `, ${place}`}`;
            data += `${!district.length ? '' : `, ${district}`}`;
            data += `${!region.length ? '' : `, ${region}`}`;
            data += `${!country.length ? '' : `, ${country}`}`;

            return { data, dataComplete: { country, region, district, locality } };
        } catch (error) {
            return { data: '', dataComplete: { country: '', region: '', district: '', locality: '' } };
        }
    }

    public static async getAllGeolocation(): Promise<{ lat: number; lng: number; address: string; reAddressComplete: IAddressComplete }> {
        const languageTranslate = LanguageTranslate();
        let result = { lat: 0, lng: 0, address: '', reAddressComplete: { country: '', region: '', district: '', locality: '' } };
        if (!navigator.onLine) return result;

        try {
            let resGeolocation = await AdapterGeolocation.getLocation();
            let resAddress = await AdapterGeolocation.getAddress(resGeolocation);
            Object.assign(result, { lat: resGeolocation.latitud, lng: resGeolocation.longitud, address: resAddress.data, reAddressComplete: resAddress.dataComplete });
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }

        return result;
    }
}
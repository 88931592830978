import { useSelector } from 'react-redux';
import { RootState } from '../Infraestructure/AdapterStore';
import './ElementLoader.scss';
import logo from '../../../assets/img/IconosAppSGSOMovil/ic_nuevo2_round.png';
import logoSafeIS from '../../../assets/img/safeis/logo-safeis-rounded.png';

export const ElementLoader = () => {
    const { generico: { loading, textLoading }, auth: { preferencia } } = useSelector((state: RootState) => state);
    return (
        <div className='ElementLoader' hidden={!loading}>
            <div className={'loader-wrapper'} />
            <img className="loading-image" src={preferencia.appv2 ? logoSafeIS : logo } alt="Loading..." />
            <span className="loading-span">{textLoading}</span>
        </div>
    )
};

import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewModal } from './Components/ViewModal';
import { LanguageTranslate } from '../../shared/Infraestructure/LanguageTranslate';


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        try {
            controller.init();
            return () => {
            }
        } catch (error) {
            window.location.reload();
        }
        // eslint-disable-next-line
    }, [controller.countProcess]);

    return (
    <>
        <ViewMain
            data={controller.data}
            formFilter={controller.formFilter}
            onChangeFechaInicio={controller.onChangeFechaInicio}
            onChangeFechaFin={controller.onChangeFechaFin}
            onRedirectForm={controller.onRedirectForm}
            reloadData={controller.loadData}
            downloadFile={controller.downloadFile}
            user={controller.user}
            openModal={() => { controller.onChangeModal({ show: true }) }}
        />
        <ViewModal
            configModal={controller.configModal}
            languageTranslate={LanguageTranslate()}
            onChangeModal={controller.onChangeModal}
            onSubmitModal={controller.onSubmitModal}
        />
    </>
    );
};

export default ManagerEntity;
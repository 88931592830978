import { useDispatch } from "react-redux";
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { useNavigate } from "react-router-dom";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { AdapterConfigure } from "./AdapterConfigure";
import { useState } from "react";
import { EntityNotification } from "../../../shared/Domain/Generic/EntityNotification";
import { keyModule } from "../Domain/Utils";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [listNotification, setListNotification] = useState<EntityNotification[]>([]);
    const [isListNoRead, setListNoRead] = useState<boolean>(false);

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);


    const init = () => {
        dispatch(hideIconMenu());
        dispatch(changeSaludo(false));
        getNotifications();
    }

    const getNotifications = async () => {
        let result = await repository.getList();
        if (!result) return;
        result = result.map(row => ({
            ...row,
            personalInspeccionado: row.personalInspeccionado.filter(row => row.identificacion === user.Identificacion)
        })).sort((rowA, rowB) => rowB.fechaNotificacion.fechaInicial.idFecha - rowA.fechaNotificacion.fechaInicial.idFecha)
        setListNotification(() => result);
    }

    const onChangeReadNotification = () => setListNoRead(value => !(value));

    const redirectItem = async (key: keyModule, payload: EntityNotification) => {
        // const resultQuest = await AdapterGenerico.createMessage('Pregunta', 'Esta a punto de ser redirigido, ¿Esta seguro?', 'question', true);
        // if (!resultQuest) return;

        dispatch(addLoading({ text: languageTranslate.textoCargando }))
        // await repository.readNotification({ identificacion: user.Identificacion, _id: payload._id, countNotifications })
        const id = payload.modulo[0]._id;
        const url = `${AdapterConfigure.LIST_REDIRECT_NOTIFICATION[key]}/${id}?openCommentDefault=true`;
        dispatch(removeLoading());
        navigate(url, { replace: true })
    }

    const reloadData = async () => {
        dispatch(addLoading({ text: languageTranslate.textoCargando }));
        await repository.getNotificationsPending({ identificacion: user.Identificacion });
        await getNotifications();
        dispatch(removeLoading());
    }

    return ({
        init,
        redirectItem,
        listNotification,

        isListNoRead,
        onChangeReadNotification,
        reloadData
    })
}
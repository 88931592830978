import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTE: Modify only the value, not the key */
export const idioma_en: EntityLanguage = {
    code: 'en',
    nombreProyecto: 'Operational Safety Management System',
    textoLogin: 'Login',
    textoConfiguracion: 'Settings',
    textoCatalogos: 'Refresh data',
    textoCerrarSesion: 'Logout',
    textoModoCrear: 'ADD',
    textoModoEditar: 'EDIT',
    textoModoVisualizar: 'PREVIEW',
    textoModoLevantamiento: 'SURVEY',
    textoNavbarInicio: 'Home',
    textoConfigurandoAreaTrabajo: 'Setting up your work environment...',
    textoSincronizacionSemanalCatalogo: 'Weekly catalog synchronization',
    textoNuevaVersion: 'New version available, click here get it',
    textoGuardando: 'Saving...',
    textoCargando: 'Loading...',
    textoAlerta: 'Alert',
    textoIncompleto: 'Incomplete',
    textoExitoso: 'Success',
    textoSinConexion: 'Broken Connection',
    textoDescripcionSinConexion: 'Internet connection is not available',
    textoValidacionTerminos: 'Accept Terms of Services',
    textoCambioPais: 'Country was changed to',
    textoPagina: 'Page',
    textoPaginaDe: 'of',
    textoNro: 'Num.',
    btnCancelar: 'Cancel',
    btnGuardar: 'Save',
    btnSiguiente: 'Next',
    btnVerImagen: 'Show images',
    btnRegresar: 'Back',
    btnAgregar: 'Add',
    textoCargandoAudio: 'Processing audio, this may take longer...',
    btnDetener: 'Pause',
    btnReproducir: 'Play',
    btnLimpiar: 'Clear',
    btnGuardarFirma: 'Save Signature',
    btnVerFirma: 'View Signature',
    btnSi: 'Yes',
    btnNo: 'No',
    btnBuscar: 'Search',
    btnSeleccionar: 'Select',
    btnRecursoPreventivo: 'Safety Staff',
    btnDescargar: 'Download',
    btnDescargando: 'Downloading...',
    moduloConfiguracion: {
        textoPreferencia: 'Preferences',
        textoPais: 'Country',
        textoDelegacion: 'Delegation',
        textoEmpresa: 'Company',
        textoOT: 'OT',
        textoIdioma: 'Language',
        textoCambioPassword: 'Change Password',
        textoPassword: 'Password',
        textoNuevaPassword: 'New Password',
        textoRepetirNuevaPassword: 'Repeat New Password',
        textoBotonSaveCambioPassword: 'Save',
        textoPersonalMediosHumanos: 'Human Resources Staff',
        textoBuscarCuadrillaPersonal: 'Search Crew',
        textoAutomatizaPersonal: 'Automate Human Resources',
        validate: {
            formPreferencia: {
                pais: 'Select Country',
                delegacion: 'Select a Delegation',
                ot: 'Select a OT',
                personal: 'Select a Human Media Staff'
            },
            formChangePassword: {
                password: 'Type Password',
                newPassword: 'Type New Password',
                reNewPassword: 'Repeat New Password',
                formatNewPassword: 'Your new password must be at least 12 characters long and include at least one uppercase letter, one number, and one special character'
            },
            cambioPasswordCorrecto: 'Password change was successful'
        }
    },
    moduloLogin: {
        textoTitulo: 'Login',
        txtUsuario: 'User',
        txtPassword: 'Password',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Remenber me',
        textoOlvidoContrasenia: 'Forgot your password?',
        textoRegresarMain: 'Return to Sign-In',
        textoRegistrarRDI: 'Log in as a Guest',
        textoTitleSelectPais: 'Select Country',
        textoPlaceholderSelectPais: 'Country',
        textoErrorSelectPais: 'Option is mandatory.',
        validate: {
            formLogin: {
                username: 'Username',
                usernameMinimo: 'Minimum 3 characters',
                password: 'Password',
                passwordMinimo: 'Minimum 3 characters'
            }
        }
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Password Recovery',
        textoUsuario: 'User',
        textoIdentificacion: 'Identification Document Number',
        textoCorreoElectronico: 'Email',
        textoAccion: 'Reset Password',
        textoRegresarLogin: 'Return to Sign-In',
        validate:{
            form:{
                username: 'Username',
                usernameMinimo: 'Minimum 3 characters',
                identificacion:'Identification Document Number',
                identificacionFormato: 'Identification Document Number is invalid.',
                correoElectronico: 'Email',
                correoElectronicoFormato: 'Email is invalid'
            },
            recuperarPasswordCorrecto: 'Password restore was successfully, you will receive a message to registered email'
        }
    },
    modulo404: {
        textoDescripcion: 'Requested resource is not available',
        textoHome: 'Go Home'
    },
    moduloNotAvailable: {
        textoCabecera: 'Resource is not Available',
        textoDescripcion: 'Requested resource is not available',
    },
    moduloMain: {
        textoTitulo: 'Operational Safety Management System',
        textoBienvenida: 'Welcome!',
        textoInicio: 'Home',
        textoAyuda: 'Help',
        textoTituloPreguntaAyuda: 'Redirecting',
        textoDescripcionPreguntaAyuda: 'You will be redirected to the system help documentation, Are you sure?',
        textoReporte: 'Report',
        textoRefrescarApp: 'Refresh App',
        textoNotificacion: 'Notifications',
        textoTituloPreguntaReporte: 'Redirecting',
        textoDescripcionPreguntaReporte: 'You will be redirected to the Service Management System, Are you sure?',
    },
    moduloCatalogo: {
        textoRefrescar: 'Refresh',
        textoRefrescarTodo: 'Refresh All',
        textoSincronizado: 'Status: Synchronized',
        textoNoSincronizado: 'Status: Not Synchronized',
    },
    moduloRDI: {
        list: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'List', navigate: false, path: '' }],
            btnAgregar: 'ADD',
            textoVacio: 'No data',
            textoEstado: 'Status',
            textoFechaInicio: 'Start date',
            textoFechafin: 'End date',
            validate: {
                formFilter: {
                    fechaInicio: 'Starting date',
                    fechaFin: 'Ending date',
                    estado: 'Select a date'
                }
            },
            option: {
                "pendiente": "Pending",
                "solucionado": "Resolved"
            }            
        },
        form: {
            navbar: [{ text: 'RDI', navigate: true, path: '/main/RDI' }, { text: 'List', navigate: false, path: '' }],
            textoPais: 'Country',
            textoDelegacion: 'Delegation',
            textoEmpresa: 'Company',
            textoSubcontrata: 'Subcontractor',
            textoCliente: 'Customer',
            textoActividad: 'Activity',
            textoUsuarioRegistra: 'Registered By',
            textoFechaHoraIncidencia: 'Incident Datetime',
            textoTipoIncidencia: 'Incident Type',
            textoDescripcionIncidencia: 'Incident Description',
            textoMedidasPropuestas: 'Proposed solution',
            textoDescripcionSolucion: 'Solution description',
            textoFotoSubsanacion: 'Photo correction',
            textoUsuarioMedidasPropuestas: 'Solution Manager',
            textoObra: 'Work Project',
            textoProvincia: 'State',
            textoDireccion: 'Location',
            textoFactorRiesgo: 'Risk Factor',
            textoTipoIncidente: 'Own / Subcontract',
            textoParalizacionTrabajo: 'Stop Work',
            textoCondicionInsegura: 'Unsafe Condition',
            textoVehiculoAfectado: 'Affected Vehicle',
            textoVerImagenes: 'Show image',
            textoPlaca: 'Affected Vehicle Plate Number',
            textMensajeAgradecimiento: 'The RDI has been successfully saved and has already been communicated to Safety Team. Thank you for reporting this incident, with your report you help improve your safety and all your colleagues',
            textoFotosIncidencia: 'Incident Photos',
            textoAyudaSolucion: 'Fill in only if the issue is resolved',
            modal: {
                textoTitulo: 'Image',
                btnEliminarImagen: 'DELETE IMAGE',
                textoSinImagenes: 'No images'
            },
            btnNext: 'Next',
            btnSave: 'Save',
            stepper: {
                stepper1: {
                    text: 'General',
                    content: {
                        title: 'GENERAL',

                    }
                },
                stepper2: {
                    text: 'Incident Information',
                    textDetalleSolucion: 'Proposed Measures',
                    textSolucion: 'Solution',
                    content: {
                        title: 'Incident INFORMATION',

                    }
                },
            },
            validate: {
                formRegistro: {
                    ot: 'OT',
                    delegacion: 'Delegation',
                    empresa: 'Company',
                    cliente: 'Customer',
                    actividad: 'Activity',
                    usuarioRegistro: 'Registered by',

                    fechaIncidente: 'Incident Datetime',
                    tipoIncidencia: 'Incident Type',
                    breveDescripcionIncidente: 'Incident Description',
                    factorRiesgo: 'Risk Factor',
                    tipoIncidente: 'Incident Type',
                    condicionInsegura: 'Unsafe Condition',
                    vehiculoAfectado: 'Affected Vehicle',
                    cantidadImagenes: 'You can upload up to 10 images maximum',

                    propuestaSolucion: 'Enter a solution proposal',
                    descripcionSolucion: 'Enter a solution description',
                    responsableSolucion: 'Enter a solution responsible',
                    fotoSolucion: 'Enter a remediation photo',

                    fotoIncidencia: 'Upload an image of the issue',
                    fotoSolucionRequerimientroPrevioFotoIncidencia: 'You must first upload a photo of the issue'
                }
            },
            TipoIncidentePersonal: "Own Staff",
            TipoIncidenteSubContrata: "Subcontracted Staff"
        }
    },
    moduloInspeccion: {
        list: {
            navbar: [{ text: 'Inspections', navigate: false, path: '' }],
            navbarOPS: [{ text: 'SO', navigate: false, path: '' }],
            btnAgregar: 'ADD',
            textoVacio: 'No Inspections',
            textoVacioOPS: 'No SO Inspections',
            textoEnviandoCorreo: 'Sending mail...',
            modalEmail: {
                title: 'Send mail',
                correoElectronico: 'Email',
                tableEmail: 'EMAILS',
                cc: 'CC',
                btnEnviar: 'Send'
            },
            modalSearch: {
                titulo: 'Custom search',
                fechaInicial: 'Starting Date',
                fechaFinal: 'Ending date',
                tipoOperacion: 'Operation Type',
                identificacion: 'ID Number',
                buscarInspeccionesFinalizadas: 'Search for finished inspections',
                codigoInspeccion: 'Inspection Code',
                btnBuscar: 'Search'
            },
            item: {
                jefeObra: 'Site Manager',
                autor: 'Author',
                nroNoConformidad: 'Num. Non-conformity',
                fechaInspeccion: 'Inspection Date',
                empresa: 'Company',
                subContrata: 'Subcontractor',
                tipoOperacion: 'Operation Type',
                personal: 'Staff'
            },
            validate: {
                correoExiste: `Email address already exists`,
                correoEnviado: `Email Sent successfully`,
                fechaInicio: 'Starting Date',
                fechaFinal: 'Ending Date',
                fechaRangoInicioFinal: 'Starting date must be lower than Ending date',
                tipoOperacion: 'Operation type',
                identificacion: 'ID Number',
            }
        },
        form: {
            navbar: [{ text: 'Inspections', navigate: true, path: '/main/Inspeccion' }],
            navbarOPS: [{ text: 'SO', navigate: true, path: '/main/OPS' }],
            navbarText: {
                modeCrear: 'Create',
                modeVisualizar: 'Show',
                modeLevantamiento: 'Incident Closure'
            },
            btnNext: 'Next',
            btnSave: 'Save',
            stepper: {
                stepper1: {
                    text: 'General',
                    content: {
                        title: 'GENERAL',
                        ot: 'OT',
                        area: 'Area / Subsidiary',
                        empresa: 'Company',
                        actividad: 'Activity',
                        cliente: 'Customer',
                        jefeObra: 'Site Manager',
                        coordinador: 'Safety Technician',
                        encargado: 'Manager'
                    }
                },
                stepper2: {
                    text: 'Inspection Information',
                    content: {
                        title: 'INSPECTION INFORMATION',
                        responsableLevantamiento: 'Closure Responsible',
                        inspeccionConjunta: 'Joint Inspection',
                        personalConjunta: 'Joint Inspection Staff',
                        subContrata: 'Subcontractors',
                        inspeccionSubcontrataConjunta: 'Joint Inspection Subcontractor',
                        tipoOperacion: 'Operation Type',
                        formato: 'Format',
                        paralizacionTrabajo: 'Stop Work',
                        momento: 'Event Type',
                        lugar: 'Location',
                        obraTD: 'Work Project',
                        trabajoRealizar: 'Assignment Work',
                        placa: 'Vehicle Plate Number',
                        reconocimientoSST: 'Award for Excellent HSE Performance',
                        observacionOPS: 'SO Recognition',
                        fueraHorario: 'Outside Working Hours',
                    }
                },
                stepper3: {
                    text: 'Information Staff',
                    content: {
                        title: 'INFORMATION STAFF',
                        personal: 'Staff',
                        table: {
                            id: 'ID',
                            name: 'Name',
                            lastName: 'Lastname',
                            responsable: 'Manager',
                            recursoPreventivo: 'Safety Foreman',
                        }
                    }
                },
                stepper4: {
                    text: 'Registration Information',
                    content: {
                        details: {
                            title: 'INSPECTION TYPE',
                            btnSelect: 'Select',
                            listEmpty: 'No data',
                            titleModal: 'Select Inspection Type',
                            titleModalView: 'Item Information',
                            titleModalCreate: 'Add Item',
                            titleModalEdit: 'Modify Item'
                        },
                        items: {
                            title: 'ITEM LIST',
                            btnList: 'List',
                            btnAdd: 'Add',
                            listEmpty: 'No logs',
                            modal: {
                                tipoInspeccion: 'Inspection Type',
                                tipoItem: 'Item Type',
                                item: 'Item',
                                detalle: 'Detail',
                                personal: 'Staff',
                                resultado: 'Result',
                                nivelGravedad: 'Severity Level',
                                tipoSituacion: 'Situation Type',
                                grave: 'Critical',
                                descripcion: 'Description',
                                accionPropuesta: 'Proposed action',
                                levantamientoInmediato: 'Immediated Closure',
                                descripcionLevantamiento: 'Closure Description',
                                fechaLevantamiento: 'Closure Date',
                                imgTextoInspeccion: 'Inspection',
                                imgTextoLevantamiento: 'Closure',
                                titleModalImage: 'Show images',
                                fechaPrevista: 'Expected Closure date',
                                btnSave: 'Save',
                                btnSaveAndContinue: 'Save and Continue',
                                btnCancel: 'Cancel'
                            },
                            item: {
                                tipoInspeccion: 'Inspection Type',
                                tipoItem: 'Item Type',
                                item: 'Item',
                                descripcion: 'Description',
                                textIncompleta: 'Incomplete',
                                textCorrecto: 'Correct',
                                textGrave: 'Critical',
                                textNoConforme: 'Incident',
                                textImgInspeccion: 'Inspection'
                            }
                        },
                        textoCompruebaInformacion: 'Check your information',
                        textObservacionGeneral: 'Observations',
                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'OT',
                    area: 'Area',
                    empresa: 'Company',
                    actividad: 'Activity',
                    cliente: 'Customer',
                    jefeObra: 'Site Manager',
                    coordinador: 'Manager',

                    conjuntaPersonal: 'Joint Staff',
                    tipoOperacion: 'Operation Type',
                    formato: 'Format',
                    tipoMomentoTrabajo: 'Event Type',
                    lugar: 'Location',
                    obra: 'Work Project',
                    trabajoRealizar: 'Assignment Work',
                    observacionOPS: 'SO Observation',

                    personalResponsableTrabajo: 'Manager',

                    detalleInspeccionVacia: 'Enter at least one item',
                    detalleInspeccionIncompleta: 'Complete all items',
                    responsableLevantamiento: 'Closure Responsible',

                    cantidadImagenes: (cantidadMaxima: any) => `You can upload up to ${cantidadMaxima} images`, 
                },
                formRegistroItem: {
                    tipoInspeccion: 'Inspection Type',
                    tipoItem: 'Item Type',
                    item: 'Item',
                    detalle: 'Detail',
                    personal: 'Staff',
                    resultado: 'Result',
                    nivelGravedad: 'Severity Level',
                    descripcion: 'Description',
                    accionPropuesta: 'Proposed Actions',
                    tipoSituacion: 'Situation Type',
                    descripcionLevantamiento: 'Closure Description',
                    fechaLevantamiento: 'Closure Date',
                    fotoLevantamiento: 'Closure Image',
                    cantidadImagenes: 'Are all these images necessary? (verify that any image is repeated)',
                    fechaPrevista: 'Enter an Expected Date',
                },
                noItem: 'Item is not found',
                noDetalleItem: 'Item detail is not found',
                detalleItemDuplicado: 'Duplicated item detail',
                detalleItemNoPermitido: 'Item is not allowed',
                noTipoInspeccion: 'You must select an Inspection Type'
            },
            TipoMomento: {
                inicio: 'Start of Work',
                durante: 'During Work',
                fin: 'End of Work'
            },
            textoBuscarCuadrillaPersonal: "Looking for staff crew...",
            textoCorrecto: "Compliance",
            textoNoConforme: "No Compliance",
            nivelSeguridad: {
                textoAlta: "High",
                textoMedia: "Medium",
                textoLeve: "Low",
            },
            tipoSituacion: {
                textoActoInseguro: "Unsafe Act",
                textoCondicionInsegura: "Unsafe Condition"
            }
        }
    },
    moduloAutoChequeo: {
        list: {
            TituloListaAutochequeo: "SELF-CHECK LIST",
            TituloListaControlPreventivo: "PREVENTIVE CONTROL",
            navbar: [{ text: 'Self-check Management', navigate: false, path: '' }],
            navbarGuest: [{ text: 'Pre-task briefing', navigate: false, path: '' }],
            Estado: "Status",
            textoCPPendiente: 'CP Pending',
            textoCPCerrado: 'CP Closed',
            EstadoItems: {
                EstadoActivo: "Active",
                EstadoInactivo: "Inactive"
            },
            Resultado: "Result",
            ResultadoItems: {
                ResultadoCorrecto: "Correct",
                ResultadoIncorrecto: "Invalid"
            },
            Autor: "Author",
            FechaRegistro: "Registration date",
            textAudio: 'Audio',
            textPDF: 'PDF',
            search: {
                Titulo: "Self-check Search",
                FechaInicial: "Initial date",
                FechaFinal: "Final date",
                Identificacion: "Identification",
                BtnBuscar: "Search",
                BusquedaGrupal: "Group Search",
                BuscarRegistrosActivos: "Search Active Records"
            },
            validate: {
                formFilter: {
                    fechaInicial: 'Starting Date',
                    fechaFinal: 'Ending Date',
                    identificacion: 'ID Number'
                }
            }
        },
        form: {
            navBarTextCharlaPretarea: 'Pre-task briefing',
            navBarTextListaAutochequeo: 'Self-Check List',
            navBarTextControlPreventivo: 'Preventive Control',
            navBarTextCierreControlPreventivo: 'Closure Preventive Control',
            navBarTextPreview: 'Preview',
            navBarGuest: [{ text: 'Pre-Task briefing', navigate: true, path: '/main/GestionAutochequeo' }, { text: 'Detail', navigate: false, path: '' }],
            tituloDatosGenerales: 'General',
            tituloDatosControlPreventivo: 'Preventive Control',
            textoAyudaControlPreventivo: 'Preventive control closure',
            textoAyudaNecesitaRegistrarLAutochequeo: 'This OT needs the self-check list to be made first, if you already did it please ignore this message',
            btnAutcompletarUltimoRegistro: 'Autocomplete from the last record',
            inputs: {
                ot: 'OT',
                empresa: 'Company',
                subContrata: 'Subcontractor',
                delegacion: 'Delegation',
                actividad: 'Activity',
                recursoPreventivo: 'Safety staff',
                cliente: 'Customer',
                obra: 'Work Project',
                lugar: 'Location',
                trabajoRealizar: 'Assignment Work',
                jefeObra: 'Site Manager',
                tituloGrupo: 'Group',
                ordenServicio: 'Service Order / Work Order',
                txtObservacion: 'Observation',
                txtPersonal: 'Staff',
                textFirmaJefeTrabajo: "Signature of the Chief of works",
                textBien: 'Good',
                textMal: 'Bad'
            },
            preguntarRealizarCierreControlPreventivo: 'Do you want to perform the Preventive Control Closure?',
            advertenciaListaAutochequeoRealizado: 'The Self-Check list has already been made',
            advertenciaSinRegistro: 'Record not found',
            advertenciaRegistroConCierre: 'This registry already has a Closure',
            advertenciaCargarOT: 'An error occurred while loading OTs',
            advertenciaBuscarOTRegistro: 'OT not found linked to record',
            advertenciaRegistroSinCierre: 'This OT does not have a Preventive Control Closure',
            advertenciaOTSinTipologias: 'This OT does not have typologies defined.',
            advertenciaOTSinPreguntas: 'This OT does not have any question',
            advertenciaOTSinPreguntasAutochequeo: 'This OT has no questions to display, try another',
            advertenciaOTPreview: (text: string) => `Could not find the OT you registered in ${text}, go through the whole process again`,
            textTipologia: 'Typologies',
            tipologiaVacio: 'No typologies',
            textCargandoPreguntas: 'Loading Questions',
            textCargandoTipologias: 'Loading typologies',
            textRegistroIncompleto: 'Do you have a registration that was not completed, do you want to upload it?',
            validate: {
                formRegistro: {
                    ordenServicio: 'Enter a Service Order / Work Order',
                    ot: 'OT',
                    otDirectoCP: `You need to first complete 'Self-Check Checklist' or 'Pre-Task briefing'`,
                    tipologia: 'Typology',
                    empresa: 'Company',
                    delegacion: 'Delegation',
                    actividad: 'Activity',
                    cliente: 'Customer',
                    obra: 'Work Project',
                    lugar: 'Location',
                    trabajoRealizar: 'Assignment Job',
                    jefeObra: 'Site Manager',
                    usuarioRegistra: 'Register by'
                },
                formDinamico: {
                    preguntaNoRespondido: 'You have not answered any question',
                    preguntaNoEstadoEquipo: `Select 'team status' in question number`,
                    preguntaNoObservacion: `You must fill an 'observation' for each question`,
                    preguntaNoAudio: `You must record an 'audio' on question`,
                    preguntaNoPersonal: `Enter 'staff' in question number`,
                    preguntaNoImagen: (count: number) => `You can upload up to ${count} for each question`,
                    preguntaImagen: `You must upload an image for each question`,
                    preguntaBloqueo: `It is not allowed to continue, Technical training is mandatory for each question`,
                    preguntaNoImagenGrupal: `Upload a group image`,
                    preguntaNoObservacionGrupal: `You must fill a group observation`,
                    preguntaBlockDevolverOrden: (numero: any) => `If you do not meet the necessary material in question number ${numero} you will not be able to register`,
                    preguntaModuloAUT: 'You can only register in "Self-Checklist" if you are the only brigade member; group registrations are done in "Pre-Task briefing"',
                    preguntaModuloCPT: 'You can only register in "Pre-Task briefing" if there are multiple brigade members; individual registrations are done in "Self-Checklist"',
                    preguntaJefeTrabajo: 'Select a Work Manager in question number',
                    preguntaRiesgo: 'Select a risk in question number',
                    preguntaMedidaPreventiva: 'Select a Safety Foreman in question number',
                    preguntaEPCS: 'Select a PPE in question number',
                    preguntaRecursoPreventivo: 'Select the Safety Foreman in question number',
                    preguntaFirmaJefeTrabajo: "Work Manager must sign for all questions",
                    preguntaFirmaJefeTrabajoVacio: 'Draw a signature to save',
                }
            }
        },
        comentario: {
            textComentario: 'Comment',
            titleModalAgregar: 'Add Comment',
            titleModalList: 'Comments',
            table: {
                autor: 'Author',
                puntuacion: 'Score',
                Comentario: 'Comment'
            },
            requiredAutochequeo: 'You should first review the PDF before entering a comment',
            requiredCharlaPretarea: 'You should first review the audio and PDF before entering a comment'
        }
    },
    moduloNotificacion: {
        textoNoLeido: 'Unread',
        textoAcabaRecibirNotificacion: 'You have just received a new comment in the registration num:',
        textoNuevo: 'New'
    },
    menu: {
        '010': {
            titulo: 'Inspections',
            descripcion: 'Inspection Record'
        },
        '020': {
            titulo: 'Inspections SO',
            descripcion: 'Inspection Record Safety Observation'
        },
        '030': {
            titulo: 'Self-Check List',
            descripcion: 'AutoCheck List Registration'
        },
        '031': {
            titulo: 'Pre-task briefing',
            descripcion: 'Recording of the Pre-task briefing'
        },
        '040': {
            titulo: 'Preventive Control',
            descripcion: 'Preventive Control Questions'
        },
        '050': {
            titulo: 'RDI',
            descripcion: 'Incident Report'
        },
        '060': {
            titulo: 'Self-check Management',
            descripcion: 'Self-check Management'
        },
    },
    languages: [
        { code: 'es', name: 'Spanish' },
        { code: 'en', name: 'English' },
        { code: 'it', name: 'Italian' },
        { code: 'fr', name: 'French' },
        { code: 'pt', name: 'Portuguese' },
    ]
}